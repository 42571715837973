/* tslint:disable */
/* eslint-disable */
/**
 * Mind
 * Kare API v4.0
 *
 * The version of the OpenAPI document: 4.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AccessTokenResponse } from '../model';
// @ts-ignore
import { AuthUrlResponse } from '../model';
// @ts-ignore
import { AuthenticateGdriveRequest } from '../model';
// @ts-ignore
import { AuthenticateZendeskRequest } from '../model';
// @ts-ignore
import { AuthorizedServicesResponse } from '../model';
// @ts-ignore
import { CreateClientRequest } from '../model';
// @ts-ignore
import { Document360Request } from '../model';
// @ts-ignore
import { GetClientResponse } from '../model';
// @ts-ignore
import { GetDocument360Response } from '../model';
// @ts-ignore
import { GetSettingsResponse } from '../model';
// @ts-ignore
import { GetStatusResponse } from '../model';
// @ts-ignore
import { GetZendeskSettingsResponse } from '../model';
// @ts-ignore
import { ListWebcrawlerDomainsResponse } from '../model';
// @ts-ignore
import { ModelError } from '../model';
// @ts-ignore
import { NodeDetail } from '../model';
// @ts-ignore
import { PatchDocument360Request } from '../model';
// @ts-ignore
import { PatchDocument360Response } from '../model';
// @ts-ignore
import { PostAuthUrlRequest } from '../model';
// @ts-ignore
import { PutWebcrawlerDomainRequest } from '../model';
// @ts-ignore
import { PutWebcrawlerDomainResponse } from '../model';
// @ts-ignore
import { UpdateSettingsRequest } from '../model';
// @ts-ignore
import { ValidateWebcrawlerDomainRequest } from '../model';
// @ts-ignore
import { ValidateWebcrawlerDomainResponse } from '../model';
/**
 * IngestionApi - axios parameter creator
 * @export
 */
export const IngestionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Use this endpoint to register a new Box client. 
         * @summary Authenticates a previously registered new box client.
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} code Exchange code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateBoxClient: async (authorization: string, kbId: string, code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('authenticateBoxClient', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('authenticateBoxClient', 'kbId', kbId)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('authenticateBoxClient', 'code', code)
            const localVarPath = `/ingestion/{kb_id}/box/authenticate`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeIngestionWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to update the authentication code and return a new access code for the user. 
         * @summary Exchange auth code for tokens to complete Oauthflow
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {AuthenticateGdriveRequest} body The redirect URI that the auth code and state token should be sent to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateGDrive: async (authorization: string, kbId: string, body: AuthenticateGdriveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('authenticateGDrive', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('authenticateGDrive', 'kbId', kbId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('authenticateGDrive', 'body', body)
            const localVarPath = `/ingestion/{kb_id}/gdrive/authenticate/exchange`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeIngestionWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to exchange a zendesk token and complete authentication.
         * @summary Authenticate Zendesk token
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {AuthenticateZendeskRequest} body Create zendesk token request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateZendesk: async (authorization: string, kbId: string, body: AuthenticateZendeskRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('authenticateZendesk', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('authenticateZendesk', 'kbId', kbId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('authenticateZendesk', 'body', body)
            const localVarPath = `/ingestion/{kb_id}/zendesk/authenticate`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeIngestionWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to register a new Box client. Clients will then need to send the registration token. 
         * @summary Creates a new box client
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {CreateClientRequest} body Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBoxClient: async (authorization: string, kbId: string, body: CreateClientRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('createBoxClient', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('createBoxClient', 'kbId', kbId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createBoxClient', 'body', body)
            const localVarPath = `/ingestion/{kb_id}/box`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeIngestionWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to create Document360 credentials.
         * @summary Connects a new Document360 account using an API key.
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {Document360Request} body Create document360 request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocument360Settings: async (authorization: string, kbId: string, body: Document360Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('createDocument360Settings', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('createDocument360Settings', 'kbId', kbId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createDocument360Settings', 'body', body)
            const localVarPath = `/ingestion/{kb_id}/document360`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeIngestionWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a webcralwer domain. After tdeletion all crawled documents will be archived and the domain will no longer be crawled. 
         * @summary Delete a webcrawler domain
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} host domain name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWebcrawlerDomain: async (authorization: string, kbId: string, host: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('deleteWebcrawlerDomain', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('deleteWebcrawlerDomain', 'kbId', kbId)
            // verify required parameter 'host' is not null or undefined
            assertParamExists('deleteWebcrawlerDomain', 'host', host)
            const localVarPath = `/ingestion/{kb_id}/webcrawler/domains`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeIngestionWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (host !== undefined) {
                localVarQueryParameter['host'] = host;
            }

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Disconnects an existing Box client. 
         * @summary Disconnects a new box client
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disconnectBoxClient: async (authorization: string, kbId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('disconnectBoxClient', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('disconnectBoxClient', 'kbId', kbId)
            const localVarPath = `/ingestion/{kb_id}/box`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeIngestionWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to disconnect the Document360 Accout from this KB.
         * @summary Disconnect Document360
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disconnectDocument360: async (authorization: string, kbId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('disconnectDocument360', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('disconnectDocument360', 'kbId', kbId)
            const localVarPath = `/ingestion/{kb_id}/document360`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeIngestionWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to disconnect the Google Drive connection
         * @summary Disconnect GDrive
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disconnectGDrive: async (authorization: string, kbId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('disconnectGDrive', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('disconnectGDrive', 'kbId', kbId)
            const localVarPath = `/ingestion/{kb_id}/gdrive`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeIngestionWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to disconnect the Zendesk Accout from this KB.
         * @summary Disconnect Zendesk
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disconnectZendesk: async (authorization: string, kbId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('disconnectZendesk', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('disconnectZendesk', 'kbId', kbId)
            const localVarPath = `/ingestion/{kb_id}/zendesk`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeIngestionWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to schedule a run of this Google Drive import job 
         * @summary Schedule a GDrive job
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gDriveScheduleJob: async (authorization: string, kbId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('gDriveScheduleJob', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('gDriveScheduleJob', 'kbId', kbId)
            const localVarPath = `/ingestion/{kb_id}/gdrive/schedule`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeIngestionWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to the currently authorized services with the account
         * @summary Get authorized services
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthorizedServices: async (authorization: string, kbId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getAuthorizedServices', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('getAuthorizedServices', 'kbId', kbId)
            const localVarPath = `/ingestion/{kb_id}/services`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeIngestionRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to get the box client configuration. 
         * @summary Get new box client
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoxClient: async (authorization: string, kbId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getBoxClient', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('getBoxClient', 'kbId', kbId)
            const localVarPath = `/ingestion/{kb_id}/box`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeIngestionRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to get the current status of the Knowledgebases most recent import job on the box service. Also returns the error message if the job has failed. If the knowledgebase is not published return 412. if the knowledgebase does not exist return 404. 
         * @summary Get the status of the knowledgebases most recent import job on the box service.
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoxLatestImportStatus: async (authorization: string, kbId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getBoxLatestImportStatus', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('getBoxLatestImportStatus', 'kbId', kbId)
            const localVarPath = `/ingestion/{kb_id}/box/imports/latest`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeIngestionRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to get the current status of the Knowledgebases most recent import job on the Document360 service. Also returns the error message if the job has failed. If the knowledgebase is not published return 412. if the knowledgebase does not exist return 404. 
         * @summary Get the status of the knowledgebases most recent import job on the Document360 service.
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument360LatestImportStatus: async (authorization: string, kbId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getDocument360LatestImportStatus', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('getDocument360LatestImportStatus', 'kbId', kbId)
            const localVarPath = `/ingestion/{kb_id}/document360/imports/latest`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeIngestionRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the Document360 settings for this KB. Returns 404 `not found` if no Document360 is configured. 
         * @summary Get Document360 settings
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument360Settings: async (authorization: string, kbId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getDocument360Settings', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('getDocument360Settings', 'kbId', kbId)
            const localVarPath = `/ingestion/{kb_id}/document360`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeIngestionRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to get the access token if exists and belongs to the current user. This can be used to get the access token for the user without having to reauthenticate. 
         * @summary Returns the access token
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGDriveAccessToken: async (authorization: string, kbId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getGDriveAccessToken', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('getGDriveAccessToken', 'kbId', kbId)
            const localVarPath = `/ingestion/{kb_id}/gdrive/authenticate/token`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeIngestionWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to get the current status of the Knowledgebases most recent import job on the Google Drive service. Also returns the error message if the job has failed. If the knowledgebase is not published return 412. if the knowledgebase does not exist return 404. 
         * @summary Get the status of the knowledgebases most recent import job on the Google Drive service.
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGDriveLatestImportStatus: async (authorization: string, kbId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getGDriveLatestImportStatus', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('getGDriveLatestImportStatus', 'kbId', kbId)
            const localVarPath = `/ingestion/{kb_id}/gdrive/imports/latest`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeIngestionRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to get the google drive settings. 
         * @summary Get Google Drive settings
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGDriveSettings: async (authorization: string, kbId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getGDriveSettings', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('getGDriveSettings', 'kbId', kbId)
            const localVarPath = `/ingestion/{kb_id}/gdrive`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeIngestionWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to get the current status of the Knowledgebases most recent Webcrawler import job from the host. Also returns the error message if the job has failed. If the knowledgebase is not published return 412. if the knowledgebase does not exist return 404. 
         * @summary Get the status of the knowledgebases most recent webcrawler import job from the host
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} host host name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebcrawlerLatestImportStatus: async (authorization: string, kbId: string, host: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getWebcrawlerLatestImportStatus', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('getWebcrawlerLatestImportStatus', 'kbId', kbId)
            // verify required parameter 'host' is not null or undefined
            assertParamExists('getWebcrawlerLatestImportStatus', 'host', host)
            const localVarPath = `/ingestion/{kb_id}/webcrawler/imports/latest`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeIngestionRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (host !== undefined) {
                localVarQueryParameter['host'] = host;
            }

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to get the current status of the Knowledgebases most recent import job on the Zendesk service. Also returns the error message if the job has failed. If the knowledgebase is not published return 412. if the knowledgebase does not exist return 404. 
         * @summary Get the status of the knowledgebases most recent import job on the Zendesk service.
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZendeskLatestImportStatus: async (authorization: string, kbId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getZendeskLatestImportStatus', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('getZendeskLatestImportStatus', 'kbId', kbId)
            const localVarPath = `/ingestion/{kb_id}/zendesk/imports/latest`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeIngestionRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the Zendesk settings for this KB. Returns 404 `not found` if no Zendesk is configured. 
         * @summary Get Zendesk settings
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZendeskSettings: async (authorization: string, kbId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getZendeskSettings', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('getZendeskSettings', 'kbId', kbId)
            const localVarPath = `/ingestion/{kb_id}/zendesk`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeIngestionRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all the domains that the webcrawler should crawl with all their properties. 
         * @summary List domains to webcrawl
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} [host] domain name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWebcrawlerDomains: async (authorization: string, kbId: string, host?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('listWebcrawlerDomains', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('listWebcrawlerDomains', 'kbId', kbId)
            const localVarPath = `/ingestion/{kb_id}/webcrawler/domains`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeIngestionRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (host !== undefined) {
                localVarQueryParameter['host'] = host;
            }

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to generate a Oauth authentical URL that will send the authorization code to the provided redirect url. 
         * @summary Returns the Oauth authentication URL
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {PostAuthUrlRequest} body Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postGDriveAuthenticationUrl: async (authorization: string, kbId: string, body: PostAuthUrlRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('postGDriveAuthenticationUrl', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('postGDriveAuthenticationUrl', 'kbId', kbId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('postGDriveAuthenticationUrl', 'body', body)
            const localVarPath = `/ingestion/{kb_id}/gdrive/authenticate/url`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeIngestionWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * \'Uploads a document content and creates a new node to manage it. The file name is used to generate the ID. If a file with the same name re-uploaded it overrides the previous one.\' 
         * @summary Upload document
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {File} content The content to upload.
         * @param {string} status Status of the content between published, unpublished or deleted.
         * @param {string} [labels] Labels to assign to the content. As a json encoded list of strings.
         * @param {boolean} [override] &#x60;Indicates if the content should be overwritten if file exists.&#x60; 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUpload: async (authorization: string, kbId: string, content: File, status: string, labels?: string, override?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('postUpload', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('postUpload', 'kbId', kbId)
            // verify required parameter 'content' is not null or undefined
            assertParamExists('postUpload', 'content', content)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('postUpload', 'status', status)
            const localVarPath = `/ingestion/{kb_id}/upload`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerKnowledgeIngestionWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


            if (content !== undefined) { 
                localVarFormParams.append('content', content as any);
            }
    
            if (status !== undefined) { 
                localVarFormParams.append('status', status as any);
            }
    
            if (labels !== undefined) { 
                localVarFormParams.append('labels', labels as any);
            }
    
            if (override !== undefined) { 
                localVarFormParams.append('override', override as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Saves the crawl settings for the given domain. The domain will be recrawled with the new settings the next time the crawler runs. 
         * @summary Put webcrawler domain
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} host domain name
         * @param {PutWebcrawlerDomainRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWebcrawlerDomain: async (authorization: string, kbId: string, host: string, body?: PutWebcrawlerDomainRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('putWebcrawlerDomain', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('putWebcrawlerDomain', 'kbId', kbId)
            // verify required parameter 'host' is not null or undefined
            assertParamExists('putWebcrawlerDomain', 'host', host)
            const localVarPath = `/ingestion/{kb_id}/webcrawler/domains`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeIngestionWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (host !== undefined) {
                localVarQueryParameter['host'] = host;
            }

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the configuration of an already connected Document360 account.
         * @summary Update Document360 settings
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {PatchDocument360Request} body Update document360 request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocument360Settings: async (authorization: string, kbId: string, body: PatchDocument360Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('updateDocument360Settings', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('updateDocument360Settings', 'kbId', kbId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateDocument360Settings', 'body', body)
            const localVarPath = `/ingestion/{kb_id}/document360`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeIngestionWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to update a google drive setting with the list of folders/files to import. 
         * @summary Update existing authenticated Google Drive settings
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {UpdateSettingsRequest} body Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGDriveSettings: async (authorization: string, kbId: string, body: UpdateSettingsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('updateGDriveSettings', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('updateGDriveSettings', 'kbId', kbId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateGDriveSettings', 'body', body)
            const localVarPath = `/ingestion/{kb_id}/gdrive`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeIngestionWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Validates a host to crawl by checking robots.txt and sitemap url. 
         * @summary validates a domain to crawl
         * @param {string} authorization Authorization Bearer header
         * @param {ValidateWebcrawlerDomainRequest} body The host name to check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateWebcrawlerDomain: async (authorization: string, body: ValidateWebcrawlerDomainRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('validateWebcrawlerDomain', 'authorization', authorization)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('validateWebcrawlerDomain', 'body', body)
            const localVarPath = `/ingestion/webcrawler/validate-host`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeIngestionRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IngestionApi - functional programming interface
 * @export
 */
export const IngestionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IngestionApiAxiosParamCreator(configuration)
    return {
        /**
         * Use this endpoint to register a new Box client. 
         * @summary Authenticates a previously registered new box client.
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} code Exchange code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticateBoxClient(authorization: string, kbId: string, code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticateBoxClient(authorization, kbId, code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to update the authentication code and return a new access code for the user. 
         * @summary Exchange auth code for tokens to complete Oauthflow
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {AuthenticateGdriveRequest} body The redirect URI that the auth code and state token should be sent to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticateGDrive(authorization: string, kbId: string, body: AuthenticateGdriveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticateGDrive(authorization, kbId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to exchange a zendesk token and complete authentication.
         * @summary Authenticate Zendesk token
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {AuthenticateZendeskRequest} body Create zendesk token request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticateZendesk(authorization: string, kbId: string, body: AuthenticateZendeskRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticateZendesk(authorization, kbId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to register a new Box client. Clients will then need to send the registration token. 
         * @summary Creates a new box client
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {CreateClientRequest} body Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBoxClient(authorization: string, kbId: string, body: CreateClientRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBoxClient(authorization, kbId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to create Document360 credentials.
         * @summary Connects a new Document360 account using an API key.
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {Document360Request} body Create document360 request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDocument360Settings(authorization: string, kbId: string, body: Document360Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDocument360Settings(authorization, kbId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes a webcralwer domain. After tdeletion all crawled documents will be archived and the domain will no longer be crawled. 
         * @summary Delete a webcrawler domain
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} host domain name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWebcrawlerDomain(authorization: string, kbId: string, host: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWebcrawlerDomain(authorization, kbId, host, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Disconnects an existing Box client. 
         * @summary Disconnects a new box client
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disconnectBoxClient(authorization: string, kbId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disconnectBoxClient(authorization, kbId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to disconnect the Document360 Accout from this KB.
         * @summary Disconnect Document360
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disconnectDocument360(authorization: string, kbId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disconnectDocument360(authorization, kbId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to disconnect the Google Drive connection
         * @summary Disconnect GDrive
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disconnectGDrive(authorization: string, kbId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disconnectGDrive(authorization, kbId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to disconnect the Zendesk Accout from this KB.
         * @summary Disconnect Zendesk
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disconnectZendesk(authorization: string, kbId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disconnectZendesk(authorization, kbId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to schedule a run of this Google Drive import job 
         * @summary Schedule a GDrive job
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gDriveScheduleJob(authorization: string, kbId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gDriveScheduleJob(authorization, kbId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint to the currently authorized services with the account
         * @summary Get authorized services
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthorizedServices(authorization: string, kbId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorizedServicesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthorizedServices(authorization, kbId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to get the box client configuration. 
         * @summary Get new box client
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBoxClient(authorization: string, kbId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBoxClient(authorization, kbId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to get the current status of the Knowledgebases most recent import job on the box service. Also returns the error message if the job has failed. If the knowledgebase is not published return 412. if the knowledgebase does not exist return 404. 
         * @summary Get the status of the knowledgebases most recent import job on the box service.
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBoxLatestImportStatus(authorization: string, kbId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBoxLatestImportStatus(authorization, kbId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to get the current status of the Knowledgebases most recent import job on the Document360 service. Also returns the error message if the job has failed. If the knowledgebase is not published return 412. if the knowledgebase does not exist return 404. 
         * @summary Get the status of the knowledgebases most recent import job on the Document360 service.
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocument360LatestImportStatus(authorization: string, kbId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocument360LatestImportStatus(authorization, kbId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the Document360 settings for this KB. Returns 404 `not found` if no Document360 is configured. 
         * @summary Get Document360 settings
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocument360Settings(authorization: string, kbId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDocument360Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocument360Settings(authorization, kbId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to get the access token if exists and belongs to the current user. This can be used to get the access token for the user without having to reauthenticate. 
         * @summary Returns the access token
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGDriveAccessToken(authorization: string, kbId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGDriveAccessToken(authorization, kbId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to get the current status of the Knowledgebases most recent import job on the Google Drive service. Also returns the error message if the job has failed. If the knowledgebase is not published return 412. if the knowledgebase does not exist return 404. 
         * @summary Get the status of the knowledgebases most recent import job on the Google Drive service.
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGDriveLatestImportStatus(authorization: string, kbId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGDriveLatestImportStatus(authorization, kbId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to get the google drive settings. 
         * @summary Get Google Drive settings
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGDriveSettings(authorization: string, kbId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGDriveSettings(authorization, kbId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to get the current status of the Knowledgebases most recent Webcrawler import job from the host. Also returns the error message if the job has failed. If the knowledgebase is not published return 412. if the knowledgebase does not exist return 404. 
         * @summary Get the status of the knowledgebases most recent webcrawler import job from the host
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} host host name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWebcrawlerLatestImportStatus(authorization: string, kbId: string, host: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWebcrawlerLatestImportStatus(authorization, kbId, host, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to get the current status of the Knowledgebases most recent import job on the Zendesk service. Also returns the error message if the job has failed. If the knowledgebase is not published return 412. if the knowledgebase does not exist return 404. 
         * @summary Get the status of the knowledgebases most recent import job on the Zendesk service.
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getZendeskLatestImportStatus(authorization: string, kbId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getZendeskLatestImportStatus(authorization, kbId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the Zendesk settings for this KB. Returns 404 `not found` if no Zendesk is configured. 
         * @summary Get Zendesk settings
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getZendeskSettings(authorization: string, kbId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetZendeskSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getZendeskSettings(authorization, kbId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all the domains that the webcrawler should crawl with all their properties. 
         * @summary List domains to webcrawl
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} [host] domain name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listWebcrawlerDomains(authorization: string, kbId: string, host?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListWebcrawlerDomainsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listWebcrawlerDomains(authorization, kbId, host, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to generate a Oauth authentical URL that will send the authorization code to the provided redirect url. 
         * @summary Returns the Oauth authentication URL
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {PostAuthUrlRequest} body Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postGDriveAuthenticationUrl(authorization: string, kbId: string, body: PostAuthUrlRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postGDriveAuthenticationUrl(authorization, kbId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * \'Uploads a document content and creates a new node to manage it. The file name is used to generate the ID. If a file with the same name re-uploaded it overrides the previous one.\' 
         * @summary Upload document
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {File} content The content to upload.
         * @param {string} status Status of the content between published, unpublished or deleted.
         * @param {string} [labels] Labels to assign to the content. As a json encoded list of strings.
         * @param {boolean} [override] &#x60;Indicates if the content should be overwritten if file exists.&#x60; 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUpload(authorization: string, kbId: string, content: File, status: string, labels?: string, override?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUpload(authorization, kbId, content, status, labels, override, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Saves the crawl settings for the given domain. The domain will be recrawled with the new settings the next time the crawler runs. 
         * @summary Put webcrawler domain
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} host domain name
         * @param {PutWebcrawlerDomainRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putWebcrawlerDomain(authorization: string, kbId: string, host: string, body?: PutWebcrawlerDomainRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutWebcrawlerDomainResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putWebcrawlerDomain(authorization, kbId, host, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the configuration of an already connected Document360 account.
         * @summary Update Document360 settings
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {PatchDocument360Request} body Update document360 request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDocument360Settings(authorization: string, kbId: string, body: PatchDocument360Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatchDocument360Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDocument360Settings(authorization, kbId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to update a google drive setting with the list of folders/files to import. 
         * @summary Update existing authenticated Google Drive settings
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {UpdateSettingsRequest} body Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGDriveSettings(authorization: string, kbId: string, body: UpdateSettingsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGDriveSettings(authorization, kbId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Validates a host to crawl by checking robots.txt and sitemap url. 
         * @summary validates a domain to crawl
         * @param {string} authorization Authorization Bearer header
         * @param {ValidateWebcrawlerDomainRequest} body The host name to check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateWebcrawlerDomain(authorization: string, body: ValidateWebcrawlerDomainRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateWebcrawlerDomainResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateWebcrawlerDomain(authorization, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IngestionApi - factory interface
 * @export
 */
export const IngestionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IngestionApiFp(configuration)
    return {
        /**
         * Use this endpoint to register a new Box client. 
         * @summary Authenticates a previously registered new box client.
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} code Exchange code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateBoxClient(authorization: string, kbId: string, code: string, options?: any): AxiosPromise<void> {
            return localVarFp.authenticateBoxClient(authorization, kbId, code, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to update the authentication code and return a new access code for the user. 
         * @summary Exchange auth code for tokens to complete Oauthflow
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {AuthenticateGdriveRequest} body The redirect URI that the auth code and state token should be sent to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateGDrive(authorization: string, kbId: string, body: AuthenticateGdriveRequest, options?: any): AxiosPromise<AccessTokenResponse> {
            return localVarFp.authenticateGDrive(authorization, kbId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to exchange a zendesk token and complete authentication.
         * @summary Authenticate Zendesk token
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {AuthenticateZendeskRequest} body Create zendesk token request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateZendesk(authorization: string, kbId: string, body: AuthenticateZendeskRequest, options?: any): AxiosPromise<void> {
            return localVarFp.authenticateZendesk(authorization, kbId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to register a new Box client. Clients will then need to send the registration token. 
         * @summary Creates a new box client
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {CreateClientRequest} body Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBoxClient(authorization: string, kbId: string, body: CreateClientRequest, options?: any): AxiosPromise<void> {
            return localVarFp.createBoxClient(authorization, kbId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to create Document360 credentials.
         * @summary Connects a new Document360 account using an API key.
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {Document360Request} body Create document360 request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDocument360Settings(authorization: string, kbId: string, body: Document360Request, options?: any): AxiosPromise<void> {
            return localVarFp.createDocument360Settings(authorization, kbId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a webcralwer domain. After tdeletion all crawled documents will be archived and the domain will no longer be crawled. 
         * @summary Delete a webcrawler domain
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} host domain name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWebcrawlerDomain(authorization: string, kbId: string, host: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteWebcrawlerDomain(authorization, kbId, host, options).then((request) => request(axios, basePath));
        },
        /**
         * Disconnects an existing Box client. 
         * @summary Disconnects a new box client
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disconnectBoxClient(authorization: string, kbId: string, options?: any): AxiosPromise<void> {
            return localVarFp.disconnectBoxClient(authorization, kbId, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to disconnect the Document360 Accout from this KB.
         * @summary Disconnect Document360
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disconnectDocument360(authorization: string, kbId: string, options?: any): AxiosPromise<void> {
            return localVarFp.disconnectDocument360(authorization, kbId, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to disconnect the Google Drive connection
         * @summary Disconnect GDrive
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disconnectGDrive(authorization: string, kbId: string, options?: any): AxiosPromise<void> {
            return localVarFp.disconnectGDrive(authorization, kbId, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to disconnect the Zendesk Accout from this KB.
         * @summary Disconnect Zendesk
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disconnectZendesk(authorization: string, kbId: string, options?: any): AxiosPromise<void> {
            return localVarFp.disconnectZendesk(authorization, kbId, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to schedule a run of this Google Drive import job 
         * @summary Schedule a GDrive job
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gDriveScheduleJob(authorization: string, kbId: string, options?: any): AxiosPromise<void> {
            return localVarFp.gDriveScheduleJob(authorization, kbId, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to the currently authorized services with the account
         * @summary Get authorized services
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthorizedServices(authorization: string, kbId: string, options?: any): AxiosPromise<AuthorizedServicesResponse> {
            return localVarFp.getAuthorizedServices(authorization, kbId, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to get the box client configuration. 
         * @summary Get new box client
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoxClient(authorization: string, kbId: string, options?: any): AxiosPromise<GetClientResponse> {
            return localVarFp.getBoxClient(authorization, kbId, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to get the current status of the Knowledgebases most recent import job on the box service. Also returns the error message if the job has failed. If the knowledgebase is not published return 412. if the knowledgebase does not exist return 404. 
         * @summary Get the status of the knowledgebases most recent import job on the box service.
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoxLatestImportStatus(authorization: string, kbId: string, options?: any): AxiosPromise<GetStatusResponse> {
            return localVarFp.getBoxLatestImportStatus(authorization, kbId, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to get the current status of the Knowledgebases most recent import job on the Document360 service. Also returns the error message if the job has failed. If the knowledgebase is not published return 412. if the knowledgebase does not exist return 404. 
         * @summary Get the status of the knowledgebases most recent import job on the Document360 service.
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument360LatestImportStatus(authorization: string, kbId: string, options?: any): AxiosPromise<GetStatusResponse> {
            return localVarFp.getDocument360LatestImportStatus(authorization, kbId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the Document360 settings for this KB. Returns 404 `not found` if no Document360 is configured. 
         * @summary Get Document360 settings
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument360Settings(authorization: string, kbId: string, options?: any): AxiosPromise<GetDocument360Response> {
            return localVarFp.getDocument360Settings(authorization, kbId, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to get the access token if exists and belongs to the current user. This can be used to get the access token for the user without having to reauthenticate. 
         * @summary Returns the access token
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGDriveAccessToken(authorization: string, kbId: string, options?: any): AxiosPromise<AccessTokenResponse> {
            return localVarFp.getGDriveAccessToken(authorization, kbId, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to get the current status of the Knowledgebases most recent import job on the Google Drive service. Also returns the error message if the job has failed. If the knowledgebase is not published return 412. if the knowledgebase does not exist return 404. 
         * @summary Get the status of the knowledgebases most recent import job on the Google Drive service.
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGDriveLatestImportStatus(authorization: string, kbId: string, options?: any): AxiosPromise<GetStatusResponse> {
            return localVarFp.getGDriveLatestImportStatus(authorization, kbId, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to get the google drive settings. 
         * @summary Get Google Drive settings
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGDriveSettings(authorization: string, kbId: string, options?: any): AxiosPromise<GetSettingsResponse> {
            return localVarFp.getGDriveSettings(authorization, kbId, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to get the current status of the Knowledgebases most recent Webcrawler import job from the host. Also returns the error message if the job has failed. If the knowledgebase is not published return 412. if the knowledgebase does not exist return 404. 
         * @summary Get the status of the knowledgebases most recent webcrawler import job from the host
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} host host name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebcrawlerLatestImportStatus(authorization: string, kbId: string, host: string, options?: any): AxiosPromise<GetStatusResponse> {
            return localVarFp.getWebcrawlerLatestImportStatus(authorization, kbId, host, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to get the current status of the Knowledgebases most recent import job on the Zendesk service. Also returns the error message if the job has failed. If the knowledgebase is not published return 412. if the knowledgebase does not exist return 404. 
         * @summary Get the status of the knowledgebases most recent import job on the Zendesk service.
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZendeskLatestImportStatus(authorization: string, kbId: string, options?: any): AxiosPromise<GetStatusResponse> {
            return localVarFp.getZendeskLatestImportStatus(authorization, kbId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the Zendesk settings for this KB. Returns 404 `not found` if no Zendesk is configured. 
         * @summary Get Zendesk settings
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZendeskSettings(authorization: string, kbId: string, options?: any): AxiosPromise<GetZendeskSettingsResponse> {
            return localVarFp.getZendeskSettings(authorization, kbId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all the domains that the webcrawler should crawl with all their properties. 
         * @summary List domains to webcrawl
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} [host] domain name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listWebcrawlerDomains(authorization: string, kbId: string, host?: string, options?: any): AxiosPromise<ListWebcrawlerDomainsResponse> {
            return localVarFp.listWebcrawlerDomains(authorization, kbId, host, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to generate a Oauth authentical URL that will send the authorization code to the provided redirect url. 
         * @summary Returns the Oauth authentication URL
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {PostAuthUrlRequest} body Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postGDriveAuthenticationUrl(authorization: string, kbId: string, body: PostAuthUrlRequest, options?: any): AxiosPromise<AuthUrlResponse> {
            return localVarFp.postGDriveAuthenticationUrl(authorization, kbId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * \'Uploads a document content and creates a new node to manage it. The file name is used to generate the ID. If a file with the same name re-uploaded it overrides the previous one.\' 
         * @summary Upload document
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {File} content The content to upload.
         * @param {string} status Status of the content between published, unpublished or deleted.
         * @param {string} [labels] Labels to assign to the content. As a json encoded list of strings.
         * @param {boolean} [override] &#x60;Indicates if the content should be overwritten if file exists.&#x60; 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUpload(authorization: string, kbId: string, content: File, status: string, labels?: string, override?: boolean, options?: any): AxiosPromise<NodeDetail> {
            return localVarFp.postUpload(authorization, kbId, content, status, labels, override, options).then((request) => request(axios, basePath));
        },
        /**
         * Saves the crawl settings for the given domain. The domain will be recrawled with the new settings the next time the crawler runs. 
         * @summary Put webcrawler domain
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} host domain name
         * @param {PutWebcrawlerDomainRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWebcrawlerDomain(authorization: string, kbId: string, host: string, body?: PutWebcrawlerDomainRequest, options?: any): AxiosPromise<PutWebcrawlerDomainResponse> {
            return localVarFp.putWebcrawlerDomain(authorization, kbId, host, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the configuration of an already connected Document360 account.
         * @summary Update Document360 settings
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {PatchDocument360Request} body Update document360 request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocument360Settings(authorization: string, kbId: string, body: PatchDocument360Request, options?: any): AxiosPromise<PatchDocument360Response> {
            return localVarFp.updateDocument360Settings(authorization, kbId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to update a google drive setting with the list of folders/files to import. 
         * @summary Update existing authenticated Google Drive settings
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {UpdateSettingsRequest} body Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGDriveSettings(authorization: string, kbId: string, body: UpdateSettingsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateGDriveSettings(authorization, kbId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Validates a host to crawl by checking robots.txt and sitemap url. 
         * @summary validates a domain to crawl
         * @param {string} authorization Authorization Bearer header
         * @param {ValidateWebcrawlerDomainRequest} body The host name to check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateWebcrawlerDomain(authorization: string, body: ValidateWebcrawlerDomainRequest, options?: any): AxiosPromise<ValidateWebcrawlerDomainResponse> {
            return localVarFp.validateWebcrawlerDomain(authorization, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IngestionApi - object-oriented interface
 * @export
 * @class IngestionApi
 * @extends {BaseAPI}
 */
export class IngestionApi extends BaseAPI {
    /**
     * Use this endpoint to register a new Box client. 
     * @summary Authenticates a previously registered new box client.
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {string} code Exchange code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngestionApi
     */
    public authenticateBoxClient(authorization: string, kbId: string, code: string, options?: AxiosRequestConfig) {
        return IngestionApiFp(this.configuration).authenticateBoxClient(authorization, kbId, code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to update the authentication code and return a new access code for the user. 
     * @summary Exchange auth code for tokens to complete Oauthflow
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {AuthenticateGdriveRequest} body The redirect URI that the auth code and state token should be sent to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngestionApi
     */
    public authenticateGDrive(authorization: string, kbId: string, body: AuthenticateGdriveRequest, options?: AxiosRequestConfig) {
        return IngestionApiFp(this.configuration).authenticateGDrive(authorization, kbId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to exchange a zendesk token and complete authentication.
     * @summary Authenticate Zendesk token
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {AuthenticateZendeskRequest} body Create zendesk token request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngestionApi
     */
    public authenticateZendesk(authorization: string, kbId: string, body: AuthenticateZendeskRequest, options?: AxiosRequestConfig) {
        return IngestionApiFp(this.configuration).authenticateZendesk(authorization, kbId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to register a new Box client. Clients will then need to send the registration token. 
     * @summary Creates a new box client
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {CreateClientRequest} body Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngestionApi
     */
    public createBoxClient(authorization: string, kbId: string, body: CreateClientRequest, options?: AxiosRequestConfig) {
        return IngestionApiFp(this.configuration).createBoxClient(authorization, kbId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to create Document360 credentials.
     * @summary Connects a new Document360 account using an API key.
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {Document360Request} body Create document360 request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngestionApi
     */
    public createDocument360Settings(authorization: string, kbId: string, body: Document360Request, options?: AxiosRequestConfig) {
        return IngestionApiFp(this.configuration).createDocument360Settings(authorization, kbId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a webcralwer domain. After tdeletion all crawled documents will be archived and the domain will no longer be crawled. 
     * @summary Delete a webcrawler domain
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {string} host domain name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngestionApi
     */
    public deleteWebcrawlerDomain(authorization: string, kbId: string, host: string, options?: AxiosRequestConfig) {
        return IngestionApiFp(this.configuration).deleteWebcrawlerDomain(authorization, kbId, host, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Disconnects an existing Box client. 
     * @summary Disconnects a new box client
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngestionApi
     */
    public disconnectBoxClient(authorization: string, kbId: string, options?: AxiosRequestConfig) {
        return IngestionApiFp(this.configuration).disconnectBoxClient(authorization, kbId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to disconnect the Document360 Accout from this KB.
     * @summary Disconnect Document360
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngestionApi
     */
    public disconnectDocument360(authorization: string, kbId: string, options?: AxiosRequestConfig) {
        return IngestionApiFp(this.configuration).disconnectDocument360(authorization, kbId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to disconnect the Google Drive connection
     * @summary Disconnect GDrive
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngestionApi
     */
    public disconnectGDrive(authorization: string, kbId: string, options?: AxiosRequestConfig) {
        return IngestionApiFp(this.configuration).disconnectGDrive(authorization, kbId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to disconnect the Zendesk Accout from this KB.
     * @summary Disconnect Zendesk
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngestionApi
     */
    public disconnectZendesk(authorization: string, kbId: string, options?: AxiosRequestConfig) {
        return IngestionApiFp(this.configuration).disconnectZendesk(authorization, kbId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to schedule a run of this Google Drive import job 
     * @summary Schedule a GDrive job
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngestionApi
     */
    public gDriveScheduleJob(authorization: string, kbId: string, options?: AxiosRequestConfig) {
        return IngestionApiFp(this.configuration).gDriveScheduleJob(authorization, kbId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to the currently authorized services with the account
     * @summary Get authorized services
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngestionApi
     */
    public getAuthorizedServices(authorization: string, kbId: string, options?: AxiosRequestConfig) {
        return IngestionApiFp(this.configuration).getAuthorizedServices(authorization, kbId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to get the box client configuration. 
     * @summary Get new box client
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngestionApi
     */
    public getBoxClient(authorization: string, kbId: string, options?: AxiosRequestConfig) {
        return IngestionApiFp(this.configuration).getBoxClient(authorization, kbId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to get the current status of the Knowledgebases most recent import job on the box service. Also returns the error message if the job has failed. If the knowledgebase is not published return 412. if the knowledgebase does not exist return 404. 
     * @summary Get the status of the knowledgebases most recent import job on the box service.
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngestionApi
     */
    public getBoxLatestImportStatus(authorization: string, kbId: string, options?: AxiosRequestConfig) {
        return IngestionApiFp(this.configuration).getBoxLatestImportStatus(authorization, kbId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to get the current status of the Knowledgebases most recent import job on the Document360 service. Also returns the error message if the job has failed. If the knowledgebase is not published return 412. if the knowledgebase does not exist return 404. 
     * @summary Get the status of the knowledgebases most recent import job on the Document360 service.
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngestionApi
     */
    public getDocument360LatestImportStatus(authorization: string, kbId: string, options?: AxiosRequestConfig) {
        return IngestionApiFp(this.configuration).getDocument360LatestImportStatus(authorization, kbId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the Document360 settings for this KB. Returns 404 `not found` if no Document360 is configured. 
     * @summary Get Document360 settings
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngestionApi
     */
    public getDocument360Settings(authorization: string, kbId: string, options?: AxiosRequestConfig) {
        return IngestionApiFp(this.configuration).getDocument360Settings(authorization, kbId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to get the access token if exists and belongs to the current user. This can be used to get the access token for the user without having to reauthenticate. 
     * @summary Returns the access token
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngestionApi
     */
    public getGDriveAccessToken(authorization: string, kbId: string, options?: AxiosRequestConfig) {
        return IngestionApiFp(this.configuration).getGDriveAccessToken(authorization, kbId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to get the current status of the Knowledgebases most recent import job on the Google Drive service. Also returns the error message if the job has failed. If the knowledgebase is not published return 412. if the knowledgebase does not exist return 404. 
     * @summary Get the status of the knowledgebases most recent import job on the Google Drive service.
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngestionApi
     */
    public getGDriveLatestImportStatus(authorization: string, kbId: string, options?: AxiosRequestConfig) {
        return IngestionApiFp(this.configuration).getGDriveLatestImportStatus(authorization, kbId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to get the google drive settings. 
     * @summary Get Google Drive settings
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngestionApi
     */
    public getGDriveSettings(authorization: string, kbId: string, options?: AxiosRequestConfig) {
        return IngestionApiFp(this.configuration).getGDriveSettings(authorization, kbId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to get the current status of the Knowledgebases most recent Webcrawler import job from the host. Also returns the error message if the job has failed. If the knowledgebase is not published return 412. if the knowledgebase does not exist return 404. 
     * @summary Get the status of the knowledgebases most recent webcrawler import job from the host
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {string} host host name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngestionApi
     */
    public getWebcrawlerLatestImportStatus(authorization: string, kbId: string, host: string, options?: AxiosRequestConfig) {
        return IngestionApiFp(this.configuration).getWebcrawlerLatestImportStatus(authorization, kbId, host, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to get the current status of the Knowledgebases most recent import job on the Zendesk service. Also returns the error message if the job has failed. If the knowledgebase is not published return 412. if the knowledgebase does not exist return 404. 
     * @summary Get the status of the knowledgebases most recent import job on the Zendesk service.
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngestionApi
     */
    public getZendeskLatestImportStatus(authorization: string, kbId: string, options?: AxiosRequestConfig) {
        return IngestionApiFp(this.configuration).getZendeskLatestImportStatus(authorization, kbId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the Zendesk settings for this KB. Returns 404 `not found` if no Zendesk is configured. 
     * @summary Get Zendesk settings
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngestionApi
     */
    public getZendeskSettings(authorization: string, kbId: string, options?: AxiosRequestConfig) {
        return IngestionApiFp(this.configuration).getZendeskSettings(authorization, kbId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all the domains that the webcrawler should crawl with all their properties. 
     * @summary List domains to webcrawl
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {string} [host] domain name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngestionApi
     */
    public listWebcrawlerDomains(authorization: string, kbId: string, host?: string, options?: AxiosRequestConfig) {
        return IngestionApiFp(this.configuration).listWebcrawlerDomains(authorization, kbId, host, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to generate a Oauth authentical URL that will send the authorization code to the provided redirect url. 
     * @summary Returns the Oauth authentication URL
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {PostAuthUrlRequest} body Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngestionApi
     */
    public postGDriveAuthenticationUrl(authorization: string, kbId: string, body: PostAuthUrlRequest, options?: AxiosRequestConfig) {
        return IngestionApiFp(this.configuration).postGDriveAuthenticationUrl(authorization, kbId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * \'Uploads a document content and creates a new node to manage it. The file name is used to generate the ID. If a file with the same name re-uploaded it overrides the previous one.\' 
     * @summary Upload document
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {File} content The content to upload.
     * @param {string} status Status of the content between published, unpublished or deleted.
     * @param {string} [labels] Labels to assign to the content. As a json encoded list of strings.
     * @param {boolean} [override] &#x60;Indicates if the content should be overwritten if file exists.&#x60; 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngestionApi
     */
    public postUpload(authorization: string, kbId: string, content: File, status: string, labels?: string, override?: boolean, options?: AxiosRequestConfig) {
        return IngestionApiFp(this.configuration).postUpload(authorization, kbId, content, status, labels, override, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Saves the crawl settings for the given domain. The domain will be recrawled with the new settings the next time the crawler runs. 
     * @summary Put webcrawler domain
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {string} host domain name
     * @param {PutWebcrawlerDomainRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngestionApi
     */
    public putWebcrawlerDomain(authorization: string, kbId: string, host: string, body?: PutWebcrawlerDomainRequest, options?: AxiosRequestConfig) {
        return IngestionApiFp(this.configuration).putWebcrawlerDomain(authorization, kbId, host, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the configuration of an already connected Document360 account.
     * @summary Update Document360 settings
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {PatchDocument360Request} body Update document360 request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngestionApi
     */
    public updateDocument360Settings(authorization: string, kbId: string, body: PatchDocument360Request, options?: AxiosRequestConfig) {
        return IngestionApiFp(this.configuration).updateDocument360Settings(authorization, kbId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to update a google drive setting with the list of folders/files to import. 
     * @summary Update existing authenticated Google Drive settings
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {UpdateSettingsRequest} body Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngestionApi
     */
    public updateGDriveSettings(authorization: string, kbId: string, body: UpdateSettingsRequest, options?: AxiosRequestConfig) {
        return IngestionApiFp(this.configuration).updateGDriveSettings(authorization, kbId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Validates a host to crawl by checking robots.txt and sitemap url. 
     * @summary validates a domain to crawl
     * @param {string} authorization Authorization Bearer header
     * @param {ValidateWebcrawlerDomainRequest} body The host name to check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngestionApi
     */
    public validateWebcrawlerDomain(authorization: string, body: ValidateWebcrawlerDomainRequest, options?: AxiosRequestConfig) {
        return IngestionApiFp(this.configuration).validateWebcrawlerDomain(authorization, body, options).then((request) => request(this.axios, this.basePath));
    }
}
