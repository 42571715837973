<template>
    <div class="d-fl-grow1 d-h100p d-d-flex d-fd-column d-ps-relative">
        <div
            class="d-d-flex d-fd-column d-pt12 d-pb8 d-px16 d-zi-popover d-bgc-primary d-h100p"
        >
            <div class="d-d-flex d-ai-center d-t d-td300 d-mb24">
                <div class="d-d-flex d-fw-wrap d-w100p d-fd-column">
                    <div
                        class="d-headline-medium d-pr16 d-d-flex d-ai-center d-h24 d-mb2 d-gg16"
                    >
                        Plugins
                    </div>
                    <div
                        class="d-d-flex d-ai-center d-t d-td300 d-pt2 d-zi-navigation-fixed d-body-small"
                    >
                        <p class="d-pr4">
                            Reusable components to integrate user data into
                            chatbots, enabling personalized responses based on
                            specific data, like call history, contacts or
                            scheduling details.
                        </p>
                    </div>
                </div>
            </div>
            <h5 class="d-mb12">Available plugins</h5>
            <div
                class="d-d-flex d-pr24 d-pb24 d-gg12"
                v-if="availablePlugins.length"
            >
                <plugin-card
                    v-for="availablePlugin in availablePlugins"
                    :key="availablePlugin?.id"
                    :plugin-data="availablePlugin"
                    v-model:plugin-ids="chatbotPluginIds"
                />
            </div>
            <dt-card
                v-else
                class="d-w264 d-mb24"
                content-class="d-body--sm d-h100p"
                container-class="d-ba d-bc-subtle d-bs-none"
            >
                <template #header>
                    <div class="d-d-flex d-ai-center d-wmn0">
                        <dt-icon name="slash" />
                        <span class="d-ml8 d-fw-semibold d-fl1 d-truncate">
                            No plugins available
                        </span>
                    </div>
                </template>
                <template #content>
                    When there are more available plugins they will be shown
                    here
                </template>
            </dt-card>
            <h5 class="d-mb12">Installed plugins</h5>
            <div
                class="d-d-flex d-fw-wrap d-pr24 d-pb24 d-gg12"
                v-if="installedPlugins.length"
            >
                <plugin-card
                    v-for="installedPlugin in installedPlugins"
                    :key="installedPlugin?.id"
                    :plugin-data="installedPlugin"
                    is-installed
                    v-model:plugin-ids="chatbotPluginIds"
                />
            </div>
            <dt-card
                v-else
                class="d-w264 d-mb24"
                content-class="d-body--sm d-h100p"
                container-class="d-ba d-bc-subtle d-bs-none"
            >
                <template #header>
                    <div class="d-d-flex d-ai-center d-wmn0">
                        <dt-icon name="slash" />
                        <span class="d-ml8 d-fw-semibold d-fl1 d-truncate">
                            No plugins installed
                        </span>
                    </div>
                </template>
                <template #content>
                    Installed plugins will be shown here where you can remove
                    them.
                </template>
            </dt-card>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';

import { type Knowledgebase } from '@/open-api';
import { ApiService } from '@/services/Api.service';

import PluginCard from '@/components/plugins/PluginCard.vue';

import { DtCard, DtIcon } from '@dialpad/dialtone/vue3';

export default defineComponent({
    components: {
        PluginCard,
        DtCard,
        DtIcon
    },
    mounted() {
        this.chatbotPluginIds = this.chatbot.plugin_ids;
    },
    setup() {
        const orgId: string = inject('orgId')!;

        return {
            orgId
        };
    },
    computed: {
        knowledgebase(): Knowledgebase {
            return this.$store.getters[`${this.orgId}/currentKnowledgebase`];
        },
        apiService(): ApiService | undefined {
            return this.$store.getters[`${this.orgId}/apiService`];
        },
        authToken(): string {
            return this.$store.getters[`${this.orgId}/authToken`];
        },
        chatbot(): any {
            return this.$store.getters[`${this.orgId}/currentChatbot`];
        },
        availablePlugins(): any[] {
            return this.plugins.filter(function (i) {
                return this.indexOf(i) < 0;
            }, this.installedPlugins);
        },
        installedPlugins(): any[] {
            return this.chatbotPluginIds.map((id: string) =>
                this.plugins.find((p: any) => {
                    if (p.id === id) return p;
                })
            );
        }
    },
    // methods: {
    //     async fetchPlugins() {
    //         this.loading = true;
    //         const response = await handleRequest<ListPluginsResponse>(
    //             ApiV2['2.0'].plugin.pluginList(this.authToken),
    //             this.orgId,
    //             true
    //         );
    //         if (!response.error && response.data) {
    //             this.plugins = response.data.plugins as Plugin[];
    //             console.log(JSON.stringify(response.data.plugins));
    //             this.chatbotPluginIds = this.chatbot.plugin_ids;
    //         }
    //         this.loading = false;
    //     }
    // },
    data() {
        return {
            plugins: [
                {
                    description:
                        'This plugin provides a transcript of the voice call that the user Bill had with a customer named Andrew.',
                    id: 'plugin-1',
                    title: 'Call Transcript'
                },
                {
                    description:
                        'This plugin provides the user profile of the contact Andrew Hall.',
                    id: 'plugin-2',
                    title: 'Contact Profile'
                },
                {
                    description:
                        'This plugin provides the user profile of the user Bill Johnson.',
                    id: 'plugin-3',
                    title: 'User Profile'
                },
                {
                    description:
                        'This plugin provides the action items from the conversation.',
                    id: 'plugin-4',
                    title: 'Action Items'
                },
                {
                    description:
                        'This plugin provides the call history of the user.',
                    id: 'plugin-5',
                    title: 'Call History'
                }
            ],
            chatbotPluginIds: [],
            isLoading: false
        };
    }
});
</script>
