<template>
    <div class="d-p24 d-h100p">
        <h1 class="d-gc12 d-fc-black-800 d-fw-bold d-mb24">
            {{ $t('Consolidate') }}
        </h1>
        <dt-tab-group size="sm" selected="broken-content-panel">
            <template #tabs>
                <dt-tab id="broken-content" panel-id="broken-content-panel">{{
                    $t('Broken contents')
                }}</dt-tab>
                <dt-tab
                    id="not-enough-queries"
                    panel-id="not-enough-queries-panel"
                >
                    {{ $t('Not enough queries') }}
                </dt-tab>
                <dt-tab id="too-many-queries" panel-id="too-many-queries-panel">
                    {{ $t('Too many queries') }}
                </dt-tab>
            </template>

            <dt-tab-panel
                id="broken-content-panel"
                tab-id="broken-content"
                class="d-mt16"
            >
                <p class="d-fc-black-800">{{ $t('Show only') }}</p>
            </dt-tab-panel>
            <dt-tab-panel
                id="not-enough-queries-panel"
                tab-id="not-enough-queries"
                class="d-mt16"
            >
                <p class="d-fc-black-800">Missing answer for:</p>
            </dt-tab-panel>
            <dt-tab-panel
                id="too-many-queries-panel"
                tab-id="too-many-queries"
                class="d-mt16"
            >
                <p class="d-fc-black-800">Missing answer for:</p>
            </dt-tab-panel>
            <dt-tab-panel
                id="isolated-queries-panel"
                tab-id="isolated-queries"
                class="d-mt16"
            >
                <p class="d-fc-black-800">Missing answer for:</p>
            </dt-tab-panel>
        </dt-tab-group>
    </div>
</template>

<script lang="ts">
import { DtTabGroup, DtTab, DtTabPanel } from '@dialpad/dialtone/vue3';
import { defineComponent } from 'vue';

export default defineComponent({
    components: { DtTabGroup, DtTab, DtTabPanel },
    mounted() {}
});
</script>
