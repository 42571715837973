<template>
    <div
        class="resize-parent d-h100p"
        :class="{
            'd-ps-relative': isOptimizedWizard,
            'd-br d-brw1 d-bc-subtle d-bgc-black-100': !isOptimizedWizard
        }"
    >
        <div
            :class="{
                'resize-content d-h100p d-w100p d-ps-absolute d-zi-base1 d-box-border d-of-y-auto':
                    !isOptimizedWizard
            }"
        >
            <div
                class="d-w100p d-h100p d-box-border d-d-flex d-fd-column"
                :class="{
                    'd-px16 d-pt12': !isOptimizedWizard
                }"
            >
                <div
                    class="d-d-flex d-ai-center d-mb8"
                    v-if="!isOptimizedWizard"
                >
                    <div class="d-fs-100 d-d-flex d-fd-column">
                        <span class="d-headline--sm">
                            {{
                                `${$t('Questions')} (${optimizedQuestions.filter((q) => q.status !== 'loading').length})`
                            }}
                        </span>
                        <span class="d-body--sm-compact">
                            {{
                                $t(
                                    'Questions or keywords that will trigger this response'
                                )
                            }}
                        </span>
                    </div>
                </div>
                <div class="d-ps-relative">
                    <Transition
                        name="fade"
                        @before-enter="setListInTransition"
                        @after-enter="unsetListInTransition"
                        @after-leave="unsetListInTransition"
                        @before-leave="setListInTransition"
                    >
                        <div v-if="!isOptimizing && !thinkingInTransition">
                            <TransitionGroup name="list" tag="ul">
                                <li
                                    class="d-d-flex d-w100p"
                                    v-for="(q, i) in optimizedQuestions"
                                    :key="q.text"
                                    @mouseenter="showHoverIcons(i)"
                                    @mouseleave="hideHoverIcons"
                                    :data-index="i"
                                >
                                    <div
                                        class="d-d-flex d-mb4 d-w100p d-t d-td300"
                                    >
                                        <div
                                            class="d-w100p d-hmn24 question-input"
                                            v-if="
                                                showQuestionInput ===
                                                i.toString()
                                            "
                                        >
                                            <!-- --------------------------------------------------------------- -->
                                            <!--                          QUESTION INPUT                         -->
                                            <!-- --------------------------------------------------------------- -->
                                            <dt-input
                                                size="md"
                                                v-focus-input
                                                name="questionInput"
                                                v-model="question"
                                                :messages="validate"
                                                input-wrapper-class="d-bgc-primary d-bar8 d-py2 d-mt2 d-mb1 d-pl4 d-ml1"
                                                @keydown.esc="
                                                    handleCancelQuestion(i)
                                                "
                                                @keydown.enter="
                                                    handleSubmitQuestion(i)
                                                "
                                                :disabled="
                                                    isUpdating || isOptimizing
                                                "
                                            >
                                                <template #rightIcon>
                                                    <!-- --------------------------------------------------------------- -->
                                                    <!--                      QUESTION INPUT BUTTONS                     -->
                                                    <!-- --------------------------------------------------------------- -->
                                                    <div
                                                        class="d-d-flex d-ai-center"
                                                    >
                                                        <dt-button
                                                            class="d-as-flex-start"
                                                            importance="clear"
                                                            kind="muted"
                                                            size="xs"
                                                            @click="
                                                                handleSubmitQuestion(
                                                                    i
                                                                )
                                                            "
                                                            :disabled="
                                                                isUpdating ||
                                                                isOptimizing
                                                            "
                                                        >
                                                            <template #icon>
                                                                <dt-icon
                                                                    name="check"
                                                                    size="200"
                                                                />
                                                            </template>
                                                        </dt-button>
                                                        <dt-button
                                                            class="d-as-flex-start d-ml4"
                                                            importance="clear"
                                                            kind="muted"
                                                            size="xs"
                                                            @click="
                                                                handleCancelQuestion(
                                                                    i
                                                                )
                                                            "
                                                            :disabled="
                                                                isUpdating ||
                                                                isOptimizing
                                                            "
                                                        >
                                                            <template #icon>
                                                                <dt-icon
                                                                    name="close"
                                                                    size="200"
                                                                />
                                                            </template>
                                                        </dt-button>
                                                    </div>
                                                </template>
                                            </dt-input>
                                        </div>
                                        <!-- --------------------------------------------------------------- -->
                                        <!--                               QUESTION                          -->
                                        <!-- --------------------------------------------------------------- -->
                                        <dt-card
                                            class="d-w100p d-bar8"
                                            content-class="d-pl16 d-pr4 d-py4 d-d-flex d-ai-center"
                                            :container-class="{
                                                'd-ba d-bc-subtle d-bs-none d-fc-secondary': true,
                                                'border-ai d-bc-transparent':
                                                    q.status === 'add' ||
                                                    q.status === 'loading',
                                                'h:d-bgc-critical-subtle':
                                                    q.status === 'remove'
                                            }"
                                            v-else
                                        >
                                            <template #content>
                                                <div
                                                    class="d-d-flex d-ai-center d-w100p d-hmn32"
                                                >
                                                    <div
                                                        class="d-w80p d-of-hidden d-body--md-compact"
                                                    >
                                                        <gradient-sparkle
                                                            v-if="
                                                                q.status ===
                                                                    'add' ||
                                                                q.status ===
                                                                    'loading'
                                                            "
                                                            name="sparkle"
                                                            size="200"
                                                            class="d-mr8"
                                                        />
                                                        <dt-badge
                                                            class="d-mr8"
                                                            :text="
                                                                $t('Removed')
                                                            "
                                                            type="critical"
                                                            v-if="
                                                                q.status ===
                                                                'remove'
                                                            "
                                                        />
                                                        <span
                                                            :class="{
                                                                'd-td-line-through':
                                                                    q.status ===
                                                                    'remove'
                                                            }"
                                                        >
                                                            {{ q.text }}
                                                        </span>
                                                    </div>
                                                    <div
                                                        class="d-w20p d-d-flex d-jc-flex-end"
                                                    >
                                                        <!-- --------------------------------------------------------------- -->
                                                        <!--                      QUESTION HOVER BUTTONS                     -->
                                                        <!-- --------------------------------------------------------------- -->
                                                        <dt-button
                                                            class="d-as-flex-start"
                                                            importance="clear"
                                                            kind="muted"
                                                            size="xs"
                                                            @click="
                                                                handleEditQuestion(
                                                                    i
                                                                )
                                                            "
                                                            v-show="
                                                                hoverIcons ===
                                                                    i &&
                                                                q.status !==
                                                                    'remove'
                                                            "
                                                        >
                                                            <template #icon>
                                                                <dt-icon
                                                                    name="edit-2"
                                                                    size="200"
                                                                />
                                                            </template>
                                                        </dt-button>
                                                        <dt-button
                                                            class="d-as-flex-start d-ml4"
                                                            importance="clear"
                                                            kind="muted"
                                                            size="xs"
                                                            @click="
                                                                handleDeleteQuestion(
                                                                    i
                                                                )
                                                            "
                                                            v-show="
                                                                hoverIcons ===
                                                                    i &&
                                                                q.status !==
                                                                    'remove'
                                                            "
                                                        >
                                                            <template #icon>
                                                                <dt-icon
                                                                    name="trash"
                                                                    size="200"
                                                                />
                                                            </template>
                                                        </dt-button>
                                                        <dt-button
                                                            class="d-as-flex-start d-ml4"
                                                            importance="clear"
                                                            kind="muted"
                                                            size="xs"
                                                            @click="
                                                                handleKeepQuestion(
                                                                    i
                                                                )
                                                            "
                                                            v-show="
                                                                hoverIcons ===
                                                                    i &&
                                                                q.status ===
                                                                    'remove'
                                                            "
                                                        >
                                                            {{ $t('Keep') }}
                                                        </dt-button>
                                                    </div>
                                                </div>
                                            </template>
                                        </dt-card>
                                    </div>
                                </li>
                            </TransitionGroup>
                            <Transition name="fade">
                                <div
                                    class="d-w100p d-bgc-black-100 d-ps-sticky d-b0 d-pt8 d-pb12 d-mt-auto"
                                    v-if="
                                        !isOptimizedWizard &&
                                        (!isOptimized ||
                                            (isOptimized && fullyPopulated))
                                    "
                                >
                                    <dt-button
                                        kind="muted"
                                        importance="outlined"
                                        size="xs"
                                        @click="
                                            handleAddQuestion(
                                                optimizedQuestions.length
                                            )
                                        "
                                        v-if="!showQuestionInput"
                                        :disabled="isUpdating || isOptimizing"
                                    >
                                        {{ $t('Add question') }}
                                    </dt-button>
                                    <dt-button
                                        class="d-ml8"
                                        kind="muted"
                                        importance="outlined"
                                        size="xs"
                                        @click="
                                            handleAttemptToOptimizeQuestions
                                        "
                                        v-if="!showQuestionInput"
                                        :disabled="isUpdating || isOptimizing"
                                    >
                                        <template #icon>
                                            <dt-icon
                                                name="sparkle"
                                                size="200"
                                            />
                                        </template>
                                        {{
                                            isOptimized
                                                ? $t('Reoptimize questions')
                                                : $t('Optimize questions')
                                        }}
                                    </dt-button>
                                </div>
                            </Transition>
                        </div>
                    </Transition>
                    <Transition
                        name="fade"
                        @before-enter="setThinkingInTransition"
                        @after-enter="unsetThinkingInTransition"
                        @after-leave="unsetThinkingInTransition"
                        @before-leave="setThinkingInTransition"
                    >
                        <div class="" v-if="isOptimizing && !listInTransition">
                            <li class="d-d-flex d-w100p">
                                <div class="d-d-flex d-mb4 d-w100p d-t d-td300">
                                    <dt-card
                                        class="d-w100p d-bar8"
                                        content-class="d-pl16 d-pr4 d-py4 d-d-flex d-ai-center"
                                        container-class="d-ba d-bc-subtle d-bs-none d-fc-secondary border-ai d-bc-transparent"
                                    >
                                        <template #content>
                                            <div
                                                class="d-d-flex d-ai-center d-w100p d-hmn32"
                                            >
                                                <div
                                                    class="d-w80p d-of-hidden d-body--md-compact"
                                                >
                                                    <gradient-sparkle
                                                        name="sparkle"
                                                        size="200"
                                                        class="d-mr8"
                                                    />
                                                    <span>
                                                        {{ $t('Thinking') }}
                                                    </span>
                                                </div>
                                            </div>
                                        </template>
                                    </dt-card>
                                </div>
                            </li>
                        </div>
                    </Transition>
                </div>
            </div>
        </div>
        <div
            class="d-ps-relative d-r-horizontal d-of-x-auto d-zi-base resize"
            v-if="!isOptimizedWizard"
        />
        <dt-modal
            :close-button-props="{ ariaLabel: `${$t('Close exit prompt')}` }"
            v-model:show="showOptimizeWithUnsavedQuestionsPrompt"
            v-if="!isOptimizedWizard"
        >
            <template #header>
                <div>{{ $t('Unsaved changes') }}</div>
            </template>
            <template #default>
                <p>
                    {{
                        $t(
                            'Optimizing the questions will override any unsaved changes.'
                        )
                    }}
                </p>
                <p>{{ $t('Are you sure you want to proceed?') }}</p>
            </template>
            <template #footer>
                <dt-button @click="handleSaveAndOptimize">
                    {{ $t('Save and continue') }}
                </dt-button>
                <dt-button importance="clear" @click="handleProceedOptimize">
                    {{ $t('Discard changes and continue') }}
                </dt-button>
                <dt-button
                    importance="clear"
                    kind="danger"
                    @click="handleCancelOptimize"
                >
                    {{ $t('Cancel') }}
                </dt-button>
            </template>
        </dt-modal>
    </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';

import {
    DtButton,
    DtIcon,
    DtInput,
    DtCard,
    DtBadge,
    DtModal,
    VALIDATION_MESSAGE_TYPES
} from '@dialpad/dialtone/vue3';
import GradientSparkle from '@/assets/gradient-sparkle.vue';
import { handleRequest, type IQuestion } from '@/utils/Common';
import type { Knowledgebase, OptimizeQueriesResponse } from '@/open-api';
import { NOTICE_KINDS } from '@/utils/Constants';
import type { ApiService } from '@/services/Api.service';
import { set } from 'husky';

export default defineComponent({
    props: {
        questions: {
            type: Array as PropType<IQuestion[]>,
            default: () => []
        },
        isDirty: {
            type: Boolean as PropType<boolean>
        },
        maxHeight: {
            type: String as PropType<string>,
            default: '100%'
        },
        minWidth: {
            type: String as PropType<string>,
            default: '100%'
        },
        maxWidth: {
            type: String as PropType<string>,
            default: '100%'
        },
        isUpdating: {
            type: Boolean as PropType<boolean>
        },
        nodeId: {
            type: String as PropType<string>
        },
        orgId: {
            type: String as PropType<string>
        },
        isOptimizedWizard: {
            type: Boolean as PropType<boolean>
        },
        isSaved: {
            type: Boolean as PropType<boolean>
        }
    },
    components: {
        DtButton,
        DtIcon,
        DtInput,
        DtCard,
        DtBadge,
        DtModal,
        GradientSparkle
    },
    watch: {
        questions(newQuestions, oldQuestions) {
            if (newQuestions !== oldQuestions) {
                this.optimizedQuestions = newQuestions;
            }
        },
        isSaved(newIsSaved) {
            if (newIsSaved) {
                this.handleProceedOptimize();
                // set isSaved to its default state
                this.$emit('update:isSaved', false);
            }
        },
        maxHeight(newHeight, oldHeight) {
            if (newHeight !== oldHeight) {
                this.sidebarHeight = newHeight;
            }
        },
        minWidth(newWidth, oldWidth) {
            if (newWidth !== oldWidth) {
                this.minSidebarWidth = newWidth;
            }
        },
        maxWidth(newWidth, oldWidth) {
            if (newWidth !== oldWidth) {
                this.maxSidebarWidth = newWidth;
            }
        }
    },
    computed: {
        authToken(): string {
            return this.$store.getters[`${this.orgId}/authToken`];
        },
        apiService(): ApiService {
            return this.$store.getters[`${this.orgId}/apiService`];
        },
        knowledgebase(): Knowledgebase {
            return this.$store.getters[`${this.orgId}/currentKnowledgebase`];
        },
        isOptimizing(): boolean {
            return this.optimizing;
        }
    },
    methods: {
        handleHideQuestionInput() {
            this.showQuestionInput = '';
            this.validate = [];
            this.question = '';
        },
        handleAddQuestion(i: number) {
            const index = i.toString();

            this.optimizedQuestions.push({ text: '', status: 'keep' });

            this.showQuestionInput = index;
        },
        async handleAttemptToOptimizeQuestions() {
            if (this.isDirty) {
                this.showOptimizeWithUnsavedQuestionsPrompt = true;
            } else {
                this.showOptimizeWithUnsavedQuestionsPrompt = false;
                await this.handleOptimizeQuestion();
            }
        },
        handleCancelOptimize() {
            this.showOptimizeWithUnsavedQuestionsPrompt = false;
        },
        async handleProceedOptimize() {
            this.showOptimizeWithUnsavedQuestionsPrompt = false;
            await this.handleOptimizeQuestion();
        },
        async handleSaveAndOptimize() {
            this.$emit('save');
        },
        async handleOptimizeQuestion() {
            this.optimizing = true;
            this.isOptimized = true;

            const res = await handleRequest<OptimizeQueriesResponse>(
                this.apiService?.improve.optimizeNodeQueries(
                    this.authToken,
                    this.knowledgebase?.id,
                    this.nodeId
                ),
                this.orgId
            );

            if (res.error) {
                return;
            }

            this.fullyPopulated = false;
            this.optimizedQuestions = [];

            const optimizeResp: any = res.data;

            const newOptimizedQuestions = [] as IQuestion[];
            Object.keys(optimizeResp).forEach((status: string) => {
                if (optimizeResp[status]?.length) {
                    optimizeResp[status].forEach((text: string) => {
                        newOptimizedQuestions.push({ text, status });
                    });
                }
            });

            if (
                JSON.stringify(this.optimizedQuestions.slice(1)) ===
                JSON.stringify(newOptimizedQuestions)
            ) {
                this.$store.commit(`${this.orgId}/addNotification`, {
                    kind: NOTICE_KINDS.SUCCESS,
                    title: this.$t('Optimize questions'),
                    message: this.$t('Your questions are looking good')
                });
            }

            this.optimizing = false;
            await this.handleNewOptimizedQuestions(newOptimizedQuestions);
            this.fullyPopulated = true;
            this.$emit('update:questions', this.optimizedQuestions);
        },
        handleSubmitQuestion(i: number) {
            if (this.question.length < this.minCharacters) {
                this.validate = [
                    {
                        message: this.$t(
                            'Question should be at least 3 characters'
                        ),
                        type: VALIDATION_MESSAGE_TYPES.ERROR
                    }
                ];
                return;
            }
            if (this.question.length > this.maxCharacters) {
                this.validate = [
                    {
                        message: this.$t(
                            'Questions should be less than 1,000 characters'
                        ),
                        type: VALIDATION_MESSAGE_TYPES.ERROR
                    }
                ];
                return;
            }
            this.optimizedQuestions[i].text = this.question;
            this.$emit('update:questions', this.optimizedQuestions);
            this.handleHideQuestionInput();
        },
        handleCancelQuestion(i: number) {
            if (this.optimizedQuestions?.[i]?.text === '') {
                this.handleDeleteQuestion(i);
            }
            this.handleHideQuestionInput();
        },
        handleEditQuestion(i: number) {
            this.showQuestionInput = i.toString();
            this.question = this.optimizedQuestions[i]?.text;
            this.hoverIcons = null;
        },
        handleDeleteQuestion(i: number) {
            if (!this.isOptimizedWizard) {
                this.optimizedQuestions.splice(i, 1);
            } else {
                this.optimizedQuestions[i].status = 'remove';
            }
            this.$emit('update:questions', this.optimizedQuestions);
            this.question = '';
        },
        handleKeepQuestion(i: number) {
            this.optimizedQuestions[i].status = 'keep';
            this.$emit('update:questions', this.optimizedQuestions);
            this.question = '';
        },
        /* v8 ignore next 9 */
        showHoverIcons(i: number) {
            if (this.showQuestionInput || this.isOptimizing) {
                return;
            }
            this.hoverIcons = i;
        },
        hideHoverIcons() {
            this.hoverIcons = null;
        },
        setListInTransition() {
            this.listInTransition = true;
        },
        unsetListInTransition() {
            // Give buffer between transitions
            this.listInTransition = false;
        },
        setThinkingInTransition() {
            this.thinkingInTransition = true;
        },
        unsetThinkingInTransition() {
            // Give buffer between transitions
            this.thinkingInTransition = false;
        },
        async handleNewOptimizedQuestions(optimizedQuestions: IQuestion[]) {
            return new Promise<void>((resolve) => {
                optimizedQuestions.forEach((question, index) => {
                    setTimeout(() => {
                        this.optimizedQuestions.push(question);
                        if (optimizedQuestions.length - 1 === index) resolve();
                    }, 300 * index);
                });
            });
        }
    },
    data() {
        return {
            sidebarHeight: this.maxHeight,
            minSidebarWidth: this.minWidth,
            maxSidebarWidth: this.maxWidth,
            optimizedQuestions: [] as IQuestion[],
            question: '',
            showQuestionInput: '',
            hoverIcons: null as null | number,
            minCharacters: 3,
            maxCharacters: 1000,
            validate: undefined as any,
            thinkingInTransition: false,
            listInTransition: false,
            optimizing: false,
            isOptimized: false,
            fullyPopulated: false,
            showOptimizeWithUnsavedQuestionsPrompt: false
        };
    }
});
</script>

<style lang="less">
.resize {
    min-width: v-bind(minSidebarWidth);
    max-width: v-bind(maxSidebarWidth);
    height: v-bind(sidebarHeight);

    /* this is necessary to make the scrollbars appear */
    &::before {
        content: '';
        width: 105%;
        height: 105%;
        background: var(--bgc-primary);
        pointer-events: none;
        display: block;
        position: absolute;
    }

    /* the scrollbar controls the size of the resizer */
    &::-webkit-scrollbar {
        height: v-bind(sidebarHeight);
        /*at least the same as .resize */
        width: 5px;
    }

    &::-webkit-resizer {
        background: var(--dt-color-black-100);
    }

    &:hover,
    &:active {
        &::-webkit-resizer {
            background: hsla(
                var(--dt-color-purple-200-h) var(--dt-color-purple-200-s)
                    var(--dt-color-purple-200-l) / var(--bgo)
            ) !important;
        }
    }
}

.resize-content {
    padding-left: 5px;
    /* same scrollbar width */
    margin-left: -5px;
    /* same scrollbar width */
}

.question-input {
    & .d-validation-message {
        margin-top: 2px;
        margin-bottom: 4px;
    }
}

.list-enter-active,
.list-leave-active {
    transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
    opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
    position: absolute;
    width: 100%;
}
</style>
