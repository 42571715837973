import axios, { CreateAxiosDefaults } from 'axios';
import store from '../store';

// Accept-Language: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Accept-Language
// Locale is a string of format "xx-XX"
export function configureAxios(orgId: string, config?: CreateAxiosDefaults) {
    const instance = axios.create({ ...config });

    instance.interceptors.request.use(
        (config) => {
            config.headers['Accept-Language'] =
                store.getters[`${orgId}/locale`];
            return config;
        },
        () => {}
    );

    return instance;
}
