import type { Subject } from 'rxjs';

import { setBlockType, toggleMark } from 'prosemirror-commands';
import type { EditorView } from 'prosemirror-view';
import { Plugin, type Command } from 'prosemirror-state';
import { wrapInList, liftListItem } from 'prosemirror-schema-list';
import type { NodeType } from 'prosemirror-model';

import { schema } from '@/components/rich-text-editor/schema';
import { toolbarItem } from '@/components/rich-text-editor/Toolbar.vue';
import { markdownSerializer } from '@/components/rich-text-editor/markdown';
import { TEXT_BLOCK_LIMIT } from '@/utils/Constants';
import store from '@/store';

/* v8 ignore next 210 */
export const commandBold: Command = toggleMark(schema.marks.strong);
export function toggleBold(editor: EditorView) {
    commandBold(editor.state, editor.dispatch, editor as any);
}

export const commandItalic: Command = toggleMark(schema.marks.em);
export function toggleItalic(editor: EditorView) {
    commandItalic(editor.state, editor.dispatch, editor as any);
}

export const commandStrikethrough: Command = toggleMark(schema.marks.s);
export function toggleStrikethrough(editor: EditorView) {
    commandStrikethrough(editor.state, editor.dispatch, editor as any);
}

export const commandCode: Command = toggleMark(schema.marks.code);
export function toggleCode(editor: EditorView) {
    commandCode(editor.state, editor.dispatch, editor as any);
}
export const commandCodeBlock: Command = setBlockType(schema.nodes.code_block);
export const commandParagraph: Command = setBlockType(schema.nodes.paragraph);

export function toggleCodeBlock(editor: EditorView) {
    const isActive = isCurrentlyActive(editor, schema.nodes.code_block);

    //Remove the attribute if it is present and reapply the new one
    if (isActive) {
        commandParagraph(editor.state, editor.dispatch, editor as any);
    } else {
        commandCodeBlock(editor.state, editor.dispatch, editor as any);
    }
}

export const commandOrderedList: Command = wrapInList(
    schema.nodes.ordered_list
);
export const removeOrderedList: Command = liftListItem(schema.nodes.list_item);

export function toggleOrderedList(editor: EditorView) {
    const isActive = isCurrentlyActive(editor, schema.nodes.ordered_list);

    if (isActive) {
        removeOrderedList(editor.state, editor.dispatch);
    } else {
        commandOrderedList(editor.state, editor.dispatch, editor as any);
    }
}

export const commandBulletList: Command = wrapInList(schema.nodes.bullet_list);
export const removeBulletList: Command = liftListItem(schema.nodes.list_item);
export function toggleBulletList(editor: EditorView) {
    const isActive = isCurrentlyActive(editor, schema.nodes.bullet_list);

    if (isActive) {
        removeBulletList(editor.state, editor.dispatch);
    } else {
        commandBulletList(editor.state, editor.dispatch, editor as any);
    }
}

function isCurrentlyActive(editor: EditorView, nodeType: NodeType) {
    const node = editor.state.selection;

    // @ts-ignore
    return node.$from.path.some((item: any) => item.type == nodeType);
}

export function toggleLink(
    editor: EditorView,
    title: string,
    href: string,
    isTextSelection?: boolean
) {
    // If text selection
    if (isTextSelection) {
        const commandLink: Command = toggleMark(schema.marks.link, {
            title,
            href,
            value: title
        });
        commandLink(editor.state, editor.dispatch, editor as any);
    } else {
        const attrs = { title: title || href, href };
        const node = schema.text(title || attrs.title, [
            schema.marks.link.create(attrs)
        ]);
        editor.dispatch(editor.state.tr.replaceSelectionWith(node, false));
    }
}

export function updateLink(editor: EditorView, title: string, href: string) {
    const attrs = { title: title || href, href: href };
    const node = schema.text(attrs.title, [schema.marks.link.create(attrs)]);

    const { from, to } = getSelectionNodePos(editor);
    editor.dispatch(editor.state.tr.replaceRangeWith(from, to, node));
}

export function removeLink(editor: EditorView) {
    const { from, to } = getSelectionNodePos(editor);
    editor.dispatch(editor.state.tr.delete(from, to));
}

function getSelectionNodePos(editor: EditorView) {
    const { state } = editor;
    const { selection } = state;
    const { $from } = selection;
    const pos = $from.pos - $from.textOffset;
    const previousNode = state.doc.nodeAt(pos);

    let to = pos;
    if (previousNode) {
        to += previousNode.nodeSize;
    }
    const from = to - (previousNode?.text?.length || 0);
    return { from, to };
}

export interface Options {
    bold: boolean;
    italic: boolean;
    strikethrough: boolean;
    code: boolean;
    codeBlock: boolean;
    bulletList: boolean;
    numberList: boolean;
    link: boolean;
}

export interface updateToolbarOptions {
    active: Options;
    disabled: Options;
    content?: any[];
    toggleMark: (item: toolbarItem, attrs?: any) => void;
}

class MenuView {
    private readonly $updateToolbar;
    private readonly editor;
    private readonly activeOptions: Options;
    private readonly disabledOptions: Options;
    constructor(editor: EditorView, $updateToolbar: Subject<any>) {
        this.editor = editor;
        this.$updateToolbar = $updateToolbar;
        this.activeOptions = {
            bold: false,
            italic: false,
            strikethrough: false,
            code: false,
            codeBlock: false,
            bulletList: false,
            numberList: false,
            link: false
        };
        this.disabledOptions = {
            bold: false,
            italic: false,
            strikethrough: false,
            code: false,
            codeBlock: false,
            bulletList: false,
            numberList: false,
            link: false
        };
    }

    getActiveMarkCodes(): any[] {
        const isEmpty = this.editor.state.selection.empty;
        const state = this.editor.state;

        if (isEmpty) {
            const $from = this.editor.state.selection.$from;
            const storedMarks = state.storedMarks;

            // Return either the stored marks, or the marks at the cursor position.
            // Stored marks are the marks that are going to be applied to the next input
            // if you dispatched a mark toggle with an empty cursor.
            if (storedMarks) {
                return storedMarks.map((mark) => mark.type.name);
            } else {
                return $from.marks().map((mark) => mark.type.name);
            }
        } else {
            const $head = this.editor.state.selection.$head;
            const $anchor = this.editor.state.selection.$anchor;

            // We're using a Set to not get duplicate values
            const activeMarks = new Set();

            // Here we're getting the marks at the head and anchor of the selection
            $head.marks().forEach((mark) => activeMarks.add(mark.type.name));
            $anchor.marks().forEach((mark) => activeMarks.add(mark.type.name));

            return Array.from(activeMarks);
        }
    }

    getActiveNode(): string {
        const depth = this.editor.state.selection.$head.depth || 0;
        // @ts-ignore
        const nodes = this.editor.state.selection.$head.path.filter(
            (node: any) => !!node?.type?.name?.length
        );
        return nodes[nodes.length - depth]?.type?.name;
    }

    toggleMark(editor: EditorView) {
        return (item: toolbarItem, attrs: any) => {
            switch (item.toString()) {
                case toolbarItem.Bold: {
                    toggleBold(editor);
                    break;
                }
                case toolbarItem.Italic: {
                    toggleItalic(editor);
                    break;
                }
                case toolbarItem.Link: {
                    toggleLink(editor, attrs.title, attrs.href);
                    break;
                }
                case toolbarItem.Code: {
                    toggleCode(editor);
                    break;
                }
                case toolbarItem.CodeBlock: {
                    toggleCodeBlock(editor);
                    break;
                }
                case toolbarItem.Strikethrough: {
                    toggleStrikethrough(editor);
                    break;
                }
                case toolbarItem.NumberList: {
                    toggleOrderedList(editor);
                    break;
                }
                case toolbarItem.BulletList: {
                    toggleBulletList(editor);
                    break;
                }
            }
        };
    }

    toggleNode() {}

    update() {
        const activeMarks = this.getActiveMarkCodes();
        const activeNode = this.getActiveNode();
        const { from, to } = getSelectionNodePos(this.editor);
        const selectedText = this.editor.state.doc.slice(from, to);
        // @ts-ignore
        const content: any[] = selectedText.content.content || [];

        this.activeOptions.link = activeMarks.includes(toolbarItem.Link);
        this.activeOptions.bold = activeMarks.includes(toolbarItem.Bold);
        this.activeOptions.italic = activeMarks.includes(toolbarItem.Italic);
        this.activeOptions.code = activeMarks.includes(toolbarItem.Code);
        this.activeOptions.codeBlock = toolbarItem.CodeBlock === activeNode;
        this.activeOptions.bulletList = toolbarItem.BulletList === activeNode;
        this.activeOptions.numberList = toolbarItem.NumberList === activeNode;
        this.activeOptions.strikethrough = activeMarks.includes(
            toolbarItem.Strikethrough
        );

        this.$updateToolbar.next({
            active: { ...this.activeOptions },
            disabled: { ...this.disabledOptions },
            content,
            toggleMark: this.toggleMark(this.editor)
        } as updateToolbarOptions);
    }

    destroy() {}
}

export function getMarkAtPos(view: any, pos: any, markType: any) {
    const doc = view.state.tr.doc,
        $pos = doc.resolve(pos),
        start = $pos.parent.childAfter($pos.parentOffset);

    if (start.node) {
        // node is a TextNode in our use case.
        const mark = start.node.marks.find(
            (mark: any) => mark.type.name === markType
        );
        if (mark) {
            return { $pos, start, mark };
        }
    }
}

export let MENU_VIEW: MenuView;
export function menu($updateToolbar: Subject<any>) {
    return new Plugin({
        view(editor) {
            // @ts-ignore
            MENU_VIEW = new MenuView(editor, $updateToolbar);
            return MENU_VIEW;
        }
    });
}

export function characterCount(orgId: string) {
    return new Plugin({
        filterTransaction: (transaction, state) => {
            const limit = TEXT_BLOCK_LIMIT;
            const oldSize = markdownSerializer.serialize(state.tr.doc).length;

            const newSize = markdownSerializer.serialize(
                transaction.doc
            ).length;

            // Everything is in the limit. Good.
            if (newSize <= limit) {
                store.commit(`${orgId}/setMarkdownCharCount`, newSize);
                return true;
            }

            // The limit has already been exceeded but will be reduced.
            if (oldSize > limit && newSize > limit && newSize <= oldSize) {
                return true;
            }

            // The limit has already been exceeded and will be increased further.
            if (oldSize > limit && newSize > limit && newSize > oldSize) {
                return false;
            }

            const isPaste = transaction.getMeta('paste');

            // Block all exceeding transactions that were not pasted.
            if (!isPaste) {
                return false;
            }

            // For pasted content, we try to remove the exceeding content.
            const pos = transaction.selection.$head.pos;
            const over = newSize - limit;
            const from = pos - over;
            const to = pos;

            if (from < 0) {
                return false;
            }
            // It’s probably a bad idea to mutate transactions within `filterTransaction`
            // but for now this is working fine.
            transaction.deleteRange(from, to);
            store.commit(`${orgId}/setMarkdownCharCount`, limit);
            return true;
        }
    });
}
