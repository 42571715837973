<template>
    <dt-tooltip :message="$t(message)">
        <template #anchor>
            <div class="d-gg4 d-d-flex d-ai-center">
                <dt-icon size="100" :name="iconType" class="d-fc-muted" />
                <span>{{ count }}</span>
            </div>
        </template>
    </dt-tooltip>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { DtIcon, DtTooltip } from '@dialpad/dialtone/vue3';

export enum StatCounterType {
    MissingAnswers = 'missing_answers',
    Queries = 'queries',
    ThumbsUp = 'thumbs_up',
    ThumbsDown = 'thumbs_down'
}

export default defineComponent({
    props: {
        type: {
            type: String as PropType<StatCounterType>,
            required: true
        },
        count: {
            type: Number as PropType<number>,
            default: 0
        }
    },
    components: {
        DtIcon,
        DtTooltip
    },
    computed: {
        iconType(): string {
            switch (this.type.toString()) {
                case StatCounterType.MissingAnswers: {
                    return 'alert-triangle';
                }
                case StatCounterType.Queries: {
                    return 'message';
                }
                case StatCounterType.ThumbsUp: {
                    return 'thumbs-up';
                }
                case StatCounterType.ThumbsDown: {
                    return 'thumbs-down';
                }
            }
            return '';
        },
        message(): string {
            switch (this.type.toString()) {
                case StatCounterType.MissingAnswers: {
                    return 'Unanswered questions';
                }
                case StatCounterType.Queries: {
                    return 'Queries';
                }
                case StatCounterType.ThumbsUp: {
                    return 'Thumbs up';
                }
                case StatCounterType.ThumbsDown: {
                    return 'Thumbs down';
                }
            }
            return '';
        }
    }
});
</script>
