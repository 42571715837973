<template>
    <div
        ref="editor"
        class="d-bgc-primary d-fl-grow1 d-d-flex d-fd-column d-h100p markdown-editor"
        :class="{
            'd-btr4': !shouldShowToolbar,
            'd-bbr4': !shouldShowFooter,
            'd-hmn64': editable
        }"
    ></div>
</template>

<script lang="ts">
import { defineComponent, inject, type PropType, toRaw } from 'vue';
import { EditorView } from 'prosemirror-view';
import { EditorState } from 'prosemirror-state';
import { history } from 'prosemirror-history';
import { keymap } from 'prosemirror-keymap';
import { baseKeymap } from 'prosemirror-commands';
import { dropCursor } from 'prosemirror-dropcursor';
import { gapCursor } from 'prosemirror-gapcursor';

import { schema } from '@/components/rich-text-editor/schema';
import { markdownParser } from '@/components/rich-text-editor/markdown';
import { buildInputRules } from '@/components/rich-text-editor/input-rules';
import { buildKeymap } from '@/components/rich-text-editor/keymap';
import { characterCount } from '@/components/rich-text-editor/utils';

export default defineComponent({
    props: {
        shouldShowToolbar: {
            type: Boolean as PropType<boolean>
        },
        shouldShowFooter: {
            type: Boolean as PropType<boolean>
        },
        editable: {
            type: Boolean as PropType<boolean>
        },
        isAlreadyEditing: {
            type: Boolean as PropType<boolean>
        },
        editorView: {
            type: Object as PropType<any>
        },
        schema: {
            type: Object as PropType<any>
        },
        content: {
            type: String as PropType<string>
        },
        updateToolbar: {
            type: Object as PropType<any>
        },
        menuPlugin: {
            type: Object as PropType<any>
        }
    },
    setup() {
        const orgId: string = inject('orgId')!;
        return {
            orgId
        };
    },
    computed: {
        editorInstance: {
            get(): any {
                return this.editorView;
            },
            set(content: any) {
                this.$emit('update:editorView', content);
            }
        }
    },
    mounted() {
        /* v8 ignore next 22 */
        const editor = this.$refs.editor as HTMLElement;
        if (editor) {
            this.editorInstance = new EditorView(editor, {
                editable: () => this.editable || !this.isAlreadyEditing,
                state: EditorState.create({
                    schema,
                    doc: markdownParser.parse(this.content) || undefined,
                    plugins: [
                        buildInputRules(this.schema),
                        this.menuPlugin,
                        history(),
                        keymap(buildKeymap(toRaw(this.schema))),
                        keymap(baseKeymap),
                        dropCursor(),
                        gapCursor(),
                        characterCount(this.orgId)
                    ]
                }),
                attributes: {
                    class: 'd-bgc-primary d-fl-grow1 d-d-flex d-fd-column d-h100p d-py8 d-px12 d-mx4'
                },
                transformPastedHTML(html) {
                    return html.replace(/<img.*?>/g, '');
                }
            });
        }
    }
});
</script>

<style lang="less">
.markdown-editor {
    .d-list-group {
        padding: 0 0 0 var(--dt-size-400) !important;
    }
    .d-list-group.d-lst-circle {
        margin-left: 10px !important;
        li {
            list-style: disc;
        }
    }

    .d-list-group.d-lst-decimal {
        margin-left: 10px !important;
        li {
            list-style: auto;
        }
    }
}

blockquote {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
}
</style>
