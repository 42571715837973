import { name } from '../package.json';
import { defineWebComponentElement } from './build';
import locales from '@/locales';
import { createI18n } from 'vue-i18n';

const VueCustomApp = defineWebComponentElement();

const messages = {
    'en-US': locales.en_US,
    'pt-PT': locales.pt_PT,
    'es-ES': locales.es_ES,
    'fr-FR': locales.fr_FR,
    'it-IT': locales.it_IT,
    'de-DE': locales.de_DE,
    'zh-CN': locales.zh_CN,
    'ja-JP': locales.ja_JP,
    'nl-NL': locales.nl_NL,
    'ru-RU': locales.ru_RU
};

/*
To test all language copies use this script in devtools.

var locales = [
    "de-DE",
    "es-ES",
    "fr-FR",
    "it-IT",
    "ja-JP",
    "nl-NL",
    "pt-PT",
    "ru-RU",
    "zh-CN",
    "en-US"
];
locales.forEach((currentLocale, index) => {
    setTimeout(() => {
        document.getElementsByTagName('dx-console')[0].setAttribute('locale', currentLocale);
    }, 1000 * index);
});
* */

export const i18n = createI18n({
    globalInjection: true,
    legacy: false,
    locale: 'en-US',
    fallbackLocale: 'en-US', // set fallback locale
    messages
});

if (customElements.get(name) === undefined) {
    customElements.define(name, VueCustomApp);
}
