<template>
    <dt-skeleton
        :paragraphOption="{
            rows: 1,
            width: ['100%'],
            rowClass: 'd-h72'
        }"
        class="d-mb8"
        v-if="isLoading"
    />
    <dt-card
        v-else
        class="d-w100p d-bar8 h:d-bgc-moderate d-c-pointer d-t d-td300 d-mb8"
        content-class="d-px16 d-py12 d-d-flex d-ai-center"
        container-class="d-ba d-bc-subtle d-bs-none d-fc-secondary"
    >
        <template #content>
            <div class="d-d-flex d-ai-center d-w100p d-jc-space-between">
                <div class="d-d-flex d-fd-column">
                    <div class="d-headline--md">
                        {{ `${$t(title)}` }}
                        <dt-badge
                            v-if="badgeText"
                            class="d-ml8"
                            :text="$t(badgeText)"
                            :type="badgeType"
                        />
                    </div>
                    <div>
                        {{ `${$t(description)}` }}
                    </div>
                </div>
                <dt-icon
                    name="chevron-right"
                    size="500"
                    class="d-o50 d-t d-td300 d-ml16"
                    :class="{ 'd-o100': isHover }"
                />
            </div>
        </template>
    </dt-card>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';

import { DtIcon, DtCard, DtBadge, DtSkeleton } from '@dialpad/dialtone/vue3';
import type { Knowledgebase } from '@/open-api';

export default defineComponent({
    props: {
        title: {
            type: String as PropType<string>,
            required: true
        },
        description: {
            type: String as PropType<string>,
            required: true
        },
        badgeType: {
            type: String as PropType<string>
        },
        badgeText: {
            type: String as PropType<string>
        },
        isHover: {
            type: Boolean as PropType<boolean>
        },
        isLoading: {
            type: Boolean as PropType<boolean>
        }
    },
    components: {
        DtIcon,
        DtCard,
        DtBadge,
        DtSkeleton
    },
    computed: {
        authToken(): string {
            return this.$store.getters[`${this.orgId}/authToken`];
        },
        knowledgebase(): Knowledgebase {
            return this.$store.getters[`${this.orgId}/currentKnowledgebase`];
        }
    }
});
</script>
