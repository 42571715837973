/* tslint:disable */
/* eslint-disable */
/**
 * Mind
 * Kare API v4.0
 *
 * The version of the OpenAPI document: 4.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface GetStatusResponse
 */
export interface GetStatusResponse {
    /**
     * Error message if the job has failed
     * @type {string}
     * @memberof GetStatusResponse
     */
    'error'?: string;
    /**
     * Additional source identifier.
     * @type {string}
     * @memberof GetStatusResponse
     */
    'source_id'?: string;
    /**
     * The name of this data source.
     * @type {string}
     * @memberof GetStatusResponse
     */
    'source_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatusResponse
     */
    'status'?: GetStatusResponseStatusEnum;
}

export const GetStatusResponseStatusEnum = {
    Scheduled: 'scheduled',
    Running: 'running',
    Finished: 'finished',
    Failed: 'failed'
} as const;

export type GetStatusResponseStatusEnum = typeof GetStatusResponseStatusEnum[keyof typeof GetStatusResponseStatusEnum];


