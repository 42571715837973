<template>
    <div class="d-d-flex d-h100p d-of-x-hidden">
        <default-template-view
            ref="view"
            :title="$t('Default messages')"
            :title-description="
                $t(
                    `Customize your ${chatbotName}'s default messages by selecting a response from your knowledge base.`
                )
            "
            table-fixed-header
            table-id="staticMessagesTable"
            :table-data="staticMessages"
            :active-row-id="activeRowId"
            :columns="columns"
            :is-fetching="isFetching"
            :has-error="hasError"
            hide-checkbox
            item-type="messages"
            @table-row-click="handleOpenStaticMessage"
            hide-search
            id-key="key"
            class="d-of-y-auto"
        >
            <template #actionButtons>
                <dt-button
                    size="xs"
                    importance="clear"
                    @click="resetValues"
                    :disabled="!newStaticMessages.length"
                >
                    {{ $t('Discard') }}
                </dt-button>
                <dt-button
                    class="d-ml4"
                    size="xs"
                    @click="saveChanges"
                    :disabled="!newStaticMessages.length"
                >
                    {{ $t('Save changes') }}
                </dt-button>
            </template>
        </default-template-view>
        <div
            class="d-h100p"
            style="max-width: 400px"
            v-if="!aiAssistLoading && selectedMessage"
        >
            <ai-assist-drawer
                :ai-assist-loading="aiAssistLoading"
                :app-id="currentChatbot.id"
                :app-name="selectedMessage.title"
                :width="400"
                hide-close
                read-only
                :has-validation-error="!selectedMessage.valid"
                :show-empty-state="emptyPreview"
            />
        </div>
    </div>
    <web-component-loader
        v-model:loading="aiAssistLoading"
        :source="aiAssistChatbotUrl"
    />
</template>
<script lang="tsx">
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { defineComponent, h, inject } from 'vue';

import DefaultTemplateView from '@/views/DefaultTemplateView.vue';

import type { ApiService } from '@/services/Api.service';
import type { DrawerService } from '@/services/Drawer.service';

import {
    type Knowledgebase,
    type DialogStaticMessage,
    type InitResponse,
    type PostInMessageRequest,
    type NodeDetail,
    type ListNodesResponse,
    type PutDialogStaticMessageRequest,
    type Widget,
    NodeStatus,
    OutTextAspect,
    Uri,
    LicenseType
} from '@/open-api';

import AiAssistDrawer from '@/components/ai-assist-drawer/AiAssistDrawer.vue';
import WebComponentLoader from '@/components/webcomponent-loader/WebComponentLoader.vue';
import FilterPopover from '@/components/filter-popover/FilterPopover.vue';
import SearchNodesPopover from '@/components/search-nodes-popover/SearchNodesPopover.vue';
import type {
    IBaseTableColumn,
    IBaseTableRow
} from '@/components/base-table/BaseTable.types';

import { handleRequest, uuidv4 } from '@/utils/Common';
import { NODE_TYPES } from '@/utils/types/Response';
import type { INotification } from '@/utils/types/Notification';
import { DtValidationMessages, DtButton } from '@dialpad/dialtone/vue3';
import { NOTICE_KINDS } from '@/utils/Constants';
import { APP_BY_LICENSE_TYPE, APP_TYPES } from '@/utils/types/App';

function initialState() {
    return {
        staticMessages: [] as DialogStaticMessage[],
        isFetching: false,
        isFetchingPreview: false,
        hasError: false,
        activeRowId: undefined as string | undefined,
        wrapperWidth: 0 as number,
        selectedMessage: undefined as DialogStaticMessage | undefined,
        chatbotData: undefined as InitResponse | undefined,
        responses: undefined as NodeDetail[] | undefined,
        isSaving: false,
        newStaticMessages: [] as DialogStaticMessage[],
        emptyPreview: false
    };
}
export default defineComponent({
    components: {
        AiAssistDrawer,
        WebComponentLoader,
        DefaultTemplateView,
        FilterPopover,
        DtButton,
        DtValidationMessages
    },
    setup() {
        const orgId: string = inject('orgId')!;
        return {
            orgId
        };
    },
    async mounted() {
        await this.fetchAll();
    },
    computed: {
        apiService(): ApiService {
            return this.$store.getters[`${this.orgId}/apiService`];
        },
        authToken(): string {
            return this.$store.getters[`${this.orgId}/authToken`];
        },
        currentChatbot(): Widget {
            return this.$store.getters[`${this.orgId}/currentChatbot`];
        },
        currentKnowledgebase(): Knowledgebase {
            return this.$store.getters[`${this.orgId}/currentKnowledgebase`];
        },
        maxContentWidth(): string {
            return this.$store.getters[`${this.orgId}/maxContentWidth`];
        },
        drawerService(): DrawerService {
            return this.$store.getters[`${this.orgId}/drawerService`];
        },
        sidebarWidth(): number {
            return this.$store.getters[`${this.orgId}/showSidebarMenu`]
                ? 216
                : 0;
        },
        shouldDisableInputs(): boolean {
            return this.isSaving || this.isFetching || this.isFetchingPreview;
        },
        columns(): IBaseTableColumn<DialogStaticMessage>[] {
            return [
                {
                    title: 'Message',
                    type: 'string',
                    key: 'title',
                    width: '50%',
                    sortable: false,
                    template: (data: DialogStaticMessage) => {
                        return (
                            <div className="d-d-flex d-fd-column d-t d-td300 d-gg2 d-px16">
                                <span className="d-truncate">{data.title}</span>
                                <span className="d-fs-100 d-fc-secondary">
                                    {data.description}
                                </span>
                            </div>
                        );
                    }
                },
                {
                    title: 'Response',
                    type: 'string',
                    key: 'default',
                    width: '50%',
                    sortable: false,
                    template: (staticMessage: DialogStaticMessage) => {
                        let staticMsg: DialogStaticMessage | undefined;

                        if (
                            this.selectedMessage?.key === staticMessage.key &&
                            this.selectedMessage?.node_title !==
                                staticMessage.node_title
                        ) {
                            staticMsg = this.selectedMessage;
                        } else {
                            staticMsg = staticMessage;
                        }

                        if (!staticMsg) {
                            return null;
                        }

                        return (
                            <div className="d-w100p static-message-select-wrapper d-ps-relative d-fd-column d-gg8 d-px16 d-py12">
                                <SearchNodesPopover
                                    id={`static-nodes-options-${staticMsg.key}`}
                                    responses={this.responses}
                                    fetch={(e: string) =>
                                        this.changeStaticMessage(
                                            staticMsg as DialogStaticMessage,
                                            e
                                        )
                                    }
                                    selectedOptions={[
                                        !staticMsg.node_title
                                            ? 'default'
                                            : staticMsg.node_title
                                    ]}
                                    isFetching={
                                        this.isFetching || this.isSaving
                                    }
                                    moveX={-1}
                                    hideCheckboxes
                                    closeOnSelect
                                    dialogStyle=""
                                    isDisabled={this.shouldDisableInputs}
                                    isNormalSelectStyle
                                    showInvalidOption={!staticMsg.valid}
                                />
                                <DtValidationMessages
                                    validation-messages={[
                                        {
                                            type: 'error',
                                            message: this.$t(
                                                'Selected response is not published'
                                            )
                                        }
                                    ]}
                                    show-messages={!staticMsg.valid}
                                />
                            </div>
                        );
                    }
                }
            ] as IBaseTableColumn<DialogStaticMessage>[];
        },
        aiAssistChatbotUrl(): string {
            return `https://virtual-assistant.${this.$store.getters[`${this.orgId}/zone`]}.karehq.com/latest.js`;
        },
        chatbotName(): string {
            return APP_BY_LICENSE_TYPE.SINGULAR[
                this.currentChatbot.license_type
            ];
        },
        chatbotNamePluralized(): string {
            return APP_BY_LICENSE_TYPE.PLURAL[this.currentChatbot.license_type];
        }
    },
    methods: {
        async fetchAll() {
            await this.fetchResponses();
            await this.fetchStaticMessages();

            await this.$nextTick();
            this.selectFirstMessage();
        },
        async fetchStaticMessages() {
            this.isFetching = true;
            const res = await this.apiService.audience.listDialogStaticMessages(
                this.authToken,
                this.currentKnowledgebase.app_id!,
                this.currentKnowledgebase.id!
            );

            this.isFetching = false;
            if (res.data) {
                this.staticMessages = res.data.messages || [];
            }
        },
        async fetchResponses() {
            this.isFetching = true;

            const res = await handleRequest<ListNodesResponse>(
                this.apiService.knowledge.listNodes(
                    this.authToken,
                    this.currentKnowledgebase?.id!,
                    NODE_TYPES.RESPONSE,
                    NodeStatus.Published,
                    undefined,
                    undefined
                ),
                this.orgId
            );
            this.isFetching = false;

            if (res?.data) {
                this.responses = res.data.nodes;
            }
        },
        saveChanges() {
            this.newStaticMessages.forEach(
                (staticMessage: DialogStaticMessage) =>
                    this.updateStaticMessage(staticMessage)
            );
        },
        async updateStaticMessage(staticMessage: DialogStaticMessage) {
            this.isSaving = true;
            let res;
            if (staticMessage.node_id !== 'default') {
                const body: PutDialogStaticMessageRequest = {
                    node_id: staticMessage.node_id
                };

                res = await handleRequest(
                    this.apiService.audience.putDialogStaticMessage(
                        this.authToken,
                        this.currentKnowledgebase.app_id!,
                        this.currentKnowledgebase.id!,
                        staticMessage.key,
                        body
                    ),
                    this.orgId
                );
            } else {
                res = await handleRequest(
                    this.apiService.audience.deleteDialogStaticMessage(
                        this.authToken,
                        this.currentKnowledgebase.app_id!,
                        this.currentKnowledgebase.id!,
                        staticMessage.key
                    ),
                    this.orgId
                );
            }
            if (!res.error) {
                this.$store.commit(`${this.orgId}/addNotification`, {
                    kind: NOTICE_KINDS.SUCCESS,
                    message: `${staticMessage.title} ${this.$t('updated')}`
                } as INotification);
            }
            this.isSaving = false;
            this.newStaticMessages = [];
        },
        async changeStaticMessage(
            staticMessage: DialogStaticMessage,
            selectedResponseId: string
        ) {
            if (selectedResponseId && selectedResponseId !== 'default') {
                const eventBodies = await this.pullNode(selectedResponseId);
                staticMessage.node_id = selectedResponseId;
                staticMessage.node_title = selectedResponseId;
                staticMessage.valid = true;
                this.selectedMessage = staticMessage;
                this.updatePreview(eventBodies);
            } else {
                const eventBodies = this.defaultMessage(staticMessage.default);
                this.updatePreview(eventBodies);
                staticMessage.node_id = 'default';
                staticMessage.node_title = '';
                staticMessage.valid = true;
            }

            const found = this.newStaticMessages.findIndex(
                (msg: DialogStaticMessage) => msg.key === staticMessage.key
            );
            if (found === -1) {
                this.newStaticMessages.push(staticMessage);
            }
        },
        async handleOpenStaticMessage(
            staticMessage: IBaseTableRow<DialogStaticMessage>
        ) {
            if (this.activeRowId === staticMessage.id) return;
            this.selectedMessage = staticMessage.data;
            this.isFetchingPreview = true;
            this.activeRowId = staticMessage.id;

            if (!this.chatbotData) {
                const res = await this.apiService.dialog.init(
                    this.currentChatbot.id!,
                    undefined, // authorization
                    undefined, // user id
                    this.currentKnowledgebase.locale!,
                    1,
                    undefined, // as per DP-98063, user-agent should not be sent
                    this.currentKnowledgebase.locale,
                    window.location.href
                );

                if (res.data) {
                    this.chatbotData = res.data;
                }
            }

            if (this.chatbotData) {
                let eventBodies: { event_body: any; uri: Uri }[] = [];
                if (staticMessage.data.node_id) {
                    eventBodies = await this.pullNode(
                        staticMessage.data.node_id
                    );
                } else {
                    eventBodies = this.defaultMessage(
                        staticMessage.data.default
                    );
                }

                this.updatePreview(eventBodies);
            }
            this.isFetchingPreview = false;
        },
        updatePreview(eventBodies: { event_body: any; uri: Uri }[]) {
            const hasPreviewText = eventBodies.some((eventBody) => {
                return Object.keys(eventBody.event_body).some(
                    (key: string) => eventBody.event_body[key]?.text
                );
            });

            this.emptyPreview = !hasPreviewText;
            if (hasPreviewText) {
                const currentChatbotWindow = (window as any).DialpadVA?.[
                    this.currentChatbot.id!
                ];
                if (currentChatbotWindow) {
                    currentChatbotWindow.ShowConversationHistory(
                        eventBodies,
                        uuidv4()
                    );
                }
            }
        },
        defaultMessage(
            defaultMessage: string
        ): { event_body: any; uri: Uri }[] {
            return [
                {
                    event_body: {
                        ['out_text']: {
                            text: defaultMessage,
                            aspect: OutTextAspect.ResolutionAnswerText
                        }
                    },
                    uri: Uri.OutText
                }
            ];
        },
        async pullNode(
            nodeId: string
        ): Promise<{ event_body: any; uri: Uri }[]> {
            const body: PostInMessageRequest = {
                conversation_id: this.chatbotData!.conversation_id,
                message: {
                    header: {},
                    uri: Uri.InPull,
                    message_body: {
                        in_pull: {
                            node_id: nodeId
                        }
                    }
                }
            };
            const resPull = await this.apiService.dialog.postInMessage(
                this.chatbotData!.dialog_token,
                body,
                this.currentKnowledgebase.locale!,
                1,
                undefined, // as per DP-98063, user-agent should not be sent
                this.currentKnowledgebase.locale!,
                window.location.href
            );
            if (resPull.data) {
                return resPull.data.messages.map((msg) => ({
                    event_body: msg.message_body,
                    uri: msg.uri
                }));
            }
            return [];
        },
        selectFirstMessage() {
            const refView = this.$refs.view as any;
            if (this.staticMessages.length && refView?.$el) {
                const rows = (refView.$el as HTMLDivElement)
                    .querySelector('#staticMessagesTable')
                    ?.querySelectorAll(
                        '.data-grid-row'
                    ) as NodeListOf<HTMLDivElement>;

                if (rows?.length) {
                    // Make sure it's the second row since we have the header row included as well
                    rows[1].click();
                }
            }
        },
        async resetValues() {
            Object.assign(this.$data, initialState());
            await this.fetchAll();
        }
    },
    data() {
        return {
            ...initialState(),
            aiAssistLoading: true
        };
    }
});
</script>

<style lang="less">
.static-message-select-wrapper > div {
    width: 100% !important;
}
</style>
