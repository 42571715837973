<template>
    <dt-tooltip
        v-if="isGenerative"
        v-feature="'generative_bot'"
        :class="styleClass"
        placement="bottom"
        :message="$t(`This ${chatbotName} leverages Generative Ai assistance`)"
    >
        <template #anchor>
            <div
                class="d-d-flex d-ai-center d-ml-auto d-px4 d-py2 d-baw1 d-ba d-bc-subtle d-bar8 d-bgc-primary"
            >
                <dt-icon class="d-mr4" name="dialpad-ai-color" size="200" />
                <span class="d-body-compact-small"> Assisted </span>
            </div>
        </template>
    </dt-tooltip>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import { DtTooltip, DtIcon } from '@dialpad/dialtone/vue3';
import { Widget } from '@/open-api';
import { APP_BY_LICENSE_TYPE } from '@/utils/types/App';

export default defineComponent({
    /* v8 ignore next 1000 */
    props: {
        chatbot: {
            type: Object as PropType<Widget>,
            required: true
        },
        styleClass: {
            type: String as PropType<string>
        }
    },
    components: {
        DtTooltip,
        DtIcon
    },
    computed: {
        isGenerative(): boolean {
            return this.chatbot.generative || false;
        },
        chatbotName(): string {
            return APP_BY_LICENSE_TYPE.SINGULAR[this.chatbot.license_type];
        }
    }
});
</script>
