<template>
    <dt-toast
        v-model:show="show"
        :title="title || notificationTitle"
        :kind="kind"
        :close-button-props="{ ariaLabel: 'Close notification' }"
        :duration="6000"
    >
        {{ message }}
        <template #action>
            <dt-button
                size="sm"
                class="d-truncate"
                v-if="action && Object.keys(action).length"
                @click="handleActionClick"
                importance="outlined"
                kind="muted"
            >
                {{ actionTitle }}
            </dt-button>
        </template>
    </dt-toast>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import { DtToast, DtButton } from '@dialpad/dialtone/vue3';
import { Knowledgebase } from '@/open-api';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    props: {
        kind: {
            type: String as PropType<
                'base' | 'info' | 'error' | 'success' | 'warning'
            >,
            default: 'base'
        },
        message: {
            type: String as PropType<string>
        },
        title: {
            type: String as PropType<string>
        },
        action: {
            type: Object as PropType<{
                title?: string;
                routerName?: string;
                tabName?: string;
            }>
        }
    },
    components: {
        DtToast,
        DtButton
    },
    setup(props) {
        const { t } = useI18n();
        let notificationTitle: string;
        switch (props.kind) {
            case 'info':
                notificationTitle = t('Information');
                break;
            case 'error':
                notificationTitle = t('Error');
                break;
            case 'success':
                notificationTitle = t('Success');
        }
        return {
            t,
            notificationTitle
        };
    },
    computed: {
        knowledgebase(): Knowledgebase {
            return this.$store.getters[`${this.orgId}/currentKnowledgebase`];
        },
        actionTitle(): string | undefined {
            return this.action?.title;
        }
    },
    methods: {
        handleActionClick() {
            this.$router.push({
                name: this.action.routerName,
                params: {
                    appId: this.$route.params?.appId,
                    kbId: this.knowledgebase?.id
                },
                query: {
                    subTab: this.action.tabName
                }
            });
        }
    },
    data() {
        return {
            show: true
        };
    }
});
</script>
