<template>
    <root-layout
        :side-bar-menu="shouldShowSidebarMenu"
        :knowledge-top-bar="shouldShowKnowledgeMenu"
    >
        <template #notifications>
            <notification-wrapper />
        </template>
    </root-layout>
</template>

<script lang="ts">
import { defineComponent, inject, type PropType } from 'vue';
import { setTheme } from '@dialpad/dialtone/themes/config';
import DpLight from '@dialpad/dialtone/themes/dp-light';
import DpDark from '@dialpad/dialtone/themes/dp-dark';

import type { Knowledgebase } from '@/open-api';

import StaticMessagesView from '@/views/StaticMessagesView.vue';
import HistoryView from '@/views/HistoryView.vue';

import DrawerTemplate from '@/components/drawer-template/DrawerTemplate.vue';
import NotificationWrapper from '@/components/notification-wrapper/NotificationWrapper.vue';
import NodeQuestions from '@/components/node-editor/NodeQuestions.vue';
import OptimizeWizardView from '@/views/OptimizeWizardView.vue';
import RootLayout from '@/components/root-layout/RootLayout.vue';
import ProseMirror from '@/components/rich-text-editor/RichTextEditor.vue';
import MockedDataWatermark from '@/components/mocked-data-watermark/MockedDataWatermark.vue';
import RichTextEditor from '@/components/rich-text-editor/RichTextEditor.vue';
import ChatbotDrawer from '@/components/chatbot-drawer/ChatbotDrawer.vue';
import WebcrawlerPage from '@/components/sources-drawer/WebcrawlerPage.vue';
import ImproveView from '@/views/ImproveView.vue';
import EditorBlock from '@/components/rich-text-editor/EditorBlock.vue';

import type { DIALPAD_LOCALES, ZONES_ABBR } from '@/utils/Constants';
import { convertToBoolean, isKnowledgeRoute } from '@/utils/Common';

import type { Drawer, DrawerService } from '@/services/Drawer.service';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ArcElement,
    BarElement
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ArcElement,
    BarElement
);

export default defineComponent({
    props: {
        orgName: {
            type: String as PropType<string>
        },
        zone: {
            type: String as PropType<ZONES_ABBR>,
            required: true
        },
        locale: {
            type: String as PropType<DIALPAD_LOCALES>
        },
        authToken: {
            type: String as PropType<string>
        },
        refreshToken: {
            type: String as PropType<string>
        },
        document: {
            type: Object as PropType<HTMLElement>
        },
        showSidebarMenu: {
            default: true
        },
        bodyStyle: {
            type: String as PropType<string>
        },
        limited: {
            type: Boolean as PropType<boolean>
        },
        darkMode: {
            type: String as PropType<string | boolean>
        },
        companyId: {
            type: String as PropType<string>
        },
        currentPath: {
            type: String as PropType<string>
        },
        newWebchatClient: {
            type: String as PropType<string>
        },
        officeName: {
            type: String as PropType<string>
        },
        timezone: {
            type: String as PropType<string>
        }
    },
    components: {
        ProseMirror,
        RootLayout,
        HistoryView,
        MockedDataWatermark,
        NotificationWrapper,
        DrawerTemplate,
        NodeQuestions,
        StaticMessagesView,
        RichTextEditor,
        ChatbotDrawer,
        WebcrawlerPage,
        ImproveView,
        OptimizeWizardView,
        EditorBlock
    },
    watch: {
        $route(to, from) {
            if (!!this.drawer && from.name !== to.name) {
                this.drawerService?.closeDrawer();
            }
        }
    },
    computed: {
        shouldShowSidebarMenu(): boolean {
            return convertToBoolean(this.showSidebarMenu);
        },
        shouldShowKnowledgeMenu(): boolean {
            return isKnowledgeRoute(
                this.$router.currentRoute.value.name || undefined
            );
        },
        knowledgebase(): Knowledgebase {
            return this.$store.getters[`${this.orgId}/currentKnowledgebase`];
        },
        drawerService(): DrawerService | undefined {
            return this.$store.getters[`${this.orgId}/drawerService`];
        },
        drawer(): Drawer | undefined {
            return this.drawerService?.getOpenDrawer();
        },
        drawerInTransition(): boolean {
            return !!this.drawerService?.getInTransition();
        }
    },
    setup(props) {
        const orgId: string = inject('orgId')!;
        if (props.darkMode) {
            setTheme(DpDark);
        } else {
            setTheme(DpLight);
        }
        return {
            orgId
        };
    },
    beforeUnmount() {
        if (this.orgId) {
            // Got to make sure to remove SDK instance
            delete (window as any).DialpadDX[this.orgId];
        }
    },
    data() {
        return {
            customWidth: '' as string | undefined
        };
    }
});
</script>

<style lang="less">
@import (css) '@dialpad/dialtone/css';
@import (css) '@dialpad/dialtone/vue3/css';
@import (css) 'prosemirror-view/style/prosemirror.css';
@import (css) 'prosemirror-example-setup/style/style.css';
@import (css) 'prosemirror-menu/style/menu.css';

body {
    height: 100%;
    position: relative;
}

ul {
    padding: 0 !important;
}

.loading {
    animation-name: spin;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}

.d-root-layout--fixed {
    height: calc(100% - 64px);
}

.d-root-layout {
    min-height: calc(100% - 64px);
}

video {
    width: 100%;
}

.border-ai {
    background: linear-gradient(
            var(--dt-color-surface-primary),
            var(--dt-color-surface-primary)
        ),
        var(--dt-badge-color-background-ai);
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
}

.dt-item-layout--selected {
    height: 2.1rem !important;
}
</style>
