/* tslint:disable */
/* eslint-disable */
/**
 * Mind
 * Kare API v4.0
 *
 * The version of the OpenAPI document: 4.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetDialogStaticMessageResponse } from '../model';
// @ts-ignore
import { ListDialogStaticMessagesResponse } from '../model';
// @ts-ignore
import { ModelError } from '../model';
// @ts-ignore
import { PutDialogStaticMessageRequest } from '../model';
// @ts-ignore
import { PutDialogStaticMessageResponse } from '../model';
/**
 * AudienceApi - axios parameter creator
 * @export
 */
export const AudienceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Resets the dialog engine to use the default dialog static messages matching the given org, locale and key. 
         * @summary Delete dialog static message
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId App ID
         * @param {string} kbId Knowledgebase ID
         * @param {'i18n-nothing-found' | 'i18n-welcome' | 'i18n-pop-over' | 'i18n-thanks-for-feedback-up' | 'i18n-thanks-for-feedback-down' | 'i18n-disambiguation-post' | 'i18n-message-received' | 'i18n-stuck-in-a-loop' | 'i18n-exit-message' | 'i18n-internal-error' | 'i18n-intent-escalate' | 'i18n-intent-abusive' | 'i18n-guardrail-harmful' | 'i18n-guardrail-inappropriate' | 'i18n-guardrail-irrelevant' | 'i18n-survey' | 'i18n-route-update' | 'i18n-route-failure' | 'i18n-session-close-agent' | 'i18n-session-close-bot'} key Message key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDialogStaticMessage: async (authorization: string, appId: string, kbId: string, key: 'i18n-nothing-found' | 'i18n-welcome' | 'i18n-pop-over' | 'i18n-thanks-for-feedback-up' | 'i18n-thanks-for-feedback-down' | 'i18n-disambiguation-post' | 'i18n-message-received' | 'i18n-stuck-in-a-loop' | 'i18n-exit-message' | 'i18n-internal-error' | 'i18n-intent-escalate' | 'i18n-intent-abusive' | 'i18n-guardrail-harmful' | 'i18n-guardrail-inappropriate' | 'i18n-guardrail-irrelevant' | 'i18n-survey' | 'i18n-route-update' | 'i18n-route-failure' | 'i18n-session-close-agent' | 'i18n-session-close-bot', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('deleteDialogStaticMessage', 'authorization', authorization)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('deleteDialogStaticMessage', 'appId', appId)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('deleteDialogStaticMessage', 'kbId', kbId)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('deleteDialogStaticMessage', 'key', key)
            const localVarPath = `/audience/{app_id}/kb/{kb_id}/messages/{key}`
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)))
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeAudienceWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the dialog static messages matching the given key for the given org with the given locale. 
         * @summary Get dialog static message
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId App ID
         * @param {string} kbId Knowledgebase ID
         * @param {'i18n-nothing-found' | 'i18n-welcome' | 'i18n-pop-over' | 'i18n-thanks-for-feedback-up' | 'i18n-thanks-for-feedback-down' | 'i18n-disambiguation-post' | 'i18n-message-received' | 'i18n-stuck-in-a-loop' | 'i18n-exit-message' | 'i18n-internal-error' | 'i18n-intent-escalate' | 'i18n-intent-abusive' | 'i18n-guardrail-harmful' | 'i18n-guardrail-inappropriate' | 'i18n-guardrail-irrelevant' | 'i18n-survey' | 'i18n-route-update' | 'i18n-route-failure' | 'i18n-session-close-agent' | 'i18n-session-close-bot'} key Message key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDialogStaticMessage: async (authorization: string, appId: string, kbId: string, key: 'i18n-nothing-found' | 'i18n-welcome' | 'i18n-pop-over' | 'i18n-thanks-for-feedback-up' | 'i18n-thanks-for-feedback-down' | 'i18n-disambiguation-post' | 'i18n-message-received' | 'i18n-stuck-in-a-loop' | 'i18n-exit-message' | 'i18n-internal-error' | 'i18n-intent-escalate' | 'i18n-intent-abusive' | 'i18n-guardrail-harmful' | 'i18n-guardrail-inappropriate' | 'i18n-guardrail-irrelevant' | 'i18n-survey' | 'i18n-route-update' | 'i18n-route-failure' | 'i18n-session-close-agent' | 'i18n-session-close-bot', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getDialogStaticMessage', 'authorization', authorization)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getDialogStaticMessage', 'appId', appId)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('getDialogStaticMessage', 'kbId', kbId)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getDialogStaticMessage', 'key', key)
            const localVarPath = `/audience/{app_id}/kb/{kb_id}/messages/{key}`
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)))
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeAudienceRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all the dialog static messages for the given org with the given locale. Static messages can be overridden with a node in order to customise the conversation. 
         * @summary List dialog static messages
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId App ID
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDialogStaticMessages: async (authorization: string, appId: string, kbId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('listDialogStaticMessages', 'authorization', authorization)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('listDialogStaticMessages', 'appId', appId)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('listDialogStaticMessages', 'kbId', kbId)
            const localVarPath = `/audience/{app_id}/kb/{kb_id}/messages`
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeAudienceRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Configures the dialog engine to override the dialog static messages matching the given org, locale and key with a certain node. 
         * @summary Put dialog static message
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId App ID
         * @param {string} kbId Knowledgebase ID
         * @param {'i18n-nothing-found' | 'i18n-welcome' | 'i18n-pop-over' | 'i18n-thanks-for-feedback-up' | 'i18n-thanks-for-feedback-down' | 'i18n-disambiguation-post' | 'i18n-message-received' | 'i18n-stuck-in-a-loop' | 'i18n-exit-message' | 'i18n-internal-error' | 'i18n-intent-escalate' | 'i18n-intent-abusive' | 'i18n-guardrail-harmful' | 'i18n-guardrail-inappropriate' | 'i18n-guardrail-irrelevant' | 'i18n-survey' | 'i18n-route-update' | 'i18n-route-failure' | 'i18n-session-close-agent' | 'i18n-session-close-bot'} key Message key
         * @param {PutDialogStaticMessageRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDialogStaticMessage: async (authorization: string, appId: string, kbId: string, key: 'i18n-nothing-found' | 'i18n-welcome' | 'i18n-pop-over' | 'i18n-thanks-for-feedback-up' | 'i18n-thanks-for-feedback-down' | 'i18n-disambiguation-post' | 'i18n-message-received' | 'i18n-stuck-in-a-loop' | 'i18n-exit-message' | 'i18n-internal-error' | 'i18n-intent-escalate' | 'i18n-intent-abusive' | 'i18n-guardrail-harmful' | 'i18n-guardrail-inappropriate' | 'i18n-guardrail-irrelevant' | 'i18n-survey' | 'i18n-route-update' | 'i18n-route-failure' | 'i18n-session-close-agent' | 'i18n-session-close-bot', body?: PutDialogStaticMessageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('putDialogStaticMessage', 'authorization', authorization)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('putDialogStaticMessage', 'appId', appId)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('putDialogStaticMessage', 'kbId', kbId)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('putDialogStaticMessage', 'key', key)
            const localVarPath = `/audience/{app_id}/kb/{kb_id}/messages/{key}`
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)))
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeAudienceWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AudienceApi - functional programming interface
 * @export
 */
export const AudienceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AudienceApiAxiosParamCreator(configuration)
    return {
        /**
         * Resets the dialog engine to use the default dialog static messages matching the given org, locale and key. 
         * @summary Delete dialog static message
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId App ID
         * @param {string} kbId Knowledgebase ID
         * @param {'i18n-nothing-found' | 'i18n-welcome' | 'i18n-pop-over' | 'i18n-thanks-for-feedback-up' | 'i18n-thanks-for-feedback-down' | 'i18n-disambiguation-post' | 'i18n-message-received' | 'i18n-stuck-in-a-loop' | 'i18n-exit-message' | 'i18n-internal-error' | 'i18n-intent-escalate' | 'i18n-intent-abusive' | 'i18n-guardrail-harmful' | 'i18n-guardrail-inappropriate' | 'i18n-guardrail-irrelevant' | 'i18n-survey' | 'i18n-route-update' | 'i18n-route-failure' | 'i18n-session-close-agent' | 'i18n-session-close-bot'} key Message key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDialogStaticMessage(authorization: string, appId: string, kbId: string, key: 'i18n-nothing-found' | 'i18n-welcome' | 'i18n-pop-over' | 'i18n-thanks-for-feedback-up' | 'i18n-thanks-for-feedback-down' | 'i18n-disambiguation-post' | 'i18n-message-received' | 'i18n-stuck-in-a-loop' | 'i18n-exit-message' | 'i18n-internal-error' | 'i18n-intent-escalate' | 'i18n-intent-abusive' | 'i18n-guardrail-harmful' | 'i18n-guardrail-inappropriate' | 'i18n-guardrail-irrelevant' | 'i18n-survey' | 'i18n-route-update' | 'i18n-route-failure' | 'i18n-session-close-agent' | 'i18n-session-close-bot', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDialogStaticMessage(authorization, appId, kbId, key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the dialog static messages matching the given key for the given org with the given locale. 
         * @summary Get dialog static message
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId App ID
         * @param {string} kbId Knowledgebase ID
         * @param {'i18n-nothing-found' | 'i18n-welcome' | 'i18n-pop-over' | 'i18n-thanks-for-feedback-up' | 'i18n-thanks-for-feedback-down' | 'i18n-disambiguation-post' | 'i18n-message-received' | 'i18n-stuck-in-a-loop' | 'i18n-exit-message' | 'i18n-internal-error' | 'i18n-intent-escalate' | 'i18n-intent-abusive' | 'i18n-guardrail-harmful' | 'i18n-guardrail-inappropriate' | 'i18n-guardrail-irrelevant' | 'i18n-survey' | 'i18n-route-update' | 'i18n-route-failure' | 'i18n-session-close-agent' | 'i18n-session-close-bot'} key Message key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDialogStaticMessage(authorization: string, appId: string, kbId: string, key: 'i18n-nothing-found' | 'i18n-welcome' | 'i18n-pop-over' | 'i18n-thanks-for-feedback-up' | 'i18n-thanks-for-feedback-down' | 'i18n-disambiguation-post' | 'i18n-message-received' | 'i18n-stuck-in-a-loop' | 'i18n-exit-message' | 'i18n-internal-error' | 'i18n-intent-escalate' | 'i18n-intent-abusive' | 'i18n-guardrail-harmful' | 'i18n-guardrail-inappropriate' | 'i18n-guardrail-irrelevant' | 'i18n-survey' | 'i18n-route-update' | 'i18n-route-failure' | 'i18n-session-close-agent' | 'i18n-session-close-bot', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDialogStaticMessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDialogStaticMessage(authorization, appId, kbId, key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all the dialog static messages for the given org with the given locale. Static messages can be overridden with a node in order to customise the conversation. 
         * @summary List dialog static messages
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId App ID
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDialogStaticMessages(authorization: string, appId: string, kbId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDialogStaticMessagesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDialogStaticMessages(authorization, appId, kbId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Configures the dialog engine to override the dialog static messages matching the given org, locale and key with a certain node. 
         * @summary Put dialog static message
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId App ID
         * @param {string} kbId Knowledgebase ID
         * @param {'i18n-nothing-found' | 'i18n-welcome' | 'i18n-pop-over' | 'i18n-thanks-for-feedback-up' | 'i18n-thanks-for-feedback-down' | 'i18n-disambiguation-post' | 'i18n-message-received' | 'i18n-stuck-in-a-loop' | 'i18n-exit-message' | 'i18n-internal-error' | 'i18n-intent-escalate' | 'i18n-intent-abusive' | 'i18n-guardrail-harmful' | 'i18n-guardrail-inappropriate' | 'i18n-guardrail-irrelevant' | 'i18n-survey' | 'i18n-route-update' | 'i18n-route-failure' | 'i18n-session-close-agent' | 'i18n-session-close-bot'} key Message key
         * @param {PutDialogStaticMessageRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putDialogStaticMessage(authorization: string, appId: string, kbId: string, key: 'i18n-nothing-found' | 'i18n-welcome' | 'i18n-pop-over' | 'i18n-thanks-for-feedback-up' | 'i18n-thanks-for-feedback-down' | 'i18n-disambiguation-post' | 'i18n-message-received' | 'i18n-stuck-in-a-loop' | 'i18n-exit-message' | 'i18n-internal-error' | 'i18n-intent-escalate' | 'i18n-intent-abusive' | 'i18n-guardrail-harmful' | 'i18n-guardrail-inappropriate' | 'i18n-guardrail-irrelevant' | 'i18n-survey' | 'i18n-route-update' | 'i18n-route-failure' | 'i18n-session-close-agent' | 'i18n-session-close-bot', body?: PutDialogStaticMessageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutDialogStaticMessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putDialogStaticMessage(authorization, appId, kbId, key, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AudienceApi - factory interface
 * @export
 */
export const AudienceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AudienceApiFp(configuration)
    return {
        /**
         * Resets the dialog engine to use the default dialog static messages matching the given org, locale and key. 
         * @summary Delete dialog static message
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId App ID
         * @param {string} kbId Knowledgebase ID
         * @param {'i18n-nothing-found' | 'i18n-welcome' | 'i18n-pop-over' | 'i18n-thanks-for-feedback-up' | 'i18n-thanks-for-feedback-down' | 'i18n-disambiguation-post' | 'i18n-message-received' | 'i18n-stuck-in-a-loop' | 'i18n-exit-message' | 'i18n-internal-error' | 'i18n-intent-escalate' | 'i18n-intent-abusive' | 'i18n-guardrail-harmful' | 'i18n-guardrail-inappropriate' | 'i18n-guardrail-irrelevant' | 'i18n-survey' | 'i18n-route-update' | 'i18n-route-failure' | 'i18n-session-close-agent' | 'i18n-session-close-bot'} key Message key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDialogStaticMessage(authorization: string, appId: string, kbId: string, key: 'i18n-nothing-found' | 'i18n-welcome' | 'i18n-pop-over' | 'i18n-thanks-for-feedback-up' | 'i18n-thanks-for-feedback-down' | 'i18n-disambiguation-post' | 'i18n-message-received' | 'i18n-stuck-in-a-loop' | 'i18n-exit-message' | 'i18n-internal-error' | 'i18n-intent-escalate' | 'i18n-intent-abusive' | 'i18n-guardrail-harmful' | 'i18n-guardrail-inappropriate' | 'i18n-guardrail-irrelevant' | 'i18n-survey' | 'i18n-route-update' | 'i18n-route-failure' | 'i18n-session-close-agent' | 'i18n-session-close-bot', options?: any): AxiosPromise<void> {
            return localVarFp.deleteDialogStaticMessage(authorization, appId, kbId, key, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the dialog static messages matching the given key for the given org with the given locale. 
         * @summary Get dialog static message
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId App ID
         * @param {string} kbId Knowledgebase ID
         * @param {'i18n-nothing-found' | 'i18n-welcome' | 'i18n-pop-over' | 'i18n-thanks-for-feedback-up' | 'i18n-thanks-for-feedback-down' | 'i18n-disambiguation-post' | 'i18n-message-received' | 'i18n-stuck-in-a-loop' | 'i18n-exit-message' | 'i18n-internal-error' | 'i18n-intent-escalate' | 'i18n-intent-abusive' | 'i18n-guardrail-harmful' | 'i18n-guardrail-inappropriate' | 'i18n-guardrail-irrelevant' | 'i18n-survey' | 'i18n-route-update' | 'i18n-route-failure' | 'i18n-session-close-agent' | 'i18n-session-close-bot'} key Message key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDialogStaticMessage(authorization: string, appId: string, kbId: string, key: 'i18n-nothing-found' | 'i18n-welcome' | 'i18n-pop-over' | 'i18n-thanks-for-feedback-up' | 'i18n-thanks-for-feedback-down' | 'i18n-disambiguation-post' | 'i18n-message-received' | 'i18n-stuck-in-a-loop' | 'i18n-exit-message' | 'i18n-internal-error' | 'i18n-intent-escalate' | 'i18n-intent-abusive' | 'i18n-guardrail-harmful' | 'i18n-guardrail-inappropriate' | 'i18n-guardrail-irrelevant' | 'i18n-survey' | 'i18n-route-update' | 'i18n-route-failure' | 'i18n-session-close-agent' | 'i18n-session-close-bot', options?: any): AxiosPromise<GetDialogStaticMessageResponse> {
            return localVarFp.getDialogStaticMessage(authorization, appId, kbId, key, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all the dialog static messages for the given org with the given locale. Static messages can be overridden with a node in order to customise the conversation. 
         * @summary List dialog static messages
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId App ID
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDialogStaticMessages(authorization: string, appId: string, kbId: string, options?: any): AxiosPromise<ListDialogStaticMessagesResponse> {
            return localVarFp.listDialogStaticMessages(authorization, appId, kbId, options).then((request) => request(axios, basePath));
        },
        /**
         * Configures the dialog engine to override the dialog static messages matching the given org, locale and key with a certain node. 
         * @summary Put dialog static message
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId App ID
         * @param {string} kbId Knowledgebase ID
         * @param {'i18n-nothing-found' | 'i18n-welcome' | 'i18n-pop-over' | 'i18n-thanks-for-feedback-up' | 'i18n-thanks-for-feedback-down' | 'i18n-disambiguation-post' | 'i18n-message-received' | 'i18n-stuck-in-a-loop' | 'i18n-exit-message' | 'i18n-internal-error' | 'i18n-intent-escalate' | 'i18n-intent-abusive' | 'i18n-guardrail-harmful' | 'i18n-guardrail-inappropriate' | 'i18n-guardrail-irrelevant' | 'i18n-survey' | 'i18n-route-update' | 'i18n-route-failure' | 'i18n-session-close-agent' | 'i18n-session-close-bot'} key Message key
         * @param {PutDialogStaticMessageRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDialogStaticMessage(authorization: string, appId: string, kbId: string, key: 'i18n-nothing-found' | 'i18n-welcome' | 'i18n-pop-over' | 'i18n-thanks-for-feedback-up' | 'i18n-thanks-for-feedback-down' | 'i18n-disambiguation-post' | 'i18n-message-received' | 'i18n-stuck-in-a-loop' | 'i18n-exit-message' | 'i18n-internal-error' | 'i18n-intent-escalate' | 'i18n-intent-abusive' | 'i18n-guardrail-harmful' | 'i18n-guardrail-inappropriate' | 'i18n-guardrail-irrelevant' | 'i18n-survey' | 'i18n-route-update' | 'i18n-route-failure' | 'i18n-session-close-agent' | 'i18n-session-close-bot', body?: PutDialogStaticMessageRequest, options?: any): AxiosPromise<PutDialogStaticMessageResponse> {
            return localVarFp.putDialogStaticMessage(authorization, appId, kbId, key, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AudienceApi - object-oriented interface
 * @export
 * @class AudienceApi
 * @extends {BaseAPI}
 */
export class AudienceApi extends BaseAPI {
    /**
     * Resets the dialog engine to use the default dialog static messages matching the given org, locale and key. 
     * @summary Delete dialog static message
     * @param {string} authorization Authorization Bearer header
     * @param {string} appId App ID
     * @param {string} kbId Knowledgebase ID
     * @param {'i18n-nothing-found' | 'i18n-welcome' | 'i18n-pop-over' | 'i18n-thanks-for-feedback-up' | 'i18n-thanks-for-feedback-down' | 'i18n-disambiguation-post' | 'i18n-message-received' | 'i18n-stuck-in-a-loop' | 'i18n-exit-message' | 'i18n-internal-error' | 'i18n-intent-escalate' | 'i18n-intent-abusive' | 'i18n-guardrail-harmful' | 'i18n-guardrail-inappropriate' | 'i18n-guardrail-irrelevant' | 'i18n-survey' | 'i18n-route-update' | 'i18n-route-failure' | 'i18n-session-close-agent' | 'i18n-session-close-bot'} key Message key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudienceApi
     */
    public deleteDialogStaticMessage(authorization: string, appId: string, kbId: string, key: 'i18n-nothing-found' | 'i18n-welcome' | 'i18n-pop-over' | 'i18n-thanks-for-feedback-up' | 'i18n-thanks-for-feedback-down' | 'i18n-disambiguation-post' | 'i18n-message-received' | 'i18n-stuck-in-a-loop' | 'i18n-exit-message' | 'i18n-internal-error' | 'i18n-intent-escalate' | 'i18n-intent-abusive' | 'i18n-guardrail-harmful' | 'i18n-guardrail-inappropriate' | 'i18n-guardrail-irrelevant' | 'i18n-survey' | 'i18n-route-update' | 'i18n-route-failure' | 'i18n-session-close-agent' | 'i18n-session-close-bot', options?: AxiosRequestConfig) {
        return AudienceApiFp(this.configuration).deleteDialogStaticMessage(authorization, appId, kbId, key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the dialog static messages matching the given key for the given org with the given locale. 
     * @summary Get dialog static message
     * @param {string} authorization Authorization Bearer header
     * @param {string} appId App ID
     * @param {string} kbId Knowledgebase ID
     * @param {'i18n-nothing-found' | 'i18n-welcome' | 'i18n-pop-over' | 'i18n-thanks-for-feedback-up' | 'i18n-thanks-for-feedback-down' | 'i18n-disambiguation-post' | 'i18n-message-received' | 'i18n-stuck-in-a-loop' | 'i18n-exit-message' | 'i18n-internal-error' | 'i18n-intent-escalate' | 'i18n-intent-abusive' | 'i18n-guardrail-harmful' | 'i18n-guardrail-inappropriate' | 'i18n-guardrail-irrelevant' | 'i18n-survey' | 'i18n-route-update' | 'i18n-route-failure' | 'i18n-session-close-agent' | 'i18n-session-close-bot'} key Message key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudienceApi
     */
    public getDialogStaticMessage(authorization: string, appId: string, kbId: string, key: 'i18n-nothing-found' | 'i18n-welcome' | 'i18n-pop-over' | 'i18n-thanks-for-feedback-up' | 'i18n-thanks-for-feedback-down' | 'i18n-disambiguation-post' | 'i18n-message-received' | 'i18n-stuck-in-a-loop' | 'i18n-exit-message' | 'i18n-internal-error' | 'i18n-intent-escalate' | 'i18n-intent-abusive' | 'i18n-guardrail-harmful' | 'i18n-guardrail-inappropriate' | 'i18n-guardrail-irrelevant' | 'i18n-survey' | 'i18n-route-update' | 'i18n-route-failure' | 'i18n-session-close-agent' | 'i18n-session-close-bot', options?: AxiosRequestConfig) {
        return AudienceApiFp(this.configuration).getDialogStaticMessage(authorization, appId, kbId, key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all the dialog static messages for the given org with the given locale. Static messages can be overridden with a node in order to customise the conversation. 
     * @summary List dialog static messages
     * @param {string} authorization Authorization Bearer header
     * @param {string} appId App ID
     * @param {string} kbId Knowledgebase ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudienceApi
     */
    public listDialogStaticMessages(authorization: string, appId: string, kbId: string, options?: AxiosRequestConfig) {
        return AudienceApiFp(this.configuration).listDialogStaticMessages(authorization, appId, kbId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Configures the dialog engine to override the dialog static messages matching the given org, locale and key with a certain node. 
     * @summary Put dialog static message
     * @param {string} authorization Authorization Bearer header
     * @param {string} appId App ID
     * @param {string} kbId Knowledgebase ID
     * @param {'i18n-nothing-found' | 'i18n-welcome' | 'i18n-pop-over' | 'i18n-thanks-for-feedback-up' | 'i18n-thanks-for-feedback-down' | 'i18n-disambiguation-post' | 'i18n-message-received' | 'i18n-stuck-in-a-loop' | 'i18n-exit-message' | 'i18n-internal-error' | 'i18n-intent-escalate' | 'i18n-intent-abusive' | 'i18n-guardrail-harmful' | 'i18n-guardrail-inappropriate' | 'i18n-guardrail-irrelevant' | 'i18n-survey' | 'i18n-route-update' | 'i18n-route-failure' | 'i18n-session-close-agent' | 'i18n-session-close-bot'} key Message key
     * @param {PutDialogStaticMessageRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudienceApi
     */
    public putDialogStaticMessage(authorization: string, appId: string, kbId: string, key: 'i18n-nothing-found' | 'i18n-welcome' | 'i18n-pop-over' | 'i18n-thanks-for-feedback-up' | 'i18n-thanks-for-feedback-down' | 'i18n-disambiguation-post' | 'i18n-message-received' | 'i18n-stuck-in-a-loop' | 'i18n-exit-message' | 'i18n-internal-error' | 'i18n-intent-escalate' | 'i18n-intent-abusive' | 'i18n-guardrail-harmful' | 'i18n-guardrail-inappropriate' | 'i18n-guardrail-irrelevant' | 'i18n-survey' | 'i18n-route-update' | 'i18n-route-failure' | 'i18n-session-close-agent' | 'i18n-session-close-bot', body?: PutDialogStaticMessageRequest, options?: AxiosRequestConfig) {
        return AudienceApiFp(this.configuration).putDialogStaticMessage(authorization, appId, kbId, key, body, options).then((request) => request(this.axios, this.basePath));
    }
}
