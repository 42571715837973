<template>
    <div class="d-pt12 d-d-flex d-fd-column d-ai-center d-ta-center">
        <spot-blank-space class="d-mb16 d-w164" />
        <slot
            name="default"
            :has-filters="hasFilters"
            :has-search-filter-only="hasSearchFilterOnly"
            :item-type="itemType"
        >
            <!-- --------------------------------------------------------------- -->
            <!--                         WITHOUT FILTERS                         -->
            <!-- --------------------------------------------------------------- -->
            <div v-if="!(hasFilters || hasSearchFilterOnly)">
                <div class="d-mb4 d-headline-large">
                    <slot v-if="$slots.title" name="title" />
                    <span v-else>{{ $t(`No ${itemType}`) }}</span>
                </div>

                <slot v-if="$slots.subtitle" name="subtitle" />
            </div>
            <!-- --------------------------------------------------------------- -->
            <!--                         WITH FILTERS                            -->
            <!-- --------------------------------------------------------------- -->
            <div class="d-ta-center" v-else>
                <span class="d-mb4 d-headline-large">
                    {{ $t('No results found') }}
                </span>
                <p v-if="hasSearchFilterOnly">
                    {{ $t('Try rephrasing your search') }}
                </p>
                <p v-else>
                    {{ $t('Try a different search or change the filters') }}
                </p>
            </div>
        </slot>
    </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';
// @ts-ignore
import SpotBlankSpace from '@dialpad/dialtone/css/vue/spot/SpotBlankSpace.vue';
import { DtButton } from '@dialpad/dialtone/vue3';

export default defineComponent({
    props: {
        itemType: {
            type: String as PropType<string>
        },
        hasSearchFilterOnly: {
            type: Boolean as PropType<boolean>
        },
        hasFilters: {
            type: Boolean as PropType<boolean>
        }
    },
    components: {
        DtButton,
        SpotBlankSpace
    }
});
</script>
