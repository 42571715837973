import type { Module } from 'vuex';
import { DIALPAD_LOCALES, ZONES_ABBR } from '@/utils/Constants';
import type { StoreState } from '@/store/index';
import type {
    DigitalExperienceProvider,
    Knowledgebase,
    LicenseType,
    Organisation,
    Widget
} from '@/open-api';
import type { ApiService } from '@/services/Api.service';
import type { INotification } from '@/utils/types/Notification';
import type { DXConsoleConfiguration } from '../../sdk/sdk';
import { convertToBoolean } from '@/utils/Common';
import { DrawerService } from '@/services/Drawer.service';
import type { ResponseBlockEditor } from '@/components/node-editor/responseBlockEditor';
import { i18n } from '@/main';
import ChatterModule from '@/store/ChatterModule';
import ImportStatusModule from '@/store/ImportStatusModule';
import ResponsesModule from '@/store/ResponsesModule';
import DocumentsModule from '@/store/DocumentsModule';
import WidgetsModule from '@/store/WidgetsModule';
import { Topic } from '../../backend/pubsub';

// @ts-ignore
import { setTheme } from '@dialpad/dialtone/themes/config';
// @ts-ignore
import DpLight from '@dialpad/dialtone/themes/dp-light';
// @ts-ignore
import DpDark from '@dialpad/dialtone/themes/dp-dark';

interface FirstTimeNotices {
    chatbotNotice: boolean;
}

export interface Integrations {
    zendesk: {
        clientId: string;
        scope: string;
        redirectOrigin: string;
        redirectPath: string;
        completePath: string;
        authorizeUrl: string;
        responseType: string;
    };
    gdrive: {
        redirectOrigin: string;
        redirectPath: string;
        completePath: string;
    };
}

export interface ModuleDefaultState {
    authToken?: string;
    refreshToken?: string;
    locale: DIALPAD_LOCALES;
    orgName?: string;
    notifications: INotification[];
    zone?: ZONES_ABBR;
    host: string;
    apiService?: ApiService;
    drawerService?: DrawerService;
    bodyRef?: HTMLElement;
    firstTimeNotices: FirstTimeNotices;
    currentKnowledgeBase?: Knowledgebase;
    currentChatbot?: Widget;
    currentOrg?: Organisation;
    showSidebarMenu?: boolean;
    limited?: boolean;
    darkMode?: boolean;
    maxContentWidth: string;
    maxContentHeight: number;
    drawerWidth: string;
    responseBlockEditor?: ResponseBlockEditor;
    integrations: Integrations;
    idleDetectorFns: { [topic: string]: () => void };
    newWebchatClient: boolean;
    officeName?: string;
    timezone?: string;
    markdownCharCount?: number;
}

export function moduleDefaultState(
    config: DXConsoleConfiguration
): ModuleDefaultState {
    return {
        apiService: config.apiService,
        orgName: config.orgName,
        authToken: config.authToken,
        refreshToken: config.refreshToken,
        limited: convertToBoolean(config.limited),
        zone: config.zone,
        host: `https://console.${config.zone}.karehq.com`,
        locale: config.locale,
        notifications: [],
        firstTimeNotices: {
            chatbotNotice: true
        },
        darkMode: convertToBoolean(config.darkMode),
        drawerWidth: 'auto',
        maxContentWidth: 'auto',
        maxContentHeight: 0,
        integrations: {
            zendesk: {
                clientId: 'zdg-kare-mind',
                scope: 'hc:read',
                redirectOrigin: `https://console.${config.zone}.karehq.com`,
                redirectPath: '/mind/return/zendesk',
                completePath: '#/mind/complete/zendesk',
                authorizeUrl: '/oauth/authorizations/new',
                responseType: 'code'
            },
            gdrive: {
                redirectOrigin: `https://console.${config.zone}.karehq.com`,
                redirectPath: '/ingestion/gdrive/return',
                completePath: '#/ingestion/gdrive/complete'
            }
        },
        showSidebarMenu:
            window.location.pathname.includes('/mind/return/zendesk') ||
            window.location.pathname.includes('/ingestion/gdrive/return')
                ? false
                : convertToBoolean(config.showSidebarMenu),
        idleDetectorFns: {},
        newWebchatClient: convertToBoolean(config.newWebchatClient),
        officeName: config.officeName,
        timezone:
            config.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
        markdownCharCount: 0
    };
}

export default (initialConfig: DXConsoleConfiguration) =>
    ({
        namespaced: true,
        state: moduleDefaultState(initialConfig),
        modules: {
            chatter: ChatterModule(initialConfig.organisationDetails!),
            documents: DocumentsModule(initialConfig.organisationDetails!),
            importStatus: ImportStatusModule(
                initialConfig.organisationDetails!
            ),
            responses: ResponsesModule(initialConfig.organisationDetails!),
            widgets: WidgetsModule(initialConfig.organisationDetails!)
        },
        actions: {
            initialize(context, authToken: string) {
                i18n.global.locale.value = context.state.locale as any;
                if (authToken) context.commit('setAuthToken', authToken);
                context.commit('setApiService', initialConfig.apiService);
                const drawerService = new DrawerService();
                context.commit('setDrawerService', drawerService);
                context.commit(
                    'setOrgDetails',
                    initialConfig.organisationDetails
                );
            },
            setBodyStyle(context: any, style: string) {
                context.getters.bodyRef.style = style;
            }
        },
        mutations: {
            setCurrentKnowledgeBase: (state, knowledgeBase: Knowledgebase) => {
                state.currentKnowledgeBase = knowledgeBase;
            },
            setCurrentChatbot: (state, chatbot: Widget) => {
                state.currentChatbot = chatbot;
            },
            setOrgDetails: (state, orgDetails: Organisation) => {
                state.currentOrg = orgDetails;
            },
            setFirstTimeNotices: (
                state,
                firstTimeNotices: FirstTimeNotices
            ) => {
                state.firstTimeNotices = firstTimeNotices;
            },
            setBodyRef: (state, body: HTMLElement) => {
                state.bodyRef = body;
            },
            setApiService: (state, apiService: ApiService) => {
                state.apiService = apiService;
            },
            setNewWebchatClient: (state, newWebchatClient: boolean) => {
                state.newWebchatClient = newWebchatClient;
            },
            setDrawerService: (state, drawerService: DrawerService) => {
                state.drawerService = drawerService;
            },
            setAuthToken: (state, authToken: string) => {
                if (authToken.toLowerCase().startsWith('bearer')) {
                    state.authToken = authToken;
                } else if (authToken.length) {
                    state.authToken = `Bearer ${authToken}`;
                } else {
                    state.authToken = authToken;
                }
            },
            setRefreshToken: (state, refreshToken: string) => {
                state.refreshToken = refreshToken;
            },
            setDrawerWidth: (state, drawerWidth: string) => {
                state.drawerWidth = drawerWidth;
            },
            setLocale: (state, locale: keyof typeof DIALPAD_LOCALES) => {
                const language: any = DIALPAD_LOCALES[locale] || locale;
                state.locale = language;
                i18n.global.locale.value = language as any;
            },
            setShowSidebarMenu: (state, show: boolean) => {
                state.showSidebarMenu = show;
            },
            setZone: (state, zone: ZONES_ABBR | undefined) => {
                state.zone = zone;
            },
            setOfficeName: (state, officeName: string) => {
                state.officeName = officeName;
            },
            setTimezone: (state, timezone: string) => {
                state.timezone = timezone;
            },
            setMaxContentWidth: (state, contentWidth: string) => {
                state.maxContentWidth = contentWidth;
            },
            setMaxContentHeight: (state, contentHeight: number) => {
                state.maxContentHeight = contentHeight;
            },
            setResponseBlockEditor: (
                state,
                responseBlockEditor: ResponseBlockEditor
            ) => {
                state.responseBlockEditor = responseBlockEditor;
            },
            setDarkMode: (state, darkMode?: boolean) => {
                const systemDarkMode =
                    window.matchMedia &&
                    window.matchMedia('(prefers-color-scheme: dark)').matches;
                state.darkMode =
                    darkMode === undefined
                        ? systemDarkMode
                        : convertToBoolean(darkMode);

                if (state.darkMode) {
                    setTheme(DpDark);
                } else {
                    setTheme(DpLight);
                }
            },
            addIdleDetectorFn: (
                state,
                {
                    idleDetectorFn,
                    topic
                }: { topic: Topic; idleDetectorFn: () => void }
            ) => {
                state.idleDetectorFns[topic] = idleDetectorFn;
            },
            removeIdleDetectorFn: (state, topic: Topic) => {
                delete state.idleDetectorFns[topic];
            },
            addNotification: (state, notification: INotification) => {
                state.notifications.push(notification);
            },
            CLEAN_STATE: (state: ModuleDefaultState, objectToPersist) => {
                Object.assign(state || {}, {
                    ...moduleDefaultState(initialConfig),
                    ...objectToPersist
                });
            },
            setMarkdownCharCount: (state, charCount: string) => {
                state.markdownCharCount = charCount;
            }
        },
        getters: {
            authToken(state): string | undefined {
                return state.authToken;
            },
            refreshToken(state): string | undefined {
                return state.refreshToken;
            },
            host(state): string {
                return state.host;
            },
            locale(state): string | undefined {
                return state.locale;
            },
            orgName(state): string | undefined {
                return state.currentOrg?.subdomain;
            },
            orgLicenses(state): LicenseType[] {
                return state.currentOrg?.account_licenses || [];
            },
            limited(state): boolean | undefined {
                return state.limited;
            },
            newWebchatClient(state): boolean {
                return state.newWebchatClient;
            },
            drawerWidth(state): string {
                return state.drawerWidth;
            },
            maxContentWidth(state): string {
                return state.maxContentWidth;
            },
            maxContentHeight(state): number {
                return state.maxContentHeight;
            },
            notifications(state): INotification[] {
                return state.notifications;
            },
            zone(state): ZONES_ABBR | undefined {
                return state.zone;
            },
            officeName(state): string | undefined {
                return state.officeName;
            },
            timezone(state): string | undefined {
                return state.timezone;
            },
            darkMode(state) {
                return state.darkMode;
            },
            apiService(state) {
                return state.apiService;
            },
            drawerService(state) {
                return state.drawerService;
            },
            bodyRef(state) {
                return state.bodyRef;
            },
            firstTimeNotices(state) {
                return state.firstTimeNotices;
            },
            currentKnowledgebase(state) {
                return state.currentKnowledgeBase;
            },
            showSidebarMenu(state) {
                return state.showSidebarMenu;
            },
            currentChatbot(state) {
                return state.currentChatbot;
            },
            currentOrg(state) {
                return state.currentOrg;
            },
            koopid(state): DigitalExperienceProvider {
                return state.currentOrg?.digital_experience_provider;
            },
            companyId(state) {
                return state.currentOrg?.dialpad_company?.id;
            },
            baaEnabled(state) {
                return state.currentOrg?.baa_enabled;
            },
            document() {
                return document.getElementsByTagName('dx-console')[0]
                    .shadowRoot;
            },
            responseBlockEditor(state) {
                return state.responseBlockEditor;
            },
            integrations(state) {
                return state.integrations;
            },
            idleDetectorFns(state) {
                return state.idleDetectorFns;
            },
            markdownCharCount(state): number {
                return state.markdownCharCount;
            }
        }
    }) as Module<ModuleDefaultState, StoreState>;
