<template>
    <div class="d-d-flex d-h100p d-w100p d-fd-column d-fw-wrap">
        <div
            class="d-d-flex d-fl-grow1 d-of-hidden d-wmx100p"
            ref="mainWrapper"
            v-on-resize="() => debounce(calculateContentSize, 100)"
        >
            <div
                class="d-bgc-secondary d-h100p d-br d-bc-subtle"
                :style="{ flex: `0 0 ${sidebarWidth}` }"
                ref="leftSidebar"
                v-if="showSidebarMenu"
            >
                <sidebar-menu />
            </div>
            <div class="d-d-flex d-fd-column d-fl-grow1">
                <div class="d-d-flex" v-if="knowledgeTopBar">
                    <knowledge-banner />
                </div>
                <slot name="notifications"></slot>
                <div
                    class="d-d-flex d-fl-grow1"
                    :style="{
                        maxWidth: `${maxContainerWidth}`
                    }"
                >
                    <div
                        class="d-fl-grow1 d-of-y-auto"
                        :style="{
                            maxHeight: `${maxContentHeight}px`
                        }"
                        @scroll="handleOnScrollY"
                    >
                        <router-view :key="filteredCurrentPath" />
                    </div>
                    <div
                        :style="{ flex: `0 1 ${drawerWidth}` }"
                        ref="rightSidebar"
                    >
                        <Transition
                            name="slide"
                            @before-enter="handleBeforeEnter"
                            @after-enter="handleAfterEnter"
                            @before-leave="handleBeforeLeave"
                            @after-leave="handleAfterLeave"
                        >
                            <div class="d-h100p" v-if="drawer?.open">
                                <side-bar />
                            </div>
                        </Transition>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, inject, type PropType, provide } from 'vue';
import KnowledgeBanner from '@/components/knowledge-banner/KnowledgeBanner.vue';
import KnowledgeMenuTabs from '@/components/knowledge-menu/KnowledgeMenuTabs.vue';
import SideBar from '@/components/sidebar-drawer/SideBar.vue';
import SidebarMenu from '@/components/sidebar-menu/SidebarMenu.vue';
import type { Drawer, DrawerService } from '@/services/Drawer.service';
import { KNOWLEDGE_BANNER_HEIGHT } from '@/utils/Constants';
import { Subject } from 'rxjs';
import { createDebounce } from '@/utils/Common';

export default defineComponent({
    components: {
        KnowledgeBanner,
        KnowledgeMenuTabs,
        SideBar,
        SidebarMenu
    },
    props: {
        sideBarMenu: {
            type: Boolean as PropType<boolean>
        },
        knowledgeTopBar: {
            type: Boolean as PropType<boolean>
        },
        knowledgeMenu: {
            type: Boolean as PropType<boolean>
        }
    },
    watch: {
        $route(to, from) {
            if (from.name !== to.name) {
                this.calculateContentSize();
            }
        },
        drawerOpen() {
            this.calculateContentSize();
        }
    },
    setup() {
        const orgId: string = inject('orgId')!;
        const onRootScrollY$ = new Subject<Event>();
        provide('onRootScrollY$', onRootScrollY$);

        return {
            orgId,
            onRootScrollY$,
            debounce: createDebounce()
        };
    },
    mounted() {
        this.calculateContentSize();
    },
    computed: {
        /* v8 ignore next 92 */
        maxContentWidth: {
            get(): string {
                return this.$store.getters[`${this.orgId}/maxContentWidth`];
            },
            set(contentWidth: string) {
                this.$store.commit(
                    `${this.orgId}/setMaxContentWidth`,
                    contentWidth
                );
            }
        },
        maxContentHeight: {
            get(): number {
                return this.$store.getters[`${this.orgId}/maxContentHeight`];
            },
            set(contentHeight: number) {
                this.$store.commit(
                    `${this.orgId}/setMaxContentHeight`,
                    contentHeight
                );
            }
        },
        drawerWidth(): string {
            return !this.drawerInTransition &&
                this.drawer?.open &&
                this.drawer?.width
                ? this.drawer?.width
                : '0px';
        },
        showSidebarMenu(): boolean {
            return this.$store.getters[`${this.orgId}/showSidebarMenu`];
        },
        sidebarWidth(): string {
            return this.showSidebarMenu ? '216px' : '0px';
        },
        drawerService(): DrawerService | undefined {
            return this.$store.getters[`${this.orgId}/drawerService`];
        },
        drawer(): Drawer | undefined {
            return this.drawerService?.getOpenDrawer();
        },
        drawerInTransition(): boolean {
            return !!this.drawerService?.getInTransition();
        },
        drawerOpen(): boolean {
            return !!this.drawer?.open;
        },
        currentPath(): string {
            return this.$route.path;
        },
        filteredCurrentPath(): string {
            // Don't re-render when history param changes
            return this.currentPath.split('/history')[0] + '/history';
        }
    },
    methods: {
        calculateContentSize() {
            if (this.$refs.mainWrapper) {
                const mainWrapper: HTMLDivElement = this.$refs
                    .mainWrapper as HTMLDivElement;
                this.maxContainerWidth = `calc(${mainWrapper.clientWidth}px - ${this.sidebarWidth} - 1px)`;
                this.maxContentWidth = `calc(${mainWrapper.clientWidth}px - ${this.sidebarWidth} - ${this.drawerWidth} - 2px)`;
                this.maxContentHeight =
                    mainWrapper.clientHeight -
                    (this.knowledgeTopBar ? KNOWLEDGE_BANNER_HEIGHT : 0);
            }
        },
        handleAfterEnter() {
            this.drawerService?.setOffTransition();
        },
        handleAfterLeave() {
            this.drawerService?.setOffTransition();
        },
        handleBeforeEnter() {
            this.customWidth = this.drawer?.width?.includes('px')
                ? this.drawer.width
                : (
                      (this.$refs.rootLayout as HTMLElement).clientWidth / 2
                  ).toString();
            this.$store.commit(
                `${this.orgId}/setDrawerWidth`,
                this.customWidth
            );
            this.drawerService?.setInTransition();
        },
        handleBeforeLeave() {
            this.drawerService?.setInTransition();
        },
        handleOnScrollY(e: Event) {
            this.onRootScrollY$.next(e);
        }
    },
    data() {
        return {
            customWidth: '' as string | undefined,
            maxContainerWidth: '' as string | undefined
        };
    }
});
</script>
<style lang="less">
.slide-enter-active {
    transition: all 0.15s ease-out;
    animation: slide 0.15s;
}

.slide-leave-active {
    transition: all 0.15s ease-out;
    animation: slide 0.15s reverse;
}

@keyframes slide {
    0% {
        width: 0;
        transform: translateX(100%);
    }
    100% {
        width: v-bind(customWidth);
        transform: translateX(0);
    }
}
</style>
