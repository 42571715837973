<template>
    <dt-modal
        :close-button-props="{
            ariaLabel: `${$t('Close confirmation modal')}`
        }"
        v-model:show="showModal"
        :title="
            title
                ? noTranslation
                    ? title
                    : $t(title)
                : `${$t('Disconnect')} ${type}`
        "
        :copy="
            noTranslation
                ? description
                : description
                  ? $t(description)
                  : `${$t('Your')} ${type} ${$t(
                        'account will be disconnected and all previously imported documents will be archived. Are you sure you want to proceed?'
                    )}`
        "
    >
        <template #footer>
            <dt-button @click="handleDelete" :kind="kind">
                {{
                    buttonTitle
                        ? noTranslation
                            ? buttonTitle
                            : $t(buttonTitle)
                        : `${$t('Yes, disconnect')} ${type}`
                }}
            </dt-button>
            <dt-button
                class="d-mr4"
                importance="clear"
                kind="muted"
                @click="handleHide"
            >
                {{ $t('No, cancel') }}
            </dt-button>
        </template>
    </dt-modal>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';

import { DtButton, DtModal } from '@dialpad/dialtone/vue3';

export default defineComponent({
    props: {
        showPrompt: {
            type: Boolean as PropType<boolean>
        },
        type: {
            type: String as PropType<string>
        },
        title: {
            type: String as PropType<string>
        },
        description: {
            type: String as PropType<string>
        },
        buttonTitle: {
            type: String as PropType<string>
        },
        kind: {
            type: String as PropType<string>,
            default: 'danger'
        },
        noTranslation: {
            type: Boolean as PropType<boolean>
        }
    },
    components: {
        DtButton,
        DtModal
    },
    watch: {
        showPrompt(newShow) {
            this.showModal = newShow;
        },
        showModal(newShow) {
            this.$emit('update:showPrompt', newShow);
        }
    },
    methods: {
        handleDelete() {
            this.$emit('confirm');
            this.handleHide();
        },
        handleHide() {
            this.$emit('update:showPrompt', false);
        }
    },
    data() {
        return {
            showModal: this.showPrompt
        };
    }
});
</script>
