<template>
    <div
        class="markdown-editor-footer d-bgc-primary d-h48 d-fl-grow1 d-bbr4 d-p8 d-d-flex d-ai-center d-jc-flex-end d-box-border d-gg8"
    >
        <div
            class="d-pl8 d-mr-auto d-label--sm d-d-flex d-ai-center d-fc-warning"
            :class="{
                'd-fc-critical': markdownCharCount === maxLength
            }"
            v-if="markdownCharCount >= maxLength - 100"
        >
            <dt-icon name="alert-triangle" size="100" class="d-mr4" />
            {{
                markdownCharCount === maxLength
                    ? $t('At the limit')
                    : $t('Near the limit')
            }}
        </div>
        <dt-button
            importance="clear"
            size="sm"
            :disabled="!isSavable"
            @click="handleCancel"
        >
            {{ $t('Cancel') }}
        </dt-button>
        <dt-button size="sm" :disabled="!isSavable" @click="handleSave">
            {{ $t('Save') }}
        </dt-button>
    </div>
</template>

<script lang="ts">
import { defineComponent, inject, type PropType } from 'vue';
import { DtIcon, DtButton } from '@dialpad/dialtone/vue3';
import { TEXT_BLOCK_LIMIT } from '@/utils/Constants';

export default defineComponent({
    components: {
        DtIcon,
        DtButton
    },
    setup() {
        const orgId: string = inject('orgId')!;
        return {
            orgId
        };
    },
    props: {
        isSavable: {
            type: Boolean as PropType<boolean>
        }
    },
    computed: {
        markdownCharCount(): number {
            return this.$store.getters[`${this.orgId}/markdownCharCount`];
        }
    },
    methods: {
        handleCancel(e: Event) {
            this.$emit('cancelEdit', e);
        },
        handleSave(e: Event) {
            this.$emit('save', e);
        }
    },
    data() {
        return {
            maxLength: TEXT_BLOCK_LIMIT
        };
    }
});
</script>
