import type { Module } from 'vuex';
import type {
    DigitalExperienceProvider,
    Organisation,
    Widget,
    WidgetStatus
} from '@/open-api';
import { LicenseType } from '@/open-api';
import type { ApiService } from '@/services/Api.service';
import {
    DEBOUNCE_INTERVAL_SECONDS,
    FORCE_FECTH_INTERVAL_SECONDS
} from '@/store';
import { getDSSInstances, type KoopidBot } from '@/utils/koopid';

export interface WidgetsModuleState {
    widgets: WidgetInstance[];
    fetching: boolean;
    error: boolean;
    totalHits: number;
    status?: WidgetStatus;
    search?: string;
    licenseType?: LicenseType;
    timer?: any;
    lastFetched?: number;
}

export interface WidgetInstance extends Widget {
    bots: {
        entrytags: KoopidBot[];
        providerId?: number | string;
    };
}

export interface fetchPayload {
    status: WidgetStatus;
    search?: string;
    licenseType: LicenseType;
    disableFetching?: boolean;
}

export default function (org: Organisation) {
    return {
        namespaced: true,
        state: {
            widgets: [],
            fetching: false,
            error: false,
            totalHits: 0
        },
        getters: {
            list(state): WidgetInstance[] {
                return state.widgets;
            },
            fetching(state): boolean {
                return state.fetching;
            },
            totalHits(state): number {
                return state.totalHits;
            },
            error(state): boolean {
                return state.error;
            },
            status(state) {
                return state.status;
            },
            search(state) {
                return state.search;
            },
            licenseType(state) {
                return state.licenseType;
            },
            timer(state) {
                return state.timer;
            },
            lastFetched(state) {
                return state.lastFetched;
            }
        },
        actions: {
            async fetch(
                { commit, dispatch, rootGetters },
                { status, search, licenseType, disableFetching }: fetchPayload
            ) {
                commit('setLastFetched', Date.now());
                const apiService: ApiService =
                    rootGetters[`${org.id}/apiService`];
                const authToken: string = rootGetters[`${org.id}/authToken`];

                if (!disableFetching) commit('setFetching', true);
                commit('setError', false);
                commit('setStatus', status);
                commit('setSearch', search);
                commit('setLicenseType', licenseType);

                try {
                    const res = await apiService.knowledge.listWidgets(
                        authToken,
                        licenseType,
                        status,
                        search,
                        'name',
                        'asc'
                    );
                    commit(
                        'setWidgets',
                        res.data?.widgets?.map((widget: Widget) => ({
                            ...widget,
                            bots: { entrytags: [] }
                        })) as WidgetInstance[]
                    );
                    commit('setTotalHits', res.data?.total_hits);
                    if (licenseType === LicenseType.Dss)
                        await dispatch('fetchAllKoopidInstances');
                } catch (e) {
                    commit('setError', true);
                }

                if (!disableFetching) commit('setFetching', false);
            },
            async fetchLast({ commit, dispatch, getters }) {
                if (getters.lastFetched) {
                    const lastFetchedInSeconds = getters.lastFetched / 1000;
                    const dateNowInSeconds = Date.now() / 1000;

                    // (jnoronha): If we have incoming events but none have been fetched in the 4 seconds, we force fetch
                    if (
                        dateNowInSeconds - lastFetchedInSeconds >=
                        FORCE_FECTH_INTERVAL_SECONDS
                    ) {
                        dispatch('fetch', {
                            status: getters.status,
                            search: getters.search,
                            licenseType: getters.licenseType,
                            disableFetching: true
                        });
                        return;
                    }
                }

                if (getters.timer) clearTimeout(getters.timer);
                commit(
                    'setTimer',
                    setTimeout(() => {
                        dispatch('fetch', {
                            status: getters.status,
                            search: getters.search,
                            licenseType: getters.licenseType,
                            disableFetching: true
                        });
                    }, DEBOUNCE_INTERVAL_SECONDS * 1000)
                );
            },
            async fetchAllKoopidInstances({ commit, dispatch, getters }) {
                if (!getters.list) return;
                commit('setFetching', true);
                await Promise.all(
                    getters.list.map((widget: WidgetInstance) =>
                        dispatch('fetchKoopidInstancesByWidget', widget)
                    )
                );
                commit('setFetching', false);
            },
            async fetchKoopidInstancesByWidget(
                { dispatch, rootGetters },
                widget
            ) {
                const companyId: string = rootGetters[`${org.id}/companyId`];
                const authToken: string = rootGetters[`${org.id}/authToken`];
                const limited: string = rootGetters[`${org.id}/limited`];
                const newWebchatClient: boolean =
                    rootGetters[`${org.id}/newWebchatClient`];
                const koopid: DigitalExperienceProvider =
                    rootGetters[`${org.id}/koopid`];

                const bots = await new Promise<any>((resolve) => {
                    if (!companyId || limited) {
                        resolve([]);
                    } else {
                        getDSSInstances(
                            companyId,
                            widget.id!,
                            authToken,
                            koopid,
                            newWebchatClient
                        ).then((res) => {
                            if (res.data)
                                resolve({
                                    entrytags: res.data.entity.entrytags,
                                    providerId: res.data.entity.providerid
                                });
                            else resolve([]);
                        });
                    }
                });
                dispatch('handleWidgetUpdate', { ...widget, bots });
            },
            async update(
                { commit, rootGetters },
                { appId, widget }: { appId: string; widget: WidgetInstance }
            ) {
                const apiService: ApiService =
                    rootGetters[`${org.id}/apiService`];
                const authToken: string = rootGetters[`${org.id}/authToken`];

                commit('setFetching', true);

                try {
                    await apiService.knowledge.patchWidget(
                        authToken,
                        appId,
                        widget
                    );
                } catch (e) {
                    // Handle update error
                }

                commit('setFetching', false);
            },
            handleWidgetCreate(
                { dispatch, commit, getters },
                widget: WidgetInstance
            ) {
                if (!getters.list || !getters.status) return;

                if (getters.search?.length) {
                    dispatch('fetchLast');
                } else {
                    commit('addWidget', widget);
                    dispatch('fetchKoopidInstancesByWidget', widget);
                }
            },
            handleWidgetUpdate(
                { dispatch, commit, getters },
                widget: WidgetInstance
            ) {
                if (!getters.list || !getters.status) return;

                if (getters.status === widget.status) {
                    const foundIndex = getters.list.findIndex(
                        (rsp: WidgetInstance) => rsp.id === widget.id
                    );
                    if (foundIndex > -1) {
                        commit('mutateWidget', widget);
                    } else {
                        if (getters.search?.length) {
                            dispatch('fetchLast');
                        } else {
                            commit('addWidget', widget);
                        }
                    }
                } else {
                    commit('removeWidget', widget);
                    dispatch('fetchLast');
                }
            },
            handleWidgetDelete(
                { dispatch, commit, getters },
                widget: WidgetInstance
            ) {
                if (!getters.list || !getters.status) return;
                commit('removeWidget', widget);
                dispatch('fetchLast');
            }
        },
        mutations: {
            setWidgets(state, widgets: WidgetInstance[]) {
                state.widgets = widgets;
            },
            setTotalHits(state, totalHits: number) {
                state.totalHits = totalHits;
            },
            setFetching(state, fetching: boolean) {
                state.fetching = fetching;
            },
            setError(state, error: boolean) {
                state.error = error;
            },
            setStatus(state, status: WidgetStatus) {
                state.status = status;
            },
            setSearch(state, search: string) {
                state.search = search;
            },
            setLicenseType(state, licenseType: LicenseType) {
                state.licenseType = licenseType;
            },
            setTimer(state, timer: any) {
                state.timer = timer;
            },
            setLastFetched(state, lastFetched: number) {
                state.lastFetched = lastFetched;
            },

            addWidget(state, widget: WidgetInstance) {
                if (
                    state.widgets &&
                    !state.widgets.includes(widget) &&
                    state.status === widget.status
                ) {
                    state.widgets.push(widget);
                    state.widgets.sort((a, b) =>
                        a.name!.localeCompare(b.name!)
                    );

                    if (!state.totalHits) state.totalHits = 1;
                    else state.totalHits += 1;
                }
            },
            mutateWidget(state, widget: WidgetInstance) {
                if (!state.widgets || !state.status) return;

                const foundIndex = state.widgets.findIndex(
                    (rsp) => rsp.id === widget.id
                );
                if (foundIndex > -1) {
                    Object.assign(state.widgets[foundIndex], widget);
                }
            },
            removeWidget(state, widget: WidgetInstance) {
                if (!state.widgets) return;
                const foundIndex = state.widgets.findIndex(
                    (rsp) => rsp.id === widget.id
                );
                if (foundIndex > -1) {
                    state.widgets.splice(foundIndex, 1);
                    if (!state.totalHits) state.totalHits = 0;
                    else state.totalHits -= 1;
                }
            }
        }
    } as Module<WidgetsModuleState, any>;
}
