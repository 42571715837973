<template>
    <dt-button
        :link="isLink"
        class="d-truncate"
        :class="{ 'd-td-none d-td-underline': isLink }"
        :importance="importance"
        size="xs"
        @click="handleClick"
        :disabled="disableOnTransition && drawerInTransition"
    >
        {{ $t(anchorTitle) }}
    </dt-button>
</template>

<script lang="ts">
import { defineComponent, inject, type PropType } from 'vue';
import { DtButton } from '@dialpad/dialtone/vue3';
import type { Drawer, DrawerService } from '@/services/Drawer.service';
import { uuidv4 } from '@/utils/Common';

export default defineComponent({
    props: {
        sidebarComponent: {
            type: Object as PropType<any>
        },
        anchorTitle: {
            type: String as PropType<string>,
            default: 'Title'
        },
        drawerName: {
            type: String as PropType<string>,
            required: true
        },
        importance: {
            type: String as PropType<string>,
            default: 'primary'
        },
        width: {
            type: String as PropType<string>
        },
        drawerActive: {
            type: String as PropType<
                | 'edit'
                | 'create'
                | 'dss'
                | 'aiAssist'
                | 'salesAssist'
                | undefined
            >
        },
        isLink: {
            type: Boolean as PropType<boolean>
        },
        disableOnTransition: {
            type: Boolean as PropType<boolean>,
            default: true
        }
    },
    components: { DtButton },
    setup() {
        const orgId: string = inject('orgId')!;
        return {
            orgId
        };
    },
    computed: {
        drawerService(): DrawerService | undefined {
            return this.$store.getters[`${this.orgId}/drawerService`];
        },
        /* v8 ignore next 3 */
        drawerInTransition(): boolean {
            return !!this.drawerService?.getInTransition();
        },
        active: {
            get(): 'edit' | 'create' | 'dss' | 'aiAssist' | undefined {
                return this.drawerActive;
            },
            set(active: 'edit' | 'create' | 'dss' | 'aiAssist' | undefined) {
                this.$emit('updated:drawerActive', active);
            }
        }
    },
    methods: {
        handleClick() {
            this.active = 'create';
            const drawer: Drawer = {
                id: uuidv4(),
                name: this.drawerName,
                componentInstance: this.sidebarComponent,
                width: this.width
            };
            this.drawerService?.toggleDrawer(drawer);
        }
    }
});
</script>
