<template>
    <Transition name="slide" v-bind="$attrs">
        <drawer-template
            v-if="showWebcrawler"
            @drawer-template-close="handleClose"
            @back="handleBackClick"
            is-secondary-page
        >
            <template #header>
                <h3 class="d-d-flex d-jc-flex-start d-py8 d-ml32 d-pl8">
                    {{
                        `${
                            webcrawlerHost ? $t('Configure') : $t('Set up a')
                        } ${$t('webcrawler')}`
                    }}
                </h3>
            </template>
            <template #default>
                <dt-input
                    v-model="baseUrl"
                    aria-required="true"
                    :label="`${$t('Base URL')} (${$t('Required')})`"
                    :description="$t('The host name must not contain a path')"
                    :placeholder="`${$t('i.e.')} https://dialpad.com`"
                    :disabled="!!webcrawlerHost"
                    @input="validateURL"
                    :messages="baseUrlError"
                />
                <div class="d-mt16">
                    <h4 class="d-fc-black-700 d-fw-semibold">
                        {{ `${$t('Starting pages')} (${$t('Required')})` }}
                    </h4>
                    <p class="d-fs-100 d-fc-black-600 d-mb4">
                        {{
                            $t('The starting page URL must not contain a path')
                        }}
                    </p>
                    <dt-input
                        v-for="(startingPage, i) in startingPages"
                        :key="`another-page-input-${i}`"
                        v-model="startingPage.value"
                        :input-wrapper-class="{ 'd-mt16': !isFirstElement(i) }"
                        icon-size="md"
                        :name="`another-page-input-${i}`"
                        input-class="d-pl0"
                        :messages="startingPageError"
                        :disabled="!!baseUrlError.length || !baseUrl"
                        @input="(e: Event) => validateStartingPage(e)"
                    >
                        <template #leftIcon>
                            <span class="d-fc-black-500" v-if="baseUrl">
                                {{ baseUrl }}
                            </span>
                        </template>
                        <template #rightIcon>
                            <dt-icon
                                v-if="!isFirstElement(i)"
                                name="close"
                                size="300"
                                class="d-fc-black-900 d-c-pointer"
                                @click="handleRemoveInput(i)"
                            />
                        </template>
                    </dt-input>
                </div>
                <dt-button
                    class="d-mt16"
                    importance="outlined"
                    size="sm"
                    @click="handleAddAnotherPage"
                    :disabled="
                        lastStartingPageIsEmpty || !!startingPageError.length
                    "
                >
                    {{ $t('Add another starting page') }}</dt-button
                >
                <div class="d-mt16">
                    <h4 class="d-fc-black-700 d-fw-semibold">
                        {{ $t('Labels') }}
                    </h4>
                    <p class="d-fs-100 d-fc-black-600 d-mb4">
                        {{ $t('You can select multiple labels') }}
                    </p>
                    <base-combobox
                        data-qa="webcrawler-labels-input"
                        :item-list="tags"
                        v-model:selected-items="selectedLabels"
                    />
                </div>
                <div class="d-mt16">
                    <dt-radio-group
                        name="import-radio-group"
                        :legend="$t('Import')"
                        :value="selectedImport"
                        @input="handleRadioInput"
                    >
                        <dt-radio
                            v-for="radioInput in radioInputs"
                            :key="radioInput.value"
                            :aria-label="`${radioInput.value}-radio-button`"
                            :value="radioInput.value"
                            class="d-mb8"
                        >
                            {{ $t(radioInput.name) }}
                        </dt-radio>
                    </dt-radio-group>
                </div>
                <div class="d-mt16" v-if="additionalRobotsSelected">
                    <dt-input
                        v-model="customRobotFile"
                        class="d-ff-mono d-hmn128"
                        type="textarea"
                        :label="$t('Custom robot file')"
                    />
                </div>
                <div class="d-mt16 d-c-pointer" @click="handleToggleClick">
                    <b class="d-w100p d-d-flex d-ai-center">
                        <dt-icon
                            class="d-mr8"
                            :name="
                                showAdvancedSettings
                                    ? 'chevron-down'
                                    : 'chevron-right'
                            "
                        />
                        {{ $t('Advanced settings') }}
                    </b>
                    <p class="d-fc-black-600 d-fs-100 d-pl32">
                        {{ $t('Only use for troubleshooting') }}
                    </p>
                </div>
                <div v-if="showAdvancedSettings">
                    <dt-checkbox-group
                        :selected-values="advancedSettings"
                        name="advanced-settings-group"
                        :aria-label="$t('Advanced settings checkbox group')"
                    >
                        <div
                            class="d-mb8 d-w90p"
                            :class="{
                                'd-mt16': index === 0
                            }"
                            v-for="(checkbox, index) in checkboxOptions"
                            :key="checkbox.value"
                        >
                            <dt-checkbox
                                :value="checkbox.value"
                                :label="$t(checkbox.name)"
                                :description="
                                    checkbox?.description
                                        ? $t(checkbox.description)
                                        : ''
                                "
                                :aria-label="`${checkbox.value}-checkbox`"
                                @input="
                                    (e: Event) =>
                                        handleCheckboxClick(e, checkbox)
                                "
                            >
                                {{ $t(checkbox.name) }}
                            </dt-checkbox>
                            <template
                                v-if="
                                    checkbox.value === 'use_custom_agent' &&
                                    userAgentChecked
                                "
                            >
                                <div class="d-mt8">
                                    <dt-input
                                        ref="userAgentInput"
                                        v-model="userAgent"
                                        :placeholder="$t('User Agent')"
                                    />
                                </div>
                            </template>
                            <template
                                v-if="
                                    checkbox.value === 'css_selector' &&
                                    cssSelectorChecked
                                "
                            >
                                <div class="d-mt8">
                                    <dt-input
                                        :description="
                                            $t(
                                                'Ensure meaningful content is imported by selecting with a CSS selector.'
                                            )
                                        "
                                        ref="cssSelectorInput"
                                        v-model="cssSelector"
                                        class="d-ff-mono"
                                        placeholder="div.article"
                                    />
                                </div>
                            </template>
                            <template
                                v-if="
                                    checkbox.value === 'min_wait_duration_ms' &&
                                    minWaitMsChecked
                                "
                            >
                                <div class="d-mt8 d-w100p">
                                    <div class="d-d-flex d-jc-center">
                                        {{ minWaitMs }} ms
                                    </div>
                                    <input
                                        class="slider"
                                        type="range"
                                        :min="500"
                                        :max="5000"
                                        v-model="minWaitMs"
                                    />
                                </div>
                            </template>
                        </div>
                    </dt-checkbox-group>
                </div>
            </template>
            <template #footer>
                <div class="d-d-flex d-fd-column d-py16">
                    <div class="d-d-flex d-w100p">
                        <dt-checkbox
                            aria-label="checkbox-terms"
                            :checked="termsCheckbox"
                            @input="handleTermsCheckbox"
                            :disabled="!!webcrawlerHost"
                        />
                        <div class="d-ml8">
                            {{
                                $t(
                                    'I have legal rights to use the crawled and imported content from this website'
                                )
                            }}
                            <b>({{ $t('Required') }})</b>
                        </div>
                    </div>
                    <div class="d-w100p d-d-flex d-jc-flex-end d-mt16">
                        <dt-button
                            class="d-mr8"
                            importance="clear"
                            @click="handleBackClick"
                        >
                            {{ $t('Cancel') }}
                        </dt-button>
                        <dt-button
                            @click="handleSetUp"
                            :disabled="!termsCheckbox"
                        >
                            {{
                                `${
                                    webcrawlerHost
                                        ? $t('Configure')
                                        : $t('Set up')
                                }`
                            }}
                        </dt-button>
                    </div>
                </div>
            </template>
        </drawer-template>
    </Transition>
</template>

<script lang="ts">
import { defineComponent, inject, nextTick, type PropType } from 'vue';
import {
    DtInput,
    DtRadio,
    DtRadioGroup,
    DtIcon,
    DtButton,
    DtCheckbox,
    DtCheckboxGroup,
    VALIDATION_MESSAGE_TYPES
} from '@dialpad/dialtone/vue3';
import DrawerTemplate from '@/components/drawer-template/DrawerTemplate.vue';
import {
    CUSTOM_ROBOT_FILE,
    URL_REGEX,
    WEBCRAWLER_RADIO_INPUTS,
    WEBCRAWLER_CHECKBOXES,
    TRAILING_SLASH_REGEX,
    LEADING_SLASH_REGEX
} from '@/utils/Constants';
import type {
    Knowledgebase,
    ListTagsOKBody,
    WebcrawlerDomain
} from '@/open-api';
import BaseCombobox from '@/components/base-combobox/BaseCombobox.vue';
import { handleRequest } from '@/utils/Common';
import type { PutWebcrawlerDomainRequest } from '@/open-api';

function initialState() {
    return {
        selectedImport: 'only_given_pages' as null | string | Event,
        showAdvancedSettings: false as boolean | Event,
        validationTypes: VALIDATION_MESSAGE_TYPES,
        baseUrlError: [] as any[],
        termsCheckbox: false as boolean,
        startingPages: [{ value: '' }] as { value: string }[],
        startingPageError: [] as any[],
        customRobotFile: '',
        isSettingUp: false,
        selectedLabels: [] as string[],
        baseUrl: '',
        advancedSettings: [] as string[],
        radioInputs: WEBCRAWLER_RADIO_INPUTS,
        checkboxOptions: WEBCRAWLER_CHECKBOXES,
        labels: { tags: [] } as ListTagsOKBody,
        hasComboboxError: false,
        isComboboxFetching: false,
        userAgentChecked: false,
        cssSelectorChecked: false,
        minWaitMsChecked: false,
        userAgent: '',
        cssSelector: '',
        minWaitMs: 500
    };
}

export default defineComponent({
    props: {
        showWebcrawler: {
            type: Boolean as PropType<boolean>,
            required: true
        },
        webcrawler: {
            type: Object as PropType<WebcrawlerDomain>
        }
    },
    setup() {
        const orgId: string = inject('orgId')!;

        return {
            orgId
        };
    },
    computed: {
        /* v8 ignore next 3 */
        knowledgebase(): Knowledgebase {
            return this.$store.getters[`${this.orgId}/currentKnowledgebase`];
        },
        tags(): string[] {
            return this.labels?.tags || [];
        },
        lastStartingPageIsEmpty(): boolean {
            return !this.startingPages?.[this.startingPages?.length - 1].value;
        },
        additionalRobotsSelected(): boolean {
            return this.selectedImport === 'use_additional_robots';
        },
        webcrawlerHost(): string | undefined {
            return this.webcrawler?.host;
        }
    },
    watch: {
        webcrawler(newWebcrawler: WebcrawlerDomain) {
            this.baseUrl = newWebcrawler?.host || '';
            this.startingPages = newWebcrawler?.starting_pages?.map(
                (sp: string) => ({ value: sp })
            ) || [{ value: '' }];
            this.selectedLabels = newWebcrawler?.labels || [];

            const radioInputValues: string[] = this.radioInputs.map(
                (radio) => radio.value
            );

            this.advancedSettings =
                this.webcrawler?.flags?.filter(
                    (flag: string) => !radioInputValues.includes(flag)
                ) || [];

            this.userAgent = this.webcrawler?.custom_agent || '';
            this.userAgentChecked = !!this.webcrawler?.custom_agent?.length;
            this.customRobotFile = this.webcrawler?.additional_robots || '';
            this.cssSelector = this.webcrawler?.css_selector || '';
            this.cssSelectorChecked = !!this.webcrawler?.css_selector?.length;
            this.minWaitMs = this.webcrawler?.min_wait_duration_ms || 500;
            this.minWaitMsChecked = this.minWaitMs !== 500;

            this.selectedImport =
                radioInputValues.find(
                    (val: string) => this.webcrawler?.flags?.indexOf(val)! >= 0
                ) || 'only_given_pages';

            this.showAdvancedSettings = !!this.advancedSettings.length;
            this.termsCheckbox = !!this.webcrawler?.host;
        },
        showWebcrawler(isOpen) {
            if (isOpen) {
                this.fetchTags();
            }
        },
        additionalRobotsSelected(isSelected) {
            if (isSelected && !this.webcrawler?.additional_robots?.length) {
                this.customRobotFile = CUSTOM_ROBOT_FILE;
            }
        }
    },
    components: {
        BaseCombobox,
        DtInput,
        DtIcon,
        DtRadio,
        DtRadioGroup,
        DtButton,
        DtCheckbox,
        DtCheckboxGroup,
        DrawerTemplate
    },
    emits: ['back', 'webcrawlerClose'],
    methods: {
        isFirstElement(index: number): boolean {
            return index === 0;
        },
        handleClose() {
            this.$emit('webcrawlerClose');
            this.resetValues();
        },
        handleBackClick(status?: string | Event) {
            this.$emit('back', {
                status: typeof status === 'string' ? status : undefined,
                webcrawlerDomain: this.webcrawler
            });
            this.resetValues();
        },
        handleToggleClick() {
            this.showAdvancedSettings = !this.showAdvancedSettings;
        },
        validateURL(url: any) {
            if (!url?.length) {
                this.baseUrlError = [
                    {
                        message: this.$t('This is required'),
                        type: VALIDATION_MESSAGE_TYPES.ERROR
                    }
                ];

                return;
            }

            if (!URL_REGEX.test(url)) {
                this.baseUrlError = [
                    {
                        message: this.$t(
                            'The URL provided does not have a sitemap. You must provide a starting page.'
                        ),
                        type: VALIDATION_MESSAGE_TYPES.ERROR
                    }
                ];
            } else if (TRAILING_SLASH_REGEX.test(url)) {
                this.baseUrlError = [
                    {
                        message: this.$t(
                            'The URL provided must not have trailing slash.'
                        ),
                        type: VALIDATION_MESSAGE_TYPES.ERROR
                    }
                ];
            } else {
                this.baseUrlError = [];
            }
        },
        validateStartingPage(page: any) {
            if (page.length && !LEADING_SLASH_REGEX.test(page)) {
                this.startingPageError = [
                    {
                        message: this.$t(
                            "The starting page you provided must start with a slash '/'"
                        ),
                        type: VALIDATION_MESSAGE_TYPES.ERROR
                    }
                ];
            } else {
                this.startingPageError = [];
            }
        },
        validateStartingPages() {
            if (!this.startingPages?.[0].value.length) {
                this.startingPageError = [
                    {
                        message: this.$t('This is required'),
                        type: VALIDATION_MESSAGE_TYPES.ERROR
                    }
                ];
            } else {
                this.startingPageError = [];
            }
        },
        handleAddAnotherPage() {
            this.startingPages.push({ value: '' });
        },
        handleRemoveInput(index: number) {
            this.startingPages.splice(index, 1);
        },
        handleTermsCheckbox(checked: any) {
            this.termsCheckbox = !!checked;
        },
        handleRadioInput(val: Event) {
            this.selectedImport = val;
        },
        /* v8 ignore next 50 */
        handleCheckboxClick(checked: Event, el: any) {
            if (el.value === 'use_custom_agent') {
                this.userAgentChecked = !!checked;
                if (!this.userAgentChecked) {
                    this.userAgent = '';
                } else {
                    nextTick(() => {
                        if (
                            this.userAgentChecked &&
                            this.$refs.userAgentInput
                        ) {
                            // @ts-ignore
                            const userAgentRef = this.$refs
                                .cssSelectorInput?.[0] as HTMLElement;
                            userAgentRef?.focus();
                        }
                    });
                }
            } else if (el.value === 'css_selector') {
                this.cssSelectorChecked = !!checked;
                if (!this.cssSelectorChecked) {
                    this.cssSelector = '';
                } else {
                    nextTick(() => {
                        if (
                            this.cssSelectorChecked &&
                            this.$refs.cssSelectorInput
                        ) {
                            // @ts-ignore
                            const cssSelRef = this.$refs
                                .cssSelectorInput?.[0] as HTMLElement;
                            cssSelRef?.focus();
                        }
                    });
                }
            } else if (el.value === 'min_wait_duration_ms') {
                this.minWaitMsChecked = !!checked;
                if (!this.minWaitMsChecked) {
                    this.minWaitMs = 500;
                } else {
                    nextTick(() => {
                        if (
                            this.minWaitMsChecked &&
                            this.$refs.minWaitMsInput
                        ) {
                            // @ts-ignore
                            const minWaitMsRef = this.$refs
                                .minWaitMsInput?.[0] as HTMLElement;
                            minWaitMsRef?.focus();
                        }
                    });
                }
            }
            // Checked checkboxes are handled by checkbox-group selected-values
            // We use this to handle unchecked checkboxes
            if (!checked) {
                const index = this.advancedSettings.indexOf(el.value);
                if (index !== -1) {
                    this.advancedSettings.splice(index, 1);
                }
            }
        },
        handleSetUp() {
            this.validateStartingPages();
            if (!this.baseUrlError.length && !this.startingPageError.length) {
                this.putWebcrawlerDomain();
            }
        },
        resetValues() {
            Object.assign(this.$data, initialState());
        },
        async putWebcrawlerDomain() {
            this.isSettingUp = true;
            let flags = this.advancedSettings;
            // Remove css_selector flag if css_selector is an empty string
            if (this.cssSelectorChecked && !this.cssSelector.length) {
                flags = flags.filter((f: string) => f !== 'css_selector');
            }
            if (this.minWaitMsChecked && this.minWaitMs === 500) {
                flags = flags.filter(
                    (f: string) => f !== 'min_wait_duration_ms'
                );
            }
            const selectedImp = this.selectedImport as string;
            if (this.selectedImport) {
                flags.push(selectedImp);
            }

            let domain: WebcrawlerDomain = {
                additional_robots: this.additionalRobotsSelected
                    ? this.customRobotFile
                    : '',
                custom_agent: this.userAgentChecked ? this.userAgent : '',
                flags,
                forced_locales: [],
                host: this.baseUrl,
                labels: this.selectedLabels,
                min_wait_duration_ms: parseInt(this.minWaitMs.toString()),
                starting_pages: this.startingPages.map((sp) => sp.value)
            };

            if (this.cssSelector.length) {
                domain = {
                    ...domain,
                    css_selector: this.cssSelector
                };
            }

            const resKb = await handleRequest<PutWebcrawlerDomainRequest>(
                this.$store.getters[
                    `${this.orgId}/apiService`
                ]?.ingestion.putWebcrawlerDomain(
                    this.$store.getters[`${this.orgId}/authToken`],
                    this.knowledgebase?.id,
                    this.baseUrl,
                    {
                        domain
                    }
                ),
                this.orgId
            );
            this.isSettingUp = false;

            this.handleBackClick(resKb.error ? 'error' : 'success');
        },
        async fetchTags() {
            this.isComboboxFetching = true;
            this.labels = { tags: [] };
            const res = await handleRequest<ListTagsOKBody>(
                this.$store.getters[
                    `${this.orgId}/apiService`
                ]?.knowledge.listTags(
                    this.$store.getters[`${this.orgId}/authToken`],
                    this.knowledgebase?.id
                ),
                this.orgId
            );
            this.isComboboxFetching = false;
            this.hasComboboxError = !!res.error;
            if (res?.data) this.labels = res.data;
        }
    },
    data() {
        return {
            ...initialState()
        };
    }
});
</script>
<style lang="less" scoped>
.slider {
    appearance: none; /* removes browser-specific styling */
    width: 100%; /* width of slider */
    height: 0.3rem; /* height of slider */
    background: var(--dt-color-purple-300); /* orange background */
    outline: none; /* remove outline */
    border-radius: 12px; /* round corners */

    &::-webkit-slider-thumb {
        appearance: none; /* removes browser-specific styling */
        width: 1.4rem; /* handle width */
        height: 1.4rem; /* handle height */
        border-radius: 50%; /* make it circular */
        background-color: #fff;
        border: 2px solid var(--dt-color-purple-300);
        cursor: pointer; /* cursor on hover */
    }
}
</style>
