<template>
    <drawer-template @drawer-template-close="close" force-width>
        <template #header>
            <h3 class="d-py8">{{ $t('Configure sources') }}</h3>
        </template>
        <template #default>
            <div>
                <div v-if="externalSources.length">
                    <h4 class="d-pb16">{{ $t('External sources') }}</h4>
                    <p class="d-fc-black-600 d-fs-100 d-pb16">
                        {{ $t('Connect an external content source to import') }}
                    </p>
                    <div
                        class="d-d-flex d-ai-center d-py12 d-bb d-baw1 d-bc-black-300 d-ff-row-wrap d-mb12"
                        v-for="(externalSource, index) in externalSources"
                        v-feature="externalSource.name"
                        :key="externalSource.name"
                        :class="{
                            'd-mb16': externalSources.length - 1 === index
                        }"
                    >
                        <dt-icon
                            name="google-drive"
                            class="d-mr8"
                            v-if="externalSource.name === 'Google Drive'"
                        />
                        <dt-icon
                            name="zendesk"
                            class="d-mr8"
                            v-if="externalSource.name === 'Zendesk'"
                        />
                        <box-logo
                            class="d-mr8"
                            v-if="externalSource.name === 'Box'"
                        />
                        <document360-logo
                            class="d-mr8"
                            v-if="externalSource.name === 'Document360'"
                        />
                        <span>{{ externalSource.name }}</span>
                        <div class="d-ml-auto">
                            <dt-button
                                size="xs"
                                :loading="isFetchingServices"
                                importance="outlined"
                                :disabled="
                                    isImportInProgress(externalSource.name)
                                "
                                @click="handleConnectClick(externalSource)"
                            >
                                {{
                                    externalSource.status === connected
                                        ? $t('Configure')
                                        : $t('Connect')
                                }}
                            </dt-button>
                        </div>
                        <div
                            class="d-w100p d-ml24 d-fs-100 d-pl8"
                            v-if="isFetchingServices"
                        >
                            <dt-skeleton
                                arial-label="Loading"
                                :paragraphOption="{ rows: 1 }"
                            />
                        </div>
                        <div
                            class="d-w100p d-ml24 d-fs-100 d-pl8"
                            v-if="
                                !isFetchingServices &&
                                externalSource.status === connected
                            "
                        >
                            <div class="d-d-flex d-gg4 d-ai-center">
                                <span class="d-fc-green-400">
                                    {{ $t(connected) }}
                                </span>
                                <span
                                    class="d-d-flex d-ai-center d-gg4"
                                    v-if="
                                        getImportStatusText(
                                            externalSource?.name
                                        )
                                    "
                                >
                                    <span>
                                        •
                                        {{
                                            getImportStatusText(
                                                externalSource?.name
                                            )
                                        }}
                                    </span>
                                    <loading
                                        v-if="
                                            isImportInProgress(
                                                externalSource?.name
                                            )
                                        "
                                    />
                                    <dt-icon
                                        v-else-if="
                                            isImportInQueue(
                                                externalSource?.name
                                            )
                                        "
                                        name="clock"
                                        size="100"
                                    />
                                </span>
                                <span
                                    v-if="
                                        externalSource.name === 'Google Drive'
                                    "
                                >
                                    <span
                                        class="d-d-flex d-ai-center d-gg4"
                                        v-if="
                                            gDriveFolderStatus ===
                                            'unauthorized'
                                        "
                                    >
                                        •
                                        <span class="d-fc-warning">
                                            {{ $t('Unauthorized') }}
                                        </span>
                                        <dt-icon
                                            name="alert-triangle"
                                            size="100"
                                            class="d-fc-warning"
                                        />
                                    </span>
                                    <span
                                        class="d-d-flex d-ai-center d-gg4"
                                        v-if="
                                            gDriveSettings?.folders?.length ===
                                            0
                                        "
                                    >
                                        •
                                        <span class="d-fc-warning">
                                            {{ $t('No folder selected') }}
                                        </span>
                                        <dt-icon
                                            name="alert-triangle"
                                            size="100"
                                            class="d-fc-warning"
                                        />
                                    </span>
                                </span>
                            </div>

                            <span
                                class="d-fc-black-600"
                                v-if="externalSource.date"
                            >
                                <span class="d-px4">•</span>
                                {{ externalSource.date }}
                            </span>
                        </div>
                        <div
                            class="d-w100p d-ml24 d-fs-100 d-pl8"
                            v-if="
                                !isFetchingServices &&
                                externalSource.status !== connected
                            "
                        >
                            <span class="d-fc-red-400">{{
                                $t(notConnected)
                            }}</span>
                        </div>
                    </div>
                </div>
                <div>
                    <h4 class="d-py16">{{ $t('Webcrawler') }}</h4>
                    <dt-notice
                        v-if="hasWebcrawlerStatus"
                        :kind="webcrawlerNoticeKind"
                        :close-button-props="{
                            ariaLabel: $t('Webcrawler notice')
                        }"
                        @close="closeWebcrawlerNotice"
                        class="d-mb16"
                        :title="webcrawlerNoticeMessage"
                    />
                    <p class="d-fc-black-600 d-fs-100 d-pb16">
                        {{
                            $t(
                                'Setup a webcrawler to import and keep your online help center in sync with your Dialpad Self service knowledge base. We will use them to automatically generate responses to answer your customers.'
                            )
                        }}
                    </p>
                    <div
                        class="d-d-flex d-ai-center d-py12 d-bb d-baw1 d-bc-black-300 d-ff-row-wrap d-mb12"
                        v-for="webcrawler in webcrawlerDomains"
                        :key="webcrawler?.host"
                    >
                        <p>{{ webcrawler?.host }}</p>
                        <menu-popover
                            id="WebcrawlerActionButtons"
                            :options="webcrawlerOptions"
                            class="d-ml-auto"
                            append-to="parent"
                            @click="handleWebcrawlerClick(webcrawler)"
                        />
                        <div
                            class="d-d-flex d-gg4 d-ai-center d-w100p d-fs-100"
                        >
                            <span class="d-fc-green-400">
                                {{ $t(connected) }}
                            </span>
                            <span
                                class="d-d-flex d-ai-center d-gg4"
                                v-if="
                                    getImportStatusText(
                                        webcrawler.host!.toLowerCase(),
                                        true
                                    )
                                "
                            >
                                <span>
                                    •
                                    {{
                                        getImportStatusText(
                                            webcrawler.host!.toLowerCase(),
                                            true
                                        )
                                    }}
                                </span>
                                <loading
                                    v-if="
                                        isImportInProgress(
                                            webcrawler.host!.toLowerCase(),
                                            true
                                        )
                                    "
                                />
                                <loading
                                    v-if="
                                        isImportInQueue(
                                            webcrawler.host!.toLowerCase(),
                                            true
                                        )
                                    "
                                />
                            </span>
                        </div>
                    </div>
                    <dt-button
                        size="xs"
                        class="d-mt4"
                        importance="outlined"
                        label-class="d-ml4"
                        @click="handleConnectNewContentSource"
                    >
                        {{ $t('Connect content source') }}
                    </dt-button>
                </div>
            </div>
        </template>
        <template #secondaryPages>
            <zendesk-connect-page
                :show-zendesk-connect-page="!!zendeskData"
                :data="zendeskData"
                @back="handleConnectBackClick"
                @connect-close="close"
                @zendesk-on-force-closed="handleZendeskOnForceClosed"
                :key="zenInstanceKey"
            />
            <box-connect-page
                :show-box-connect-page="!!boxData"
                :data="boxData"
                @back="handleConnectBackClick"
                @connect-close="close"
            />
            <document360-connect-page
                :show-document360-connect-page="!!document360Data"
                :data="document360Data"
                @back="handleConnectBackClick"
                @connect-close="close"
            />
            <google-drive-connect-page
                :show-google-drive-connect-page="!!gDriveData"
                :data="gDriveData"
                @back="handleConnectBackClick"
                @connect-close="close"
            />
            <webcrawler-page
                :showWebcrawler="showWebcrawlerPage"
                :webcrawler="selectedWebcrawler"
                @back="handleWebcrawlerBackClick"
                @webcrawler-close="close"
            />
        </template>
    </drawer-template>
    <confirmation-modal
        title="Delete content source"
        description="Your content source will be deleted and all previously imported documents will be archived. Are you sure you want to proceed?"
        button-title="Yes, delete content source"
        v-model:show-prompt="showConfirmationModal"
        @confirm="deleteWebcrawlerDomain"
    />
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import {
    DtButton,
    DtInput,
    DtIcon,
    DtNotice,
    DtSkeleton
} from '@dialpad/dialtone/vue3';
import DrawerTemplate from '@/components/drawer-template/DrawerTemplate.vue';
import ZendeskConnectPage from './ZendeskConnectPage.vue';
import BoxConnectPage from './BoxConnectPage.vue';
import Document360ConnectPage from './Document360ConnectPage.vue';
import GoogleDriveConnectPage from './GoogleDriveConnectPage.vue';
import WebcrawlerPage from './WebcrawlerPage.vue';
import MenuPopover from '@/components/menu-popover/MenuPopover.vue';
import ConfirmationModal from '@/components/confirmation-modal/ConfirmationModal.vue';
import { capitalizeString, handleRequest, uuidv4 } from '@/utils/Common';
import { NOTICE_KINDS, STATUS } from '@/utils/Constants';
import {
    type ListWebcrawlerDomainsResponse,
    type AuthorizedServicesResponse,
    type WebcrawlerDomain,
    type Knowledgebase,
    GetStatusResponseStatusEnum,
    GetSettingsResponse
} from '@/open-api';
import type { IExternalSource, INotice } from './Sources.types';

import BoxLogo from '@/assets/box-logo.vue';
import Document360Logo from '@/assets/document360-logo.vue';
// @ts-ignore
import type { IMenuPopoverOptions } from '@/components/menu-popover/MenuPopover.types';
import type { ApiService } from '@/services/Api.service';
import { FetchingEnum, ImportStatusType } from '@/store/ImportStatusModule';
import Loading from '@/components/loading/Loading.vue';

export default defineComponent({
    components: {
        Loading,
        DtSkeleton,
        DtButton,
        DtInput,
        DtIcon,
        DtNotice,
        BoxLogo,
        Document360Logo,
        DrawerTemplate,
        ZendeskConnectPage,
        BoxConnectPage,
        WebcrawlerPage,
        Document360ConnectPage,
        GoogleDriveConnectPage,
        MenuPopover,
        ConfirmationModal
    },
    setup() {
        const orgId: string = inject('orgId')!;

        return {
            orgId
        };
    },
    mounted() {
        this.getAuthorizedServices();
        this.getWebcrawlerDomains();
    },
    computed: {
        FetchingEnum() {
            return FetchingEnum;
        },
        /* v8 ignore next 40 */
        hasWebcrawlerStatus(): boolean {
            return !!Object.keys(this.webcrawlerStatusNotification).length;
        },
        webcrawlerNoticeKind(): string | undefined {
            return this.webcrawlerStatusNotification.kind;
        },
        webcrawlerNoticeMessage(): string | undefined {
            return this.webcrawlerStatusNotification.message;
        },
        webcrawlerDomains(): WebcrawlerDomain[] {
            return this.webcrawlers.domains || [];
        },
        knowledgebase(): Knowledgebase {
            return this.$store.getters[`${this.orgId}/currentKnowledgebase`];
        },
        apiService(): ApiService | undefined {
            return this.$store.getters[`${this.orgId}/apiService`];
        },
        authToken(): string {
            return this.$store.getters[`${this.orgId}/authToken`];
        },
        webcrawler(): { [x: string]: ImportStatusType } {
            return this.$store.getters[`${this.orgId}/importStatus/webcrawler`];
        },
        document360(): ImportStatusType {
            return this.$store.getters[
                `${this.orgId}/importStatus/document360`
            ];
        },
        gdrive(): ImportStatusType {
            return this.$store.getters[`${this.orgId}/importStatus/gdrive`];
        },
        zendesk(): ImportStatusType {
            return this.$store.getters[`${this.orgId}/importStatus/zendesk`];
        },
        box(): ImportStatusType {
            return this.$store.getters[`${this.orgId}/importStatus/box`];
        },
        webcrawlerStatus(): { [x: string]: ImportStatusType } {
            return this.$store.getters[`${this.orgId}/importStatus/webcrawler`];
        }
    },
    methods: {
        close() {
            this.$emit('close');
            this.handleConnectBackClick({});
            this.handleWebcrawlerBackClick();
            this.closeAllNotices();
        },
        handleConnectClick(source: any) {
            this.closeAllNotices();
            if (source.name === 'Zendesk') {
                this.zendeskData = this.externalSources.find(
                    (src: IExternalSource) => src.name === 'Zendesk'
                );
            } else if (source.name === 'Box') {
                this.boxData = this.externalSources.find(
                    (src: IExternalSource) => src.name === 'Box'
                );
            } else if (source.name === 'Document360') {
                this.document360Data = this.externalSources.find(
                    (src: IExternalSource) => src.name === 'Document360'
                );
            } else if (source.name === 'Google Drive') {
                this.gDriveData = this.externalSources.find(
                    (src: IExternalSource) => src.name === 'Google Drive'
                );
            }
        },
        handleConnectBackClick(response: INotice) {
            if (Object.keys(response).length) {
                this.getAuthorizedServices();

                this.$store.commit(`${this.orgId}/addNotification`, {
                    kind: NOTICE_KINDS.SUCCESS,
                    title: response.title,
                    message: response.message
                });
            }

            if (this.gDriveStatus === this.connected) {
                this.getGDriveSettings();
            }
            this.zendeskData = undefined;
            this.boxData = undefined;
            this.document360Data = undefined;
            this.gDriveData = undefined;
        },
        handleWebcrawlerBackClick(payload?: {
            status: string;
            webcrawlerDomain: WebcrawlerDomain;
        }) {
            this.showWebcrawlerPage = false;
            this.selectedWebcrawler = null;

            if (payload?.status === 'success') {
                this.getWebcrawlerDomains();
                this.webcrawlerStatusNotification = {
                    message: this.$t(
                        'New content source added. Import in progress.'
                    ),
                    kind: NOTICE_KINDS.SUCCESS
                };
            } else if (payload?.status === 'error') {
                this.webcrawlerStatusNotification = {
                    message: this.$t('New content source could not be added.'),
                    kind: NOTICE_KINDS.ERROR
                };
            }
        },
        handleWebcrawlerClick(webcrawler?: any) {
            this.selectedWebcrawler = webcrawler;
        },
        handleConnectNewContentSource() {
            this.selectedWebcrawler = null;
            this.handleShowWebcrawlerPage();
        },
        handleShowWebcrawlerPage() {
            this.showWebcrawlerPage = true;
            this.closeAllNotices();
        },
        closeWebcrawlerNotice() {
            this.webcrawlerStatusNotification = {};
        },
        closeAllNotices() {
            this.closeWebcrawlerNotice();
        },
        async getAuthorizedServices() {
            if (!this.apiService) return;

            this.isFetchingServices = true;
            const resKb = await handleRequest<AuthorizedServicesResponse>(
                this.apiService.ingestion.getAuthorizedServices(
                    this.authToken,
                    this.knowledgebase.id!
                ),
                this.orgId
            );
            this.isFetchingServices = false;

            if (resKb.error) {
                this.$store.commit(`${this.orgId}/addNotification`, {
                    kind: NOTICE_KINDS.ERROR,
                    title: this.$t('Could not get authorized services')
                });
            } else {
                const data = resKb.data as AuthorizedServicesResponse;

                // Authorize services needs to have this order: Google Drive, Zendesk, Box and Document360
                const authorizedServices = [
                    'gdrive',
                    'zendesk',
                    'box',
                    'document360'
                ].filter((authorizedService) =>
                    Object.keys(data).includes(authorizedService)
                );

                if (authorizedServices.length) {
                    this.externalSources = authorizedServices.map(
                        (authorizedService: string) => {
                            return {
                                name:
                                    authorizedService === 'gdrive'
                                        ? 'Google Drive'
                                        : capitalizeString(authorizedService),
                                status: data[authorizedService]
                                    ? this.connected
                                    : this.notConnected
                            };
                        }
                    );

                    // If GDrive connected then get Google Drive Settings
                    if (data['gdrive']) {
                        await this.getGDriveSettings();
                    }
                }
            }
        },
        getExtSrcName(name: string) {
            switch (name) {
                case 'Google Drive':
                    return 'gdrive';
                default:
                    return name.toLowerCase();
            }
        },
        isImportInProgress(
            externalSourceName: string,
            isWebcrawler?: boolean
        ): boolean {
            const importStatus: ImportStatusType = isWebcrawler
                ? this.webcrawlerStatus?.[externalSourceName]
                : this[this.getExtSrcName(externalSourceName)];

            return (
                importStatus?.toString() === GetStatusResponseStatusEnum.Running
            );
        },
        isImportInQueue(
            externalSourceName: string,
            isWebcrawler?: boolean
        ): boolean {
            const importStatus: ImportStatusType = isWebcrawler
                ? this.webcrawlerStatus?.[externalSourceName]
                : this[this.getExtSrcName(externalSourceName)];

            return (
                importStatus?.toString() ===
                GetStatusResponseStatusEnum.Scheduled
            );
        },
        getImportStatusText(
            externalSourceName: string,
            isWebcrawler?: boolean
        ): string | undefined {
            const importStatus: ImportStatusType = isWebcrawler
                ? this.webcrawlerStatus?.[externalSourceName]
                : this[this.getExtSrcName(externalSourceName)];

            if (!importStatus) return undefined;
            switch (importStatus.toString()) {
                case GetStatusResponseStatusEnum.Scheduled: {
                    return this.$t('Import in queue');
                }
                case GetStatusResponseStatusEnum.Running: {
                    return this.$t('Import in progress');
                }
                default: {
                    return;
                }
            }
        },
        async getWebcrawlerDomains() {
            if (!this.apiService) return;

            const resKb = await handleRequest<ListWebcrawlerDomainsResponse>(
                this.apiService.ingestion.listWebcrawlerDomains(
                    this.authToken,
                    this.knowledgebase.id!
                ),
                this.orgId
            );

            if (!resKb.error) {
                const data = resKb.data;

                if (data) {
                    this.webcrawlers = data as ListWebcrawlerDomainsResponse;
                }
            }
        },
        async deleteWebcrawlerDomain() {
            if (!this.apiService) return;

            this.isDeletingWebcrawlerDomain = true;
            const resKb = await handleRequest<any>(
                this.apiService.ingestion.deleteWebcrawlerDomain(
                    this.authToken,
                    this.knowledgebase.id!,
                    this.selectedWebcrawler.host
                ),
                this.orgId
            );
            this.isDeletingWebcrawlerDomain = false;

            if (resKb.error) {
                this.webcrawlerStatusNotification = {
                    message: 'There was an error',
                    kind: NOTICE_KINDS.ERROR
                };
            } else {
                this.webcrawlerStatusNotification = {
                    message: 'Content source deleted',
                    kind: NOTICE_KINDS.SUCCESS
                };
            }

            await this.getWebcrawlerDomains();
        },
        /* v8 ignore next 68 */
        handleZendeskOnForceClosed() {
            this.zenInstanceKey = uuidv4();
        },
        handleShowConfirmationModal() {
            this.showConfirmationModal = true;
        },
        async getGDriveSettings() {
            this.isLoadingGDriveSettings = true;

            const gDriveSettingsRes = await handleRequest<GetSettingsResponse>(
                this.$store.getters[
                    `${this.orgId}/apiService`
                ]?.ingestion.getGDriveSettings(
                    this.$store.getters[`${this.orgId}/authToken`],
                    this.knowledgebase?.id,
                    {
                        redirect_uri: this.configUrl
                    }
                ),
                this.orgId
            );
            this.isLoadingGDriveSettings = false;
            if (gDriveSettingsRes?.data) {
                this.gDriveSettings = gDriveSettingsRes?.data;
                this.gDriveFolderStatus =
                    this.gDriveSettings.folders?.[0]?.status;
            }
        }
    },
    data() {
        return {
            zendeskData: undefined as undefined | IExternalSource,
            boxData: undefined as undefined | IExternalSource,
            document360Data: undefined as undefined | IExternalSource,
            gDriveData: undefined as undefined | IExternalSource,
            showWebcrawlerPage: false,
            selectedWebcrawler: null as any,
            externalSources: [] as IExternalSource[],
            webcrawlers: {} as ListWebcrawlerDomainsResponse,
            webcrawlerStatusNotification: {} as INotice,
            isFetchingServices: false,
            connected: STATUS.CONNECTED,
            notConnected: STATUS.NOT_CONNECTED,
            isDeletingWebcrawlerDomain: false,
            zenInstanceKey: uuidv4(),
            showConfirmationModal: false,
            isLoadingGDriveSettings: false,
            gDriveSettings: undefined as GetSettingsResponse,
            gDriveFolderStatus: undefined,
            webcrawlerOptions: [
                {
                    title: 'Configure',
                    action: () => {
                        this.handleShowWebcrawlerPage();
                    }
                },
                {
                    title: 'Delete',
                    kind: 'danger',
                    action: () => {
                        this.handleShowConfirmationModal();
                    }
                }
            ] as IMenuPopoverOptions[]
        };
    }
});
</script>
