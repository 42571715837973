<template>
    <template v-if="isFetching">
        <div class="d-d-flex d-fd-column d-w64">
            <dt-skeleton
                arial-label="Loading"
                :paragraphOption="{
                    rows: 1,
                    rowClass: 'd-h16 d-mb2 d-mt1',
                    width: ['100%']
                }"
            />
        </div>
    </template>
    <template v-else>
        <template v-if="!visibleKnowledgebases.length">
            <div class="d-d-flex d-fs-100 d-fc-warning d-ai-center">
                <dt-icon name="alert-triangle" size="200" />
                <span class="d-ml4">
                    {{ $t('No knowledge base created') }}
                </span>
            </div>
        </template>
        <template v-else>
            <div
                class="d-fs-100 d-d-flex"
                v-for="(kb, i) in visibleKnowledgebases"
                :key="kb || i"
            >
                <span>{{ kb }}</span>
                <span
                    class="d-px4"
                    v-if="i !== visibleKnowledgebases!.length - 1"
                >
                    •
                </span>
            </div>
            <div class="d-fs-100">
                <dt-tooltip
                    v-if="hiddenKnowledgebases.length"
                    placement="bottom"
                >
                    <template #default>
                        <p
                            v-for="(kb, index) in hiddenKnowledgebases"
                            :key="`${rowId}-kb-${index}`"
                        >
                            {{ getKbTitle(kb) }}
                        </p>
                    </template>
                    <template #anchor>
                        <div class="d-d-flex d-ai-center d-jc-center">
                            <span class="d-px4"> • </span>
                            <span>+{{ hiddenKnowledgebases.length }}</span>
                        </div>
                    </template>
                </dt-tooltip>
            </div>
        </template>
    </template>
</template>
<script lang="ts">
import { defineComponent, inject, type PropType } from 'vue';
import type {
    Knowledgebase,
    ListKnowledgebasesResponse,
    Widget
} from '@/open-api';
import { convertLocaleToLanguage, handleRequest } from '@/utils/Common';
import { DtBadge, DtIcon, DtTooltip } from '@dialpad/dialtone/vue3';
import { DtSkeleton } from '@dialpad/dialtone/vue3';
import type { WidgetInstance } from '@/store/WidgetsModule';
import type { ApiService } from '@/services/Api.service';
import store from '@/store';

export default defineComponent({
    props: {
        chatbot: {
            type: Object as PropType<Widget>,
            required: true
        },
        rowId: {
            type: String as PropType<string>,
            required: true
        }
    },
    components: {
        DtIcon,
        DtSkeleton,
        DtBadge,
        DtTooltip
    },
    computed: {
        /* v8 ignore next 6 */
        apiService(): ApiService {
            return store.getters[`${this.orgId}/apiService`];
        },
        authToken(): string {
            return store.getters[`${this.orgId}/authToken`];
        }
    },
    mounted() {
        this.fetchKbs(this.chatbot);
    },
    setup() {
        const orgId: string = inject('orgId')!;
        return {
            orgId
        };
    },
    methods: {
        /* v8 ignore next 20 */
        getKbTitle(kb: string) {
            let title = convertLocaleToLanguage(kb);
            if (this.chatbot?.default_locale === kb) {
                title = title + ` (${this.$t('Default')})`;
            }
            return title;
        },
        async fetchKbs(chatbot: WidgetInstance) {
            this.isFetching = true;
            const res = await handleRequest<ListKnowledgebasesResponse>(
                this.apiService.knowledge.listKnowledgebases(
                    this.authToken,
                    chatbot.id!
                ),
                this.orgId
            );
            const kbsData = res.data;
            const kbsDetails: Knowledgebase[] = kbsData?.knowledgebases || [];
            this.visibleKnowledgebases = kbsDetails
                .filter((kb: Knowledgebase) => kb.status === 'published')
                .sort((x, y) =>
                    x.locale === this.chatbot?.default_locale
                        ? -1
                        : y.locale === this.chatbot?.default_locale
                          ? 1
                          : 0
                )
                .map((kb: Knowledgebase) => this.getKbTitle(kb.locale))
                .slice(0, this.visibleKBLimit);

            this.hiddenKnowledgebases = kbsDetails
                .filter((kb: Knowledgebase) => kb.status === 'published')
                ?.slice(this.visibleKBLimit)
                .map((kb: Knowledgebase) => this.getKbTitle(kb.locale));
            this.isFetching = false;
        }
    },
    data() {
        return {
            visibleKBLimit: 2,
            isFetching: false,
            visibleKnowledgebases: [] as string[],
            hiddenKnowledgebases: [] as string[]
        };
    }
});
</script>
