import type { NavigationItem } from '@/components/sidebar-menu/SidebarMenu.vue';
import { NodeStatus, type Organisation, type Widget } from '@/open-api';
import {
    LicenseType,
    ResponseNodeBlockTypeEnum,
    WidgetStatus
} from '@/open-api';

export const SOURCES = [
    { value: 'all', label: 'All sources' },
    { value: 'web', label: 'Web' },
    { value: 'zendesk', label: 'Zendesk' },
    { value: 'document360', label: 'Document360' },
    // { value: 'Salesforce', label: 'Salesforce' },
    { value: 'box', label: 'Box' },
    { value: 'uploads', label: 'Uploads' },
    { value: 'gdrive', label: 'Gdrive' }
];

export enum ZONES_ABBR {
    DEVELOP = 'dev',
    STAGING = 'stg',
    EUROPE = 'eu',
    UNITED_STATES = 'us',
    TRICONTES = 'trc'
}

export enum LOCALES {
    'ar-DZ' = 'Arabic DZ',
    'ar-BH' = 'Arabic BH',
    'ar-EG' = 'Arabic EG',
    'ar-IQ' = 'Arabic IQ',
    'ar-JO' = 'Arabic JO',
    'ar-KW' = 'Arabic KW',
    'ar-LB' = 'Arabic LB',
    'ar-LY' = 'Arabic LY',
    'ar-MA' = 'Arabic MA',
    'ar-OM' = 'Arabic OM',
    'ar-QA' = 'Arabic QA',
    'ar-SA' = 'Arabic SA',
    'ar-SY' = 'Arabic SY',
    'ar-TN' = 'Arabic TN',
    'ar-AE' = 'Arabic AE',
    'ar-YE' = 'Arabic YE',
    'da-DK' = 'Danish DK',
    'nl-BE' = 'Dutch BE',
    'nl-NL' = 'Dutch NL',
    'en-AU' = 'English AU',
    'en-CA' = 'English CA',
    'en-IN' = 'English IN',
    'en-IE' = 'English IE',
    'en-HK' = 'English HK',
    'en-MT' = 'English MT',
    'en-NZ' = 'English NZ',
    'en-PH' = 'English PH',
    'en-SG' = 'English SG',
    'en-ZA' = 'English ZA',
    'en-GB' = 'English UK',
    'en-US' = 'English US',
    'fa-IR' = 'Farsi IR',
    'he-IL' = 'Hebrew IL',
    'hi-IN' = 'Hindi IN',
    'tr-TR' = 'Turkish TR',
    'ko-KR' = 'Korean KR',
    'ko-KP' = 'Korean KP',
    'zh-CN' = 'Chinese CN',
    'zh-HK' = 'Chinese HK',
    'zh-MO' = 'Chinese MO',
    'zh-SG' = 'Chinese SG',
    'zh-TW' = 'Chinese TW',
    'fi-FI' = 'Finnish FI',
    'fr-BE' = 'French BE',
    'fr-CA' = 'French CA',
    'fr-FR' = 'French FR',
    'fr-LU' = 'French LU',
    'fr-CH' = 'French CH',
    'de-AT' = 'German AT',
    'de-BE' = 'German BE',
    'de-DE' = 'German DE',
    'de-LU' = 'German LU',
    'de-CH' = 'German CH',
    'it-IT' = 'Italian IT',
    'it-CH' = 'Italian CH',
    'ja-JP' = 'Japanese JP',
    'nb-NO' = 'Norwegian NO',
    'pt-BR' = 'Portuguese BR',
    'pt-PT' = 'Portuguese PT',
    'es-AR' = 'Spanish AR',
    'es-BO' = 'Spanish BO',
    'es-CL' = 'Spanish CL',
    'es-CO' = 'Spanish CO',
    'es-CR' = 'Spanish CR',
    'es-DO' = 'Spanish DO',
    'es-EC' = 'Spanish EC',
    'es-SV' = 'Spanish SV',
    'es-GT' = 'Spanish GT',
    'es-HN' = 'Spanish HN',
    'es-MX' = 'Spanish MX',
    'es-NI' = 'Spanish NI',
    'es-PA' = 'Spanish PA',
    'es-PY' = 'Spanish PY',
    'es-PE' = 'Spanish PE',
    'es-PR' = 'Spanish PR',
    'es-ES' = 'Spanish ES',
    'es-US' = 'Spanish US',
    'es-UY' = 'Spanish UY',
    'es-VE' = 'Spanish VE',
    'sv-SE' = 'Swedish SE'
}

export const URL_REGEX =
    /http(s)?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&/=]*)/;
export const LEADING_SLASH_REGEX = /^\//;
export const TRAILING_SLASH_REGEX = /\/$/;
export const ALPHANUMERIC_REGEX = /^[a-zA-Z0-9 ]+$/;

export const MONTH_NAMES = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];
export const WEEK_DAYS = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
];

export enum ALTER_MODES {
    CREATE = 'create',
    EDIT = 'edit',
    DELETE = 'delete',
    READ = 'read'
}

export const DATA_KEYS = {
    OPENED: 'opened',
    NOT_OPENED: 'notOpened',
    DESKTOP: 'Desktop',
    MOBILE: 'Mobile',
    HELPFUL: 'helpful',
    ENGAGED: 'engaged',
    REGISTERED: 'registered',
    MISSING: 'missing',
    WINDOWS: 'Windows',
    LINUX: 'Linux',
    OSX: 'Mac OS X',
    ANDROID: 'Android',
    VISITOR: 'visitor',
    UNHELPFUL: 'unhelpful',
    RESPONSES: 'responses',
    LIBRARY: 'library',
    TOTAL: 'total',
    BOUNCED: 'bounced',
    RESOLVED: 'resolved',
    CHATTERING: 'chattering',
    RESPONSE: 'response',
    CONTENT: 'content',
    HANDED: 'handed',
    ANSWERED: 'answered',
    UNCERTAIN: 'uncertain',
    ESCALATED: 'escalated',
    NODATA: 'nodata',
    UNRESOLVED: 'unresolved',
    MISSING_KNOWLEDGE: 'missing-knowledge',
    IMPROVE_ANSWER: 'improve-answer',
    AUTO_ANSWER: 'auto-answer',
    QUERIES: 'queries',
    DISAMBIGUATION: 'disambiguation',
    POSITIVE: 'positive',
    NEGATIVE: 'negative',
    P1: 'p1',
    P5: 'p5',
    P_PERCEIVED: 'p_perceived',
    GREATLY_DISSATISFIED: 'greatlyDissatisfied',
    DISSATISFIED: 'dissatisfied',
    MODERATELY_DISSATISFIED: 'moderatelyDissatisfied',
    NEUTRAL: 'neutral',
    MODERATELY_SATISFIED: 'moderatelySatisfied',
    SATISFIED: 'satisfied',
    GREATLY_SATISFIED: 'greatlySatisfied',
    DETRACTORS: 'detractors',
    PROMOTERS: 'promoters',
    EMPTY: 'empty',
    DEFLECTED: 'deflected'
};

export const NOTICE_KINDS = {
    BASE: 'base',
    ERROR: 'error',
    INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning'
};

export const STATUS = {
    CONNECTED: 'Connected',
    NOT_CONNECTED: 'Not connected'
};

// Common mime types from https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
export const ACCEPTED_DOC_FORMATS = [
    '.docx',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf'
];

export const ACCEPTED_IMAGE_FORMATS = ['image/png', 'image/gif', 'image/jpeg'];
export const ACCEPTED_VIDEO_FORMATS = ['video/mp4', 'video/mpg', 'video/mov'];

export const WEBCRAWLER_RADIO_INPUTS = [
    {
        value: 'only_given_pages',
        name: 'Only selected pages'
    },
    { value: 'all', name: 'All of the website' },
    {
        value: 'use_additional_robots',
        name: 'Only a subset of the pages with custom robot file'
    }
];

export const WEBCRAWLER_CHECKBOXES: {
    value: string;
    name: string;
    description?: string;
}[] = [
    {
        value: 'ignore_meta_tags',
        name: 'Ignore Meta directives',
        description:
            'Meta directives are defined for a reason. This flag should only be used as a temporary measure in case a needed page was excluded by mistake, while your system administrator fixes it.'
    },
    { value: 'force_crawl', name: 'Force crawl' },
    {
        value: 'use_custom_agent',
        name: 'Use custom agent'
    },
    {
        value: 'ignore_locale',
        name: 'Ignore Page Locale'
    },
    {
        value: 'ignore_missing_pages',
        name: 'Ignore missing pages from the sitemap'
    },
    {
        value: 'css_selector',
        name: 'CSS selector'
    },
    {
        value: 'min_wait_duration_ms',
        name: 'Minimum wait duration'
    }
];

export const CUSTOM_ROBOT_FILE =
    'User-agent: *\n' + 'Disallow: /\n' + 'Allow: /public/\n';

export const KNOWLEDGE_ROUTES: NavigationItem[] = [
    {
        text: 'Optimize',
        href: '/improve',
        name: 'improve'
    },
    {
        text: 'Documents',
        name: 'documents',
        href: '/documents',
        query: {
            status: NodeStatus.Published
        }
    },
    {
        text: 'Responses',
        name: 'responses',
        href: '/responses',
        query: {
            status: NodeStatus.Published
        }
    },
    {
        text: 'Chatter',
        name: 'chatter',
        href: '/chatter',
        query: {
            status: NodeStatus.Published
        }
    },
    {
        name: 'default_messages',
        text: 'Default messages',
        href: '/default-messages'
    },
    {
        name: 'history',
        text: 'History',
        href: '/history'
    },
    {
        name: 'plugins',
        text: 'Plugins',
        href: '/Plugins'
    }
];

export const WIDGET_DEFAULT_NAME = 'Unnamed';

export const STORE_STORAGE_KEY = 'dx-console__settings';

export const MockedModuleOrg: Organisation = {
    id: '068e4ade-f3c4-49d7-993c-4f6eba47fcde',
    subdomain: 'andre-account',
    account_licenses: ['DSS', 'AAA'],
    webext_hostname: 'http://andre-account.webext.karehq.com'
};

export const kbMock = {
    app_id: '966c808e-43e9-4494-a424-ad9a982fa68f',
    id: 'default',
    license_type: 'DSS',
    locale: 'en-GB',
    status: 'published'
};

export const chatbotMock: Widget = {
    id: '966c808e-43e9-4494-a424-ad9a982fa68f',
    name: 'Chatbot title',
    license_type: LicenseType.Dss,
    default_locale: 'en-GB',
    generative: true,
    status: WidgetStatus.Published,
    bot_name: 'Dialbot test',
    company_name: 'Dialpad',
    default_experience_id: '',
    kbs: {
        'en-US': 'kb_7790976a-3ecd-414e-9632-dd4e7af30a6f',
        'pt-PT': 'kb_3abbe9a7-3916-4371-b47c-16cb9b408bfb'
    },
    vertical: 'agriculture'
};

export const KNOWLEDGE_BANNER_HEIGHT = 82;

export const CHATBOT_CARD_WIDTH = 332;
export const CHATBOT_CARD_HEIGHT = 148;

export const MIN_RESPONSE_NAME_CHARS = 5;

export const DEFAULT_ITEMS_PER_PAGE = 10;

export const CHATBOT_LIST_HEADER_HEIGHT = 84 + 51;

export const DEFAULT_MIN_DRAWER_WIDTH = '250px';
export const DRAWER_WIDTH = '361px';

export const SUPPORTED_BLOCKS: ResponseNodeBlockTypeEnum[] = [
    ResponseNodeBlockTypeEnum.Image,
    ResponseNodeBlockTypeEnum.Video,
    ResponseNodeBlockTypeEnum.Text,
    ResponseNodeBlockTypeEnum.Webext,
    ResponseNodeBlockTypeEnum.Workflow,
    ResponseNodeBlockTypeEnum.Choices,
    ResponseNodeBlockTypeEnum.CallToAction
];

export const DEFAULT_VIDEO_BLOCK_TAB: 'Embed' | 'Upload' = 'Embed';
export const DEFAULT_IMAGE_BLOCK_TAB: 'Embed' | 'Upload' = 'Upload';

export const ANALYTICS_CONTACT_CENTERS = [
    { value: 'all', label: 'All contact centers' },
    { value: 'cc1', label: 'CC1' },
    { value: 'cc2', label: 'CC2' },
    { value: 'cc3', label: 'CC3' }
];

export const DATE_OPTIONS = [
    { value: 'today', label: 'Today' },
    { value: 'yesterday', label: 'Yesterday' },
    { value: 'last_week', label: 'Last week' },
    { value: 'last_month', label: 'Last month' },
    { value: '7', label: 'Past 7 days' },
    { value: '15', label: 'Past 15 days' },
    { value: '30', label: 'Past 30 days' },
    { value: '90', label: 'Past 90 days' }
];

export enum DIALPAD_LOCALES {
    en = 'en-US',
    de = 'de-DE',
    es = 'es-ES',
    fr = 'fr-FR',
    it = 'it-IT',
    ja = 'ja-JP',
    nl = 'nl-NL',
    pt = 'pt-PT',
    ru = 'ru-RU',
    zh = 'zh-Hans'
}

export const NAVIGATION_ITEMS: any = {
    DSS: {
        text: 'Self service',
        href: '/self-service'
    },
    AAA: {
        text: 'Ai assist',
        href: '/agent-assist'
    },
    ASA: {
        text: 'Ai sales assist',
        href: '/sales-assist'
    }
};

export const OPTIMIZE_TABS = [
    { title: 'Unanswered questions', value: 'missing_knowledge' },
    { title: 'Broken contents', value: 'broken_content' },
    { title: 'Low confidence responses', value: 'low_confidence' },
    //{ title: 'Outdated responses', value: 'outdated_responses' },
    { title: 'Muted questions', value: 'muted_questions' },
    { title: 'Snoozed', value: 'snoozed' }
];

export const OPTIMIZE_ACTIONS = [
    {
        title: 'Add to document',
        description: 'Add questions to a document in your knowledge base.',
        badgeType: 'success',
        badgeText: 'Recommended'
    },
    {
        title: 'Snooze',
        description:
            'Temporarily remove this question group for your unanswered questions list while you create or update content in you external knowledge base. New content will sync and be available for answering questions within 24 hours.'
    },
    {
        title: 'Add to response',
        description:
            'Add questions to a response in your knowledge base or create a new one.'
    },
    {
        title: 'Mute all',
        description:
            'Mute these questions from appearing on the “Unanswered questions” page. You can unmute questions any time from the “Muted questions” page.'
    }
];

export enum KNOWLEDGE_SUB_ROUTES {
    OPTIMIZED_WIZARD = 'optimize_wizard',
    NODE_EDITOR = 'node_editor'
}

export const HISTORY_PAGE_ROW_HEIGHT = 75;
export const TEXT_BLOCK_LIMIT = 2500;

export enum OPTIMIZE_TAB {
    UNANSWERED_QUESTIONS = 'unanswered questions',
    BROKEN_CONTENTS = 'broken contents',
    LOW_CONFIDENCE_RESPONSES = 'low confidence responses',
    OUTDATED_RESPONSES = 'outdated responses',
    MUTED_QUESTIONS = 'muted questions',
    SNOOZED = 'snoozed'
}

export const ANALYTICS_PATH = {
    AI_AGENT: (apps: { id: string; locale: string }[]) =>
        `/analytics/digital?view=ai_agents&ai_agents=${apps.map((app) => app.id + '~' + app.locale).join(',')}`,
    AI_ASSISTANT: (apps: { id: string; locale: string }[]) =>
        `/analytics/aiassistants?view=usage&ai_assistants=${apps.map((app) => app.id + '~' + app.locale).join(',')}`
};
