<template>
    <drawer-template @drawer-template-close="close" force-width>
        <template #header>
            <span class="d-fs-300 d-fw-bold">
                {{ $t('Upload documents') }}
            </span>
        </template>
        <template #default>
            <div class="d-ps-relative d-h100p">
                <drop-file
                    is-drawer
                    is-multiple
                    :upload-text="$t('documents')"
                    upload-subtext="DOCX, PDF (max 5mb)"
                    v-model:dropzone-files="files"
                    v-model:selected-doc-labels="selectedTags"
                    :is-uploading="isUploading"
                />
            </div>
        </template>
        <template #footer v-if="files.length">
            <div class="d-d-flex d-py16">
                <dt-button class="d-ml-auto" @click="handlePublish">
                    {{ $t('Publish') }}
                </dt-button>
            </div>
        </template>
    </drawer-template>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import type { AxiosError, AxiosResponse } from 'axios';
import { from } from 'rxjs';

import { handleRequest } from '@/utils/Common';
import { ACCEPTED_DOC_FORMATS } from '@/utils/Constants';
import type { RequestArgs } from '@/open-api/base';
import type { Knowledgebase } from '@/open-api';
import type { DrawerService } from '@/services/Drawer.service';

import { DtButton, DtInput } from '@dialpad/dialtone/vue3';

import DropFile from '@/components/upload-drawer/DropFile.vue';
import DrawerTemplate from '@/components/drawer-template/DrawerTemplate.vue';

import type { IUploadedFile } from './UploadFile.types';

export default defineComponent({
    setup() {
        const orgId: string = inject('orgId')!;

        return {
            orgId
        };
    },
    components: {
        DtButton,
        DtInput,
        DropFile,
        DrawerTemplate
    },
    computed: {
        knowledgebase(): Knowledgebase {
            return this.$store.getters[`${this.orgId}/currentKnowledgebase`];
        },
        drawerService(): DrawerService | undefined {
            return this.$store.getters[`${this.orgId}/drawerService`];
        }
    },
    methods: {
        close(shouldFetch?: boolean) {
            this.drawerService?.closeDrawer();
            this.$emit('close', shouldFetch ? this.files : undefined);
            this.clearUploadedFiles();
        },
        clearUploadedFiles() {
            this.files = [];
        },
        handlePublish() {
            this.isUploading = true;

            const unpublishedFiles = this.files.filter(
                (f) => f.status !== 'Published'
            );

            this.files.forEach((f) => {
                if (f.status !== 'Published') {
                    f.status = 'Loading';
                }
            });

            const request = unpublishedFiles.map((file) => {
                return from(this.uploadFiles(file));
            });

            request.map((r, i) => {
                r.pipe().subscribe((res: any) => {
                    const error = res.error as AxiosError;
                    const response = error?.response as AxiosResponse;
                    // We need the real index for the files array
                    const index = this.files.findIndex(
                        (f) => f.file.name === unpublishedFiles[i]!.file.name
                    );
                    if (response) {
                        this.files[index].status = 'Error';
                        this.files[index].status = response
                            ? 'Error'
                            : 'Published';

                        this.files[index].message =
                            response.status === 409
                                ? this.$t('File already exists')
                                : response.data.message;
                    } else {
                        this.files[index].status = 'Published';
                    }

                    // If all files were published then it will close the upload drawer
                    if (!this.files.some((f) => f.status !== 'Published')) {
                        this.close(true);
                    }
                });
            });
        },
        async uploadFiles(fileToUpload: any) {
            return await handleRequest<RequestArgs>(
                this.$store.getters[
                    `${this.orgId}/apiService`
                ]?.ingestion.postUpload(
                    this.$store.getters[`${this.orgId}/authToken`],
                    this.knowledgebase?.id,
                    fileToUpload.file,
                    'published',
                    JSON.stringify(this.selectedTags),
                    fileToUpload.override
                ),
                this.orgId,
                true
            );
        },
        isFileAccepted(type: string) {
            return ACCEPTED_DOC_FORMATS.some((el) => el === type);
        }
    },
    data() {
        return {
            isDragging: false,
            drawerReady: false,
            isUploading: false,
            selectedTags: [] as string[],
            files: [] as IUploadedFile[]
        };
    }
});
</script>
