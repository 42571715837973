<template>
    <dt-card
        class="d-w264"
        content-class="d-body--sm d-h100p"
        container-class="d-ba d-bc-subtle d-bs-none"
    >
        <template #header>
            <div class="d-d-flex d-ai-center d-wmn0">
                <dt-icon name="dialbot" />
                <span class="d-ml8 d-fw-semibold d-fl1 d-truncate">
                    {{ pluginData?.title }}
                </span>
            </div>
        </template>
        <template #content>
            {{ pluginData?.description }}
        </template>
        <template #footer>
            <dt-button
                importance="outlined"
                size="sm"
                :kind="isInstalled ? 'danger' : 'default'"
                @click="handleClick"
            >
                {{ isInstalled ? 'Remove' : 'Install' }}
            </dt-button>
        </template>
    </dt-card>
</template>

<script lang="ts">
import { defineComponent, inject, type PropType } from 'vue';
import { DtCard, DtIcon, DtButton } from '@dialpad/dialtone/vue3';
import { handleRequest } from '@/utils/Common';
import { ApiService } from '@/services/Api.service';

export default defineComponent({
    props: {
        pluginData: {
            type: Object as PropType<any>
        },
        isInstalled: {
            type: Boolean as PropType<boolean>
        },
        pluginIds: {
            type: Array as PropType<string[]>
        }
    },
    components: {
        DtCard,
        DtIcon,
        DtButton
    },
    setup() {
        const orgId: string = inject('orgId')!;

        return {
            orgId
        };
    },
    computed: {
        apiService(): ApiService | undefined {
            return this.$store.getters[`${this.orgId}/apiService`];
        },
        authToken(): string {
            return this.$store.getters[`${this.orgId}/authToken`];
        },
        chatbot(): any {
            return this.$store.getters[`${this.orgId}/currentChatbot`];
        }
    },
    methods: {
        handleClick() {
            this.isInstalled ? this.handleRemove() : this.handleInstall();
        },
        async updateChatbot(pluginId: string, type: string): Promise<void> {
            let pluginIds: string[] = this.pluginIds;

            if (type === 'install') {
                pluginIds.push(pluginId);
            } else if (type === 'remove') {
                pluginIds = pluginIds.filter((pId) => pId !== pluginId);
            }

            this.loading = true;
            const appUpdatedResponse = await handleRequest<any>(
                this.apiService?.knowledge.patchWidget(
                    this.authToken,
                    this.chatbot.id,
                    {
                        plugin_ids: pluginIds
                    }
                ),
                this.orgId,
                true
            );

            if (!appUpdatedResponse.error) {
                this.$emit(
                    'update:pluginIds',
                    appUpdatedResponse.data.plugin_ids
                );
            }
        },
        handleInstall() {
            this.updateChatbot(this.pluginData.id, 'install');
        },
        handleRemove() {
            this.updateChatbot(this.pluginData.id, 'remove');
        }
    },
    data() {
        return {
            isLoading: false
        };
    }
});
</script>
