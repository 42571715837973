import { LicenseType } from '@/open-api';

export enum APP_TYPES {
    AGENT_ASSIST = 'Ai Assistants',
    SALES_ASSIST = 'Ai Sales Assistants',
    SELF_SERVICE = 'Ai Agents'
}

export enum APP_TYPE {
    AGENT_ASSIST = 'Ai Assistant',
    SALES_ASSIST = 'Ai Sales Assistant',
    SELF_SERVICE = 'Ai Agent'
}

export const APP_BY_LICENSE_TYPE = {
    SINGULAR: {
        [LicenseType.Aaa]: APP_TYPE.AGENT_ASSIST,
        [LicenseType.Asa]: APP_TYPE.AGENT_ASSIST,
        [LicenseType.Dss]: APP_TYPE.SELF_SERVICE
    },
    PLURAL: {
        [LicenseType.Aaa]: APP_TYPES.AGENT_ASSIST,
        [LicenseType.Asa]: APP_TYPES.AGENT_ASSIST,
        [LicenseType.Dss]: APP_TYPES.SELF_SERVICE
    }
};
